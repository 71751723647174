import { Component, Vue } from 'nuxt-property-decorator';
import { ApiModule } from '../store/ApiModule';
import { ApiInterface } from '~/store/apiInterface';

@Component
export default class extends Vue {
  store!: ApiModule;
  subscription?: () => void | undefined;
  debounceTimeout: NodeJS.Timeout | undefined = undefined;

  get defaultTableConfig() {
    return {
      defaultSort: ['name', 'asc'],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'both',
      defaultSortDirection: 'asc',
      currentPage: 1,
      isLoading: false,
      perPage: 15,
      backendFilter: false,
    };
  }

  get tableConfig() {
    return this.defaultTableConfig;
  }

  bindStore(store: ApiModule) {
    store.setApiInstance(this.$api as ApiInterface);
    this.store = store;
  }

  get currentRecord() {
    return this.store.currentRecord;
  }

  get tableRecords() {
    return this.store.tableRecords;
  }

  get isLoading() {
    return this.store.isTableLoading;
  }

  get isLastPage() {
    return this.store.hasReachedLastPage;
  }

  showNextPage() {
    this.store.showNextPage(30);
  }

  onSortChanged(params: { field: string; order: string }) {
    this.store.setSort(params);
  }

  onFilterSet(filtersExist: boolean) {
    this.store.setFilters(filtersExist);
  }

  onRowSelected(_param: any) {}

  onActionClicked() {}

  debounce(callback: () => any, delay: number = 500) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      callback();
    }, delay);
  }

  beforeDestroy() {
    if (this.subscription) {
      this.subscription();
    }
  }
}
