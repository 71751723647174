import dayjs from 'dayjs';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { PartialDeep } from 'type-fest';
import { ContractOrder } from './entities/contractOrder';
import { ApiModule } from './ApiModule';
import { partnerOrdersStore } from './';
import { Contract } from '~/types/contract';
import { OrderStatus } from '~/store/entities/contractOrder';

function uniqueList(objList: any[], key: string) {
  return [...new Map(objList.map((item) => [item[key], item])).values()];
}

@Module({
  name: 'PartnerContracts',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class PartnerContractsStoreModule extends ApiModule {
  records: any[] = [];
  contractTypes: string[] = [];
  orderTypes: string[] = [];
  orders: ContractOrder[] = [];

  customerNumber: string = '';

  get serviceUrl(): string {
    const state = this.context.state as any;
    return `/portal/${state.customerNumber}/partner/contracts/`;
  }

  @Action
  async fetchAllContractsRecords(params: { xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const state = this.context.state as any;
      const response = await this.api.get(`/portal/partner/${state.customerNumber}/contracts`, {
        params: {
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('setContractRecords', response.data);
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action
  async fetchOpenOrderRecords(params: { xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const state = this.context.state as any;
      const response = await this.api.get(`/portal/partner/${state.customerNumber}/orders/open`, {
        params: {
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('setOrderRecords', response.data);
    } catch (e) {
      console.log(e);
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action
  async fetchPendingOrderRecords(params: { xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const state = this.context.state as any;
      const response = await this.api.get(`/portal/partner/${state.customerNumber}/orders/pending`, {
        params: {
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('setOrderRecords', response.data);
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action
  async fetchContractDetails(params: { contract: string; xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const state = this.context.state as any;
      const response = await this.api.get(`/portal/partner/${state.customerNumber}/contracts/${params.contract}`, {
        params: {
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('setContractDetail', response.data.data, {});
    } catch (e) {
      this.context.commit('recordDetailError', e);
    }
  }

  @Action
  async fetchRescheduleOrderDetails(params: { contract: string; xlanguage: string }) {
    try {
      this.context.commit('actionLoading');
      const state = this.context.state as any;
      const response = await this.api.get(`/portal/partner/${state.customerNumber}/contracts/${params.contract}`, {
        params: {
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('setRescheduleDetail', response.data.data, {});
    } catch (e) {
      this.context.commit('recordDetailError', e);
    }
  }

  @Mutation
  rescheduleSuccess() {
    this.status = { type: '' };
  }

  @Mutation
  rescheduleError() {
    this.status = { type: '' };
  }

  @Action
  async rescheduleOrder(request: { externalQualifier: string; serviceDate: string; serviceTimeRange: string; xlanguage: string }) {
    try {
      this.context.commit('actionLoading');
      const requestRescheduleOrder = {
        ...request,
        ordererEmail: partnerOrdersStore.orderer?.ordererEmail,
        ordererFirstname: partnerOrdersStore.orderer?.ordererFirstname,
        ordererLastname: partnerOrdersStore.orderer?.ordererLastname,
        xlanguage: request.xlanguage,
      };
      const response = await this.api.put(
        `/portal/partner/${this.currentCustomerNumber}/orders/${request.externalQualifier}`,
        requestRescheduleOrder
      );
      this.context.commit('rescheduleSuccess', response.data.data, {});
    } catch (e) {
      this.context.commit('rescheduleError', e);
    }
  }

  @Mutation
  setContractDetail(record: Contract | undefined) {
    this.status = { type: '' };
    this.tableLoading = false;
    this.record = record;
  }

  @Mutation
  setRescheduleDetail(record: Contract | undefined) {
    this.status = { type: '' };
    this.tableLoading = false;
    this.record = record;
  }

  @Mutation
  setContractRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.records = uniqueList([...this.records, ...response.data], 'contractNo');
    this.contractTypes = response.options.types;
  }

  @Mutation
  setOrderRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.orders = uniqueList([...this.orders, ...response.data], 'orderNo');
    this.orderTypes = response.options.types;
  }

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  get currentRecord(): PartialDeep<any> {
    return this.record;
  }

  get currentContractOrders() {
    if (!this.currentRecord) {
      return [];
    }
    const orders = this.currentRecord.partnerOrders || this.currentRecord.customerOrders;
    return orders?.map((order: ContractOrder) => {
      return {
        ...order,
        taskDate: dayjs(order.taskDate).format('DD.MM.YYYY'),
        timestamp: new Date(order.taskDate).getTime(),
      };
    });
  }

  get openContractOrders() {
    return this.mapOrders(this.orders.filter((order: ContractOrder) => !order.isOrderClosed));
  }

  get openContractOrdersWithoutAval() {
    return this.mapOrders(this.orders.filter((order: ContractOrder) => !order.isOrderClosed && !order.avalMatched));
  }

  get pendingContractOrders() {
    return this.mapOrders(this.orders.filter((order: ContractOrder) => order.isOrderClosed && order.documentType === OrderStatus.ORDER));
  }

  get pendingContractOrdersWithoutAval() {
    return this.mapOrders(
      this.orders.filter((order: ContractOrder) => order.isOrderClosed && order.documentType === OrderStatus.ORDER && !order.avalMatched)
    );
  }

  get closedContractOrders() {
    return this.mapOrders(this.orders.filter((order: ContractOrder) => order.isOrderClosed && order.documentType === OrderStatus.RELEASE));
  }

  get openContracts() {
    return this.records.filter((contract: any) => !contract.isContractClosed);
  }

  get openContractsWithoutAval() {
    return this.records.filter((contract: any) => !contract.isContractClosed && !contract.avalMatched);
  }

  get closedContracts() {
    return this.records.filter((contract: any) => contract.isContractClosed);
  }

  get closedContractsWithoutAval() {
    return this.records.filter((contract: any) => contract.isContractClosed && !contract.avalMatched);
  }

  get contractTypeOptions() {
    return this.contractTypes;
  }

  get orderTypeOptions() {
    return this.orderTypes;
  }

  get hasOrders(): boolean {
    return this.orders.length > 0;
  }

  get hasContracts(): boolean {
    return this.records.length > 0;
  }

  private get mapOrders() {
    return (orders: any[]) =>
      orders.map((order: any) => {
        return {
          ...order,
          containerQuantity: order.containerQuantity,
          containerQuantityActual: order.containerQuantityActual,
          serviceLocationCity: order.serviceLocationCity,
          contactPersonName: order.contactPersonName,
          containerDescription: order.containerDescription,
          contractExternalQualifier: order.contractExternalQualifier,
          contractNo: order.contractNo,
          externalQualifier: order.externalQualifier,
          orderNo: order.orderNo,
          orderType: order.orderType,
          serviceLocation: order.serviceLocationName,
          serviceLocationName: order.serviceLocationName,
          streetAddress: order.serviceLocationAddress ?? order.streetAddress,
          taskDate: dayjs(order.taskDate),
          timestamp: new Date(order.taskDate).getTime(),
          wastetypeDescription: order.wastetypeDescription,
          zipCode: order.serviceLocationZipCode,
        };
      });
  }

  get tableRecords() {
    return this.records;
  }

  get tableModalContent(): object | undefined {
    return {};
  }
}
