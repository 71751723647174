import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7cdc64b3 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _0592ba2e = () => interopDefault(import('../pages/operatives/index.vue' /* webpackChunkName: "pages/operatives/index" */))
const _2b53e6d4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _1771a411 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _49b66ea4 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _2ef9e52e = () => interopDefault(import('../pages/confirm/index.vue' /* webpackChunkName: "pages/confirm/index" */))
const _8778531c = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _5a0f4fd7 = () => interopDefault(import('../pages/reporting/index.vue' /* webpackChunkName: "pages/reporting/index" */))
const _40a21e70 = () => interopDefault(import('../pages/receipts/index.vue' /* webpackChunkName: "pages/receipts/index" */))
const _1da25070 = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _02c7d6a0 = () => interopDefault(import('../pages/disposition/index.vue' /* webpackChunkName: "pages/disposition/index" */))
const _0683133a = () => interopDefault(import('../pages/documentation/index.vue' /* webpackChunkName: "pages/documentation/index" */))
const _5ca18010 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8f503794 = () => interopDefault(import('../pages/serviceLocations/index.vue' /* webpackChunkName: "pages/serviceLocations/index" */))
const _e0b3b0ec = () => interopDefault(import('../pages/contracts/index.vue' /* webpackChunkName: "pages/contracts/index" */))
const _1eacd208 = () => interopDefault(import('../pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _e420f37c = () => interopDefault(import('../pages/contracts/_slug.vue' /* webpackChunkName: "pages/contracts/_slug" */))
const _08fffcbe = () => interopDefault(import('../pages/operatives/_slug.vue' /* webpackChunkName: "pages/operatives/_slug" */))
const _92bd7a24 = () => interopDefault(import('../pages/serviceLocations/_slug.vue' /* webpackChunkName: "pages/serviceLocations/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abrechnung",
    component: _7cdc64b3,
    name: "invoices___de"
  }, {
    path: "/abwicklung",
    component: _0592ba2e,
    name: "operatives___de"
  }, {
    path: "/account",
    component: _2b53e6d4,
    name: "account___de"
  }, {
    path: "/anfragen",
    component: _1771a411,
    name: "requests___de"
  }, {
    path: "/angebote",
    component: _49b66ea4,
    name: "offers___de"
  }, {
    path: "/angebotspruefung",
    component: _2ef9e52e,
    name: "confirm___de"
  }, {
    path: "/auftraege",
    component: _8778531c,
    name: "orders___de"
  }, {
    path: "/auswertung",
    component: _5a0f4fd7,
    name: "reporting___de"
  }, {
    path: "/beleguebersicht",
    component: _40a21e70,
    name: "receipts___de"
  }, {
    path: "/demo",
    component: _1da25070,
    name: "demo___de"
  }, {
    path: "/disposition",
    component: _02c7d6a0,
    name: "disposition___de"
  }, {
    path: "/documentation",
    component: _0683133a,
    name: "documentation___de"
  }, {
    path: "/en",
    component: _5ca18010,
    name: "index___en"
  }, {
    path: "/leistungsorte",
    component: _8f503794,
    name: "serviceLocations___de"
  }, {
    path: "/vertraege",
    component: _e0b3b0ec,
    name: "contracts___de"
  }, {
    path: "/wartungsarbeiten",
    component: _1eacd208,
    name: "maintenance___de"
  }, {
    path: "/en/account",
    component: _2b53e6d4,
    name: "account___en"
  }, {
    path: "/en/contracts",
    component: _e0b3b0ec,
    name: "contracts___en"
  }, {
    path: "/en/demo",
    component: _1da25070,
    name: "demo___en"
  }, {
    path: "/en/disposition",
    component: _02c7d6a0,
    name: "disposition___en"
  }, {
    path: "/en/documentation",
    component: _0683133a,
    name: "documentation___en"
  }, {
    path: "/en/invoices",
    component: _7cdc64b3,
    name: "invoices___en"
  }, {
    path: "/en/maintenance",
    component: _1eacd208,
    name: "maintenance___en"
  }, {
    path: "/en/offerconfirm",
    component: _2ef9e52e,
    name: "confirm___en"
  }, {
    path: "/en/offers",
    component: _49b66ea4,
    name: "offers___en"
  }, {
    path: "/en/operatives",
    component: _0592ba2e,
    name: "operatives___en"
  }, {
    path: "/en/orders",
    component: _8778531c,
    name: "orders___en"
  }, {
    path: "/en/receipts",
    component: _40a21e70,
    name: "receipts___en"
  }, {
    path: "/en/reporting",
    component: _5a0f4fd7,
    name: "reporting___en"
  }, {
    path: "/en/requests",
    component: _1771a411,
    name: "requests___en"
  }, {
    path: "/en/servicelocations",
    component: _8f503794,
    name: "serviceLocations___en"
  }, {
    path: "/",
    component: _5ca18010,
    name: "index___de"
  }, {
    path: "/en/contracts/:slug",
    component: _e420f37c,
    name: "contracts-slug___en"
  }, {
    path: "/en/operatives/:slug",
    component: _08fffcbe,
    name: "operatives-slug___en"
  }, {
    path: "/en/servicelocations/:slug",
    component: _92bd7a24,
    name: "serviceLocations-slug___en"
  }, {
    path: "/abwicklung/:slug",
    component: _08fffcbe,
    name: "operatives-slug___de"
  }, {
    path: "/leistungsorte/:slug",
    component: _92bd7a24,
    name: "serviceLocations-slug___de"
  }, {
    path: "/vertraege/:slug",
    component: _e420f37c,
    name: "contracts-slug___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
