import { Vue } from 'nuxt-property-decorator';
import type { GeocodeResponse } from '@googlemaps/google-maps-services-js/dist/client';
import { GeoCoordinates } from '@redooo/shared/dist/types/portal/geoCoordinates';
import { ServiceLocationCreateRequest } from '@redooo/shared/dist/types/portal/serviceLocationCreateRequest';

export const geoLocateServiceLocation = (vueComponent: Vue, serviceLocation: ServiceLocationCreateRequest): Promise<GeoCoordinates | undefined> => {
  return geoLocate(vueComponent, serviceLocation.street, serviceLocation.streetNo, serviceLocation.zipCode, serviceLocation.city);
};

export const geoLocate = async (
  vueComponent: Vue,
  street: string | undefined,
  streetNo: string | undefined,
  zip: string | undefined,
  city: string | undefined
): Promise<GeoCoordinates | undefined> => {
  const searchParams = new URLSearchParams();
  searchParams.append('key', vueComponent.$config.GOOGLE_API_KEY);
  searchParams.append('address', `${street} ${streetNo}, ${zip} ${city}`);
  searchParams.append('components', 'country:de');

  const geoCodedResult = (await vueComponent.$axios.get('https://maps.googleapis.com/maps/api/geocode/json?' + searchParams)) as GeocodeResponse;
  if (geoCodedResult && geoCodedResult.data.results.length !== 0) {
    return {
      lat: geoCodedResult.data.results[0].geometry.location.lat,
      lng: geoCodedResult.data.results[0].geometry.location.lng,
    };
  } else {
    return undefined;
  }
};
