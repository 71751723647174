
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { NuxtError } from '@nuxt/types';
  import { ErrorType } from '../types/errorTypes';
  import SiteButton from '../components/shared/SiteButton.vue';

  @Component({
    components: {
      SiteButton,
    },
  })
  export default class ErrorLayout extends Vue {
    @Prop({ type: Object, required: true }) readonly error!: NuxtError;

    errorMessage = this.error.message;

    get getHeadline() {
      switch (this.errorMessage) {
        case ErrorType.NOT_AVAILABLE:
          return this.$t('pages.errorLayout.headline.notAvailable');
        case ErrorType.NOT_AUTHENTICATED:
          return this.$t('pages.errorLayout.headline.notAuthenticated');
        case ErrorType.NOT_AUTHORIZEDROUTE:
          return this.$t('pages.errorLayout.headline.notAuthorizedRoute');
        case ErrorType.SESSIONTIMEOUT:
          return this.$t('pages.errorLayout.headline.sessionTimeout');
        default:
          return this.$t('pages.errorLayout.headline.default');
      }
    }

    get getSubHeadline() {
      switch (this.errorMessage) {
        case ErrorType.NOT_AUTHENTICATED:
        case ErrorType.NOT_AUTHORIZEDROUTE:
        case ErrorType.SESSIONTIMEOUT:
        default:
          return this.$t('pages.errorLayout.subHeadline.default');
      }
    }

    get getBtnText() {
      switch (this.errorMessage) {
        case ErrorType.NOT_AVAILABLE:
          return this.$t('pages.errorLayout.btnText.loginAgain');
        case ErrorType.NOT_AUTHENTICATED:
        case ErrorType.NOT_AUTHORIZEDROUTE:
        case ErrorType.SESSIONTIMEOUT:
          return this.$t('pages.errorLayout.btnText.reloadPage');
        default:
          return this.$t('pages.errorLayout.btnText.backToHomepage');
      }
    }

    onBtnClick() {
      switch (this.errorMessage) {
        case ErrorType.NOT_AVAILABLE:
          this.$keycloak.clearToken();
          this.$keycloak.logout();
          break;

        case ErrorType.NOT_AUTHENTICATED:
        case ErrorType.NOT_AUTHORIZEDROUTE:
        case ErrorType.SESSIONTIMEOUT:
          this.$router.go(0);
          break;
        default:
          this.$i18n.locale === 'en' ? this.$router.push('/en') : this.$router.push('/');
      }
    }
  }
