
  /* eslint-disable no-undef */
  import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Location from '@redooo/shared/dist/types/ui/location';
  import { serviceLocationStore } from '../store';
  import FormField from './shared/FormField.vue';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';

  @Component({
    components: {
      FormField,
      SiteButton,
      ValidationObserver,
      ValidationProvider,
      IconBase,
    },
  })
  export default class LocationAutocomplete extends Vue {
    select: any | string = '';
    searchResults: { text: string; value: google.maps.places.AutocompletePrediction }[] = [];
    autoCompleteService: google.maps.places.AutocompleteService | null = null;
    currentPositionBounds: google.maps.LatLngBounds | null = null;
    googleSessionToken: google.maps.places.AutocompleteSessionToken | string = '';
    detailService: any = null;
    externalJSLoaded = false;
    searchTimerId?: number;
    isCrossClicked: boolean = false;

    @Prop()
    required!: boolean;

    @Prop()
    model!: any;

    @Prop({ default: false })
    disabled!: boolean;

    @Prop()
    rules!: string;

    @Prop({ default: 'text' })
    fieldType!: string;

    @Prop()
    label!: string;

    @Prop()
    id!: string;

    @Prop({ default: false })
    isZipCode!: boolean;

    head() {
      return {
        script: [
          {
            hid: 'GooglePlaces',
            src: `https://maps.googleapis.com/maps/api/js?key=${this.$config.GOOGLE_API_KEY}&region=DE&language=de&libraries=places`,
            defer: true,
            skip: this.isExternalResourceLoaded,
            callback: () => {
              this.externalJSLoaded = true;
              this.initializeServices();
            },
          },
        ],
      };
    }

    mounted() {
      this.$nextTick(function () {
        this.initializeServices();
      });
    }

    @Watch('location')
    onSearchChange(val: string, valOld: string) {
      if (!this.autoCompleteService) {
        this.initializeServices();
      }

      // reset searchResults
      if (val?.length === 0) {
        this.searchResults = [];
      }

      if (val && val !== valOld && val.length >= 3 && this.autoCompleteService && this.location !== this.select?.description) {
        if (this.isZipCode && Number(val) && val.length >= 4) {
          this.fetchEntriesDebounced();
        } else if (!this.isZipCode) {
          this.fetchEntriesDebounced();
        }
      }

      if (!val || (typeof val === 'string' && val.length === 0)) {
        (this.$children[0].$refs.validationProvider as InstanceType<typeof ValidationProvider>).setErrors([
          this.$i18n.t('global.forms.errorMessages.required') as string,
        ]);
      }
    }

    get location(): string {
      return this.model;
    }

    set location(value: string) {
      if (value?.length > 0) {
        this.$emit('on-change', value, false);
      } else if (value?.length === 0 && this.location.length > 0) {
        // user remove input with delete-button
        this.$emit('on-change', value, true);
      }
    }

    changeLocalLocation(value: string): void {
      this.location = value;
    }

    get isExternalResourceLoaded() {
      return this.externalJSLoaded || this.isGooglePlacesApiAvailable;
    }

    get isGooglePlacesApiAvailable() {
      let result = false;
      try {
        if (window.google.maps.places) {
          result = true;
        }
      } catch (e) {
        result = false;
      }
      return result;
    }

    get getZipCodeLatLngBounds() {
      return serviceLocationStore.getZipCodeLatLngBounds;
    }

    onBlur($event: any) {
      let autocompleteComp: any = [];
      autocompleteComp = $event.path?.filter((path: any) => {
        return path.className?.includes('autocomplete');
      });
      if (autocompleteComp) {
        // FIXME?: Needed because there is a conflict with @select.
        setTimeout(() => {
          autocompleteComp[0].__vue__.isActive = false;
        }, 200);
      }
    }

    updateLocation(zipCode?: string, state?: string, city?: string, street?: string, streetNo?: string) {
      const timestamp = Date.now();

      const selectedLocation: Location = {
        zipCode: zipCode || '',
        city,
        state: state || '',
        street,
        streetNo,
        timestamp,
      };
      // console.log('selectedLocation:', selectedLocation);
      this.$emit(`location-updated-${this.id}`, selectedLocation);
    }

    initializeServices() {
      if (!window.google) return;
      this.autoCompleteService = !this.autoCompleteService ? new window.google.maps.places.AutocompleteService() : this.autoCompleteService;
      this.googleSessionToken = !this.googleSessionToken ? new window.google.maps.places.AutocompleteSessionToken() : this.googleSessionToken;
    }

    geolocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            const circle = new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy,
            });
            this.currentPositionBounds = circle.getBounds();
          },
          undefined,
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
      }
    }

    onSelectChanged(option: { text: string; value: google.maps.places.AutocompletePrediction }) {
      if (option?.value) {
        this.selectLocation(option.value);
      } else {
        this.searchResults = [];
      }
    }

    selectLocation(event: google.maps.places.AutocompletePrediction) {
      if (!this.detailService) {
        this.detailService = new google.maps.places.PlacesService(document.createElement('div'));
      }

      // search input cleared?
      if (!event || !event.place_id) return;

      this.detailService.getDetails(
        { placeId: event.place_id, fields: ['address_components', 'geometry'], sessionToken: this.googleSessionToken },
        // eslint-disable-next-line require-await
        async (data: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && data.address_components) {
            if (this.isZipCode) {
              serviceLocationStore.setZipCodeLatLngBounds(data.geometry?.viewport as google.maps.LatLngBounds);
            }
            const state = this.getAddressComponentType('administrative_area_level_1', data.address_components);
            // console.log('state:', state);
            const postalCode = this.getAddressComponentType('postal_code', data.address_components);
            // console.log('postalCode:', postalCode);
            const city = this.getAddressComponentType('locality', data.address_components);
            // console.log('city:', city);
            const streetNo = this.getAddressComponentType('street_number', data.address_components);
            // console.log('streetNo:', streetNo);
            let street = this.getAddressComponentType('route', data.address_components);
            // console.log('street:', street);
            if (!street) street = this.getAddressComponentType('town_square', data.address_components);
            if (postalCode || city) {
              this.updateLocation(postalCode?.long_name, state?.short_name, city?.long_name, street?.long_name, streetNo?.long_name);
              this.googleSessionToken = new window.google.maps.places.AutocompleteSessionToken();
              (this.$children[0].$refs.validationProvider as InstanceType<typeof ValidationProvider>).setErrors([]);
            } else {
              (this.$children[0].$refs.validationProvider as InstanceType<typeof ValidationProvider>).setErrors([
                this.$i18n.t('global.forms.errorMessages.googleNoZipCode') as string,
              ]);
            }
          }
        }
      );
    }

    getAddressComponentType(addressType: string, addressComponents: google.maps.GeocoderAddressComponent[]) {
      return addressComponents.find(function (component) {
        // if (addressType === 'route') {
        //   return component.types.includes(addressType) || component.types.includes('sublocality');
        // }
        return component.types.includes(addressType);
      });
    }

    fetchEntriesDebounced() {
      window.clearTimeout(this.searchTimerId);
      this.searchTimerId = window.setTimeout(() => {
        if (!this.autoCompleteService) return;
        this.autoCompleteService.getPlacePredictions(
          {
            input: this.location,
            types: ['geocode'],
            bounds: !this.isZipCode ? this.getZipCodeLatLngBounds || undefined : this.currentPositionBounds || undefined,
            componentRestrictions: { country: 'de' },
            sessionToken: this.googleSessionToken,
          },
          this.displaySuggestions
        );
      }, 750);
    }

    displaySuggestions(predictions: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus) {
      if (!predictions || status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
      }
      this.searchResults = predictions.map((prediction) => {
        return { text: prediction.description, value: prediction };
      });
    }
  }
