import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
  name: 'User',
  stateFactory: true,
  namespaced: true,
  preserveState: false,
})
export default class UserDataModule extends VuexModule {
  private _userName = '';
  private _userEmail = '';
  private _customerId = '';
  private _roles: string[] = [];

  private _isAuthenticated = false;

  public get userName() {
    return this._userName;
  }

  public get customerId() {
    return this._customerId;
  }

  public get roles() {
    return this._roles;
  }

  public get userEmail() {
    return this._userEmail;
  }

  public get isAuthenticated() {
    return this._isAuthenticated;
  }

  @Mutation
  public setUserInfo(userInfo: { email: string; fullName: string; customerId: string; roles: string[] }) {
    this._userName = userInfo.fullName;
    this._userEmail = userInfo.email;
    this._customerId = userInfo.customerId;
    this._roles = userInfo.roles;
  }

  @Mutation
  public setAuthenticated(authenticated = false) {
    this._isAuthenticated = authenticated;
  }
}
