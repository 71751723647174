import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';
import UserStoreModule from '../store/User';
import OfferStoreModule from '../store/Offer';
import PortalStoreModule from '../store/Portal';
import AccountStoreModule from '../store/Account';
import CustomerRequestsStoreModule from '../store/CustomerRequests';
import ContractsStoreModule from '~/store/CustomerContracts';
import PartnerContractsStoreModule from '~/store/PartnerContracts';
import ServiceLocationStoreModule from '~/store/ServiceLocation';
import DeliveryStoreModule from '~/store/Delivery';
import DispositionStoreModule from '~/store/Disposition';
import PartnerOrdersStoreModule from '~/store/PartnerOrders';
import CustomerInvoiceStoreModule from '~/store/CustomerInvoice';
import PartnerInvoiceStoreModule from '~/store/PartnerInvoice';

let userStore: UserStoreModule;
let offerStore: OfferStoreModule;
let portalStore: PortalStoreModule;
let accountStore: AccountStoreModule;

let contractsStore: ContractsStoreModule;
let partnerContractsStore: PartnerContractsStoreModule;
let partnerOrdersStore: PartnerOrdersStoreModule;
let serviceLocationStore: ServiceLocationStoreModule;

let customerRequestsStore: CustomerRequestsStoreModule;
let deliveryStore: DeliveryStoreModule;
let dispositionStore: DispositionStoreModule;
let invoiceStore: CustomerInvoiceStoreModule;
let partnerInvoiceStore: PartnerInvoiceStoreModule;

function initialiseStores(store: Store<any>): void {
  userStore = getModule(UserStoreModule, store);
  offerStore = getModule(OfferStoreModule, store);
  dispositionStore = getModule(DispositionStoreModule, store);
  portalStore = getModule(PortalStoreModule, store);
  accountStore = getModule(AccountStoreModule, store);
  contractsStore = getModule(ContractsStoreModule, store);
  partnerOrdersStore = getModule(PartnerOrdersStoreModule, store);
  partnerContractsStore = getModule(PartnerContractsStoreModule, store);
  serviceLocationStore = getModule(ServiceLocationStoreModule, store);
  customerRequestsStore = getModule(CustomerRequestsStoreModule, store);
  deliveryStore = getModule(DeliveryStoreModule, store);
  invoiceStore = getModule(CustomerInvoiceStoreModule, store);
  partnerInvoiceStore = getModule(PartnerInvoiceStoreModule, store);
  console.log('STORE INIT', store);
}

export {
  initialiseStores,
  userStore,
  offerStore,
  portalStore,
  accountStore,
  contractsStore,
  partnerContractsStore,
  serviceLocationStore,
  customerRequestsStore,
  dispositionStore,
  deliveryStore,
  partnerOrdersStore,
  invoiceStore,
  partnerInvoiceStore,
};
