import { Module, Mutation, Action } from 'vuex-module-decorators';
import dayjs from 'dayjs';
import { PartialDeep } from 'type-fest';
import duration from 'dayjs/plugin/duration';
import type { ContractOffer } from '../types/contractOffer';
import { ApiInterface } from './apiInterface';
import { BaseOfferStore } from './BaseOfferStore';
import { XLanguageService } from '~/types/content';

dayjs.extend(duration);

type ApiAware = { $api: ApiInterface };
export type DurationTimeLeft = { data: Record<string, number | string>; class: string };

const expirationDateString = (expirationDateTime: string, currentTime: string): DurationTimeLeft => {
  const now = dayjs(currentTime);
  const expire = dayjs(expirationDateTime);
  return formatDuration(dayjs.duration(expire.diff(now)), expire);
};

const formatDuration = (duration: duration.Duration, expirationDate: dayjs.Dayjs): DurationTimeLeft => {
  const offerClass = duration.asMinutes() < 60 ? 'color-tertiary-red text-bold' : '';
  // FORMAT: XX Std XX Min DD.MM, XX:XX Uhr
  const data = {
    hours: duration.hours() + duration.days() * 24,
    minutes: duration.minutes(),
    expires: expirationDate.format('DD.MM.YYYY HH:mm'),
    displayExpires: expirationDate.format('DD.MM., HH:mm'),
  };
  return { data, class: offerClass };
};

const secondsLeft = (expirationDateTime: string, currentTime: string) => {
  const now = dayjs(currentTime);
  const expire = dayjs(expirationDateTime);
  return Math.round(dayjs.duration(expire.diff(now)).asSeconds());
};

@Module({
  name: 'Offer',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class OfferStoreModule extends BaseOfferStore {
  records: ContractOffer[] = [];
  types: string[] = [];
  visibleRecords: ContractOffer[] = [];
  record?: PartialDeep<ContractOffer | undefined> = undefined;
  timer: number = 0;
  currentTime: dayjs.Dayjs = dayjs();
  timerInterval: any;
  langService = new XLanguageService();
  private selectedLanguage: string = this.langService.getLanguageString('de');

  get serviceUrl(): string {
    return '/portal/partner/' + this.currentCustomerNumber + '/offers';
  }

  get language(): string {
    return this.selectedLanguage;
  }

  @Mutation
  setSelectedLanguage(language: string) {
    this.selectedLanguage = language;
  }

  @Action({ rawError: true })
  async fetchOffers(isEn: boolean) {
    const xlanguage = isEn ? 'en-US' : this.selectedLanguage;
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(this.serviceUrl, {
        params: {
          xlanguage,
        },
      });
      this.context.commit('setOfferRecords', response.data);
      this.context.commit('showNextPage');
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  setOfferRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.records = response.data;
    this.types = response.options.types;
  }

  get offerTypes() {
    return this.types;
  }

  @Action
  async fetchFeedbackOfferDetails(params: any) {
    const orderHash = params.orderHash;
    const xlanguage = params.xlanguage;
    try {
      const response = await (this.context.state as ApiAware).$api.get(`/feedback/offer/${orderHash}`, {
        params: {
          xlanguage,
        },
      });
      this.context.commit('offerDetailSuccess', response.data.data);
    } catch (e) {
      this.context.commit('recordDetailError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  offerDetailSuccess(record: ContractOffer) {
    this.status = { type: 'success' };
    this.record = { ...record };
  }

  @Action({ rawError: true })
  async acceptFeedbackOffer() {
    try {
      if (!this.recordExternalQualifier) {
        this.context.commit('offerNotFound', 'offer not found');
        return;
      }
      const response = await (this.context.state as ApiAware).$api.put(`/feedback/offers/${this.recordExternalQualifier}/confirm`);
      this.context.commit('offerActionAcceptSuccess', response.data.data);
    } catch (e) {
      this.context.commit('offerActionFailed', e);
    }
  }

  @Action
  async acceptFeedbackOfferWithDateOptions(dateOptions: {
    pickupDate?: string;
    pickupTimeRange?: string;
    deliveryDate: string;
    deliveryTimeRange: string;
  }) {
    try {
      if (!this.recordExternalQualifier) {
        this.context.commit('offerError', 'offer not found');
        return;
      }
      const response = await (this.context.state as ApiAware).$api.put(`/feedback/offers/${this.recordExternalQualifier}/confirm`, dateOptions);
      this.context.commit('removeCurrentRecord');
      this.context.commit('offerActionAcceptSuccess', response.data.data);
    } catch (e) {
      if ((e as any).response.status === 422) {
        this.context.commit('offerValidationError', e as string);
      } else {
        this.context.commit('offerActionFailed', e as string);
      }
    }
  }

  @Action({ rawError: true })
  async declineFeedbackOffer() {
    try {
      if (!this.recordExternalQualifier) {
        this.context.commit('offerNotFound', 'offer not found');
        return;
      }
      const response = await (this.context.state as ApiAware).$api.put(`/feedback/offers/${this.recordExternalQualifier}/decline`);
      this.context.commit('offerActionDeclineSuccess', response.data.data);
    } catch (e) {
      this.context.commit('offerActionFailed', e);
    }
  }

  @Mutation
  offerValidationError() {
    this.status = { type: 'success' };
  }

  @Mutation
  offerNotFound(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  @Action
  startTimer(interval: number) {
    this.timerInterval = setInterval(() => {
      this.context.commit('tick');
    }, interval);
  }

  @Action
  stopTimer() {
    clearInterval(this.timerInterval);
  }

  @Mutation
  tick() {
    this.timer++;
    this.currentTime = dayjs();
    // Force rerender by copy the records
    this.visibleRecords = [...this.visibleRecords];
  }

  get offerCount(): number {
    return this.records.filter((offer) => secondsLeft(offer.expirationDateTime, dayjs().toISOString()) > 0).length;
  }

  get offerPickupCount(): number {
    return this.records.length;
  }

  get currentRecord(): PartialDeep<ContractOffer> | undefined {
    return this.record;
  }

  get isOfferExpired(): boolean {
    const now = dayjs();
    const expired = dayjs(this.record?.expirationDateTime);
    return now.isAfter(expired);
  }

  get updateTimeLeft() {
    const expire = dayjs(this.record?.expirationDateTime);
    const duration = dayjs.duration(expire.diff(this.currentTime));
    return formatDuration(duration, expire);
  }

  get hasValidPickupDate(): boolean {
    return this.record?.pickupDate !== undefined && this.record?.pickupDate !== '0001-01-01';
  }

  get tableRecords() {
    return this.records
      .map((offer) => {
        const numSecondsLeft = secondsLeft(offer.expirationDateTime, this.currentTime.toISOString());
        return {
          taskDate: dayjs(offer.taskDate).format('DD.MM.YYYY'),
          city: offer.serviceLocation?.city,
          containerDescription: offer.containerDescription,
          customerName: offer.companyName?.length > 0 ? offer.companyName : offer.contactPerson?.name,
          expirationTime: expirationDateString(offer.expirationDateTime, this.currentTime.toISOString()),
          externalQualifier: offer.externalQualifier,
          hasAction: numSecondsLeft > 0,
          interval: offer.customerIntervalDescription,
          secondsLeft: numSecondsLeft,
          street: offer.serviceLocation?.street,
          streetNo: offer.serviceLocation?.streetNo,
          type: offer.offerType,
          wastetypeDescription: offer.wastetypeDescription,
          zipCode: offer.serviceLocation?.zipCode,
          cy: offer.type,
        };
      })
      .filter((offer) => offer.secondsLeft > 0);
  }

  get tableModalContent(): object | undefined {
    return this.record
      ? {
          contactPerson: this.record.contactPerson?.name,
          containerType: this.record.containerDescription,
          containerTypeAmount: this.record.containerQuantity,
          customer: this.record.serviceLocation?.name,
          deliveryArea: this.record.publicGround ? 'öffentlich' : 'privat',
          interval: 'einmalig',
          location: this.record.serviceLocation?.city,
          mailAdress: this.record.contactPerson?.email,
          notes: this.record.remark,
          phoneNumber: this.record.contactPerson?.phone,
          price: 'price',
          street: this.record.serviceLocation?.street,
          wasteType: this.record.wastetypeDescription,
        }
      : undefined;
  }
}
