import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { ApiInterface, ApiResponse } from '../store/apiInterface';

export default class ApiService implements ApiInterface {
  private axios: NuxtAxiosInstance;
  public constructor(axios: NuxtAxiosInstance) {
    this.axios = axios;
  }

  public get(servicePath: string, params?: any): Promise<ApiResponse> {
    return this.axios.get(servicePath, { params });
  }

  public post(servicePath: string, data?: any, params?: any): Promise<ApiResponse> {
    return this.axios.post(servicePath, data, params);
  }

  public put(servicePath: string, data?: any, params?: any): Promise<ApiResponse> {
    return this.axios.put(servicePath, data, params);
  }

  public delete(servicePath: string, params?: any): Promise<ApiResponse> {
    return this.axios.delete(servicePath, params);
  }
}
