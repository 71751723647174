export const maxChars = (value: string | undefined, maxChars = 20) => {
  if (value) {
    return value.length >= maxChars ? value.substring(0, maxChars - 1) : value;
  } else {
    return '';
  }
};

export const maxLastChars = (value: string | undefined, maxChars = 20) => {
  if (value) {
    if (value.length <= maxChars) return value;
    return value.substr(value.length - maxChars, maxChars);
  } else {
    return '';
  }
};
