import { Mutation, Action } from 'vuex-module-decorators';
import type { ContractOffer } from '../types/contractOffer';
import { ApiModule } from './ApiModule';
import { ApiInterface } from './apiInterface';
import { partnerOrdersStore } from './';

type ApiAware = { $api: ApiInterface };

export abstract class BaseOfferStore extends ApiModule {
  customerNumber!: string;

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  get currentCustomerNumber(): string {
    return this.customerNumber;
  }

  @Action
  async fetchFeedbackOfferDetails(params: any) {
    const orderHash = params.orderHash;
    const xlanguage = params.xlanguage;
    try {
      const response = await (this.context.state as ApiAware).$api.get(`/feedback/offer/${orderHash}`, {
        params: {
          xlanguage,
        },
      });
      this.context.commit('offerDetailSuccess', response.data.data);
    } catch (e) {
      this.context.commit('recordDetailError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  offerDetailSuccess(record: ContractOffer) {
    this.status = { type: 'success' };
    this.record = { ...record };
  }

  @Action
  async acceptPartnerOffer() {
    try {
      if (!this.recordExternalQualifier) {
        this.context.commit('offerNotFound', 'offer not found');
        return;
      }
      const api = (this.context.state as ApiAware).$api;
      const response = await api.put(`/portal/partner/${this.currentCustomerNumber}/offers/${this.recordExternalQualifier}/confirm`, {
        ordererEmail: partnerOrdersStore.orderer?.ordererEmail,
        ordererFirstname: partnerOrdersStore.orderer?.ordererFirstname,
        ordererLastname: partnerOrdersStore.orderer?.ordererLastname,
      });
      this.context.commit('removeCurrentRecord');
      this.context.commit('offerActionAcceptSuccess', response.data.data);
    } catch (e) {
      this.context.commit('offerActionFailed', e as string);
    }
  }

  @Action
  async acceptPartnerOfferWithAlternativeDate(dateOptions: {
    deliveryDate: string;
    deliveryTimeRange: string;
    pickupDate?: string;
    pickupTimeRange?: string;
  }) {
    try {
      if (!this.recordExternalQualifier) {
        this.context.commit('offerError', 'offer not found');
        return;
      }
      const api = (this.context.state as ApiAware).$api;
      const rerquestAlternativeDate = {
        ...dateOptions,
        ordererEmail: partnerOrdersStore.orderer?.ordererEmail,
        ordererFirstname: partnerOrdersStore.orderer?.ordererFirstname,
        ordererLastname: partnerOrdersStore.orderer?.ordererLastname,
      };
      const response = await api.put(
        `/portal/partner/${this.currentCustomerNumber}/offers/${this.recordExternalQualifier}/confirm`,
        rerquestAlternativeDate
      );
      this.context.commit('removeCurrentRecord');
      this.context.commit('offerActionAcceptSuccess', response.data.data);
    } catch (e) {
      if ((e as any).response.status === 422) {
        this.context.commit('offerValidationError', e as string);
      } else {
        this.context.commit('offerActionFailed', e as string);
      }
    }
  }

  @Action
  async declinePartnerOffer() {
    try {
      if (this.recordExternalQualifier === undefined || !this.recordExternalQualifier) {
        this.context.commit('offerActionFailed', 'offer not found');
        return;
      }
      const api = (this.context.state as ApiAware).$api;
      const response = await api.put(`/portal/partner/${this.currentCustomerNumber}/offers/${this.recordExternalQualifier}/decline`, {
        ordererEmail: partnerOrdersStore.orderer?.ordererEmail,
        ordererFirstname: partnerOrdersStore.orderer?.ordererFirstname,
        ordererLastname: partnerOrdersStore.orderer?.ordererLastname,
      });
      this.context.commit('removeCurrentRecord');
      this.context.commit('offerActionDeclineSuccess', response.data.data);
    } catch (e) {
      this.context.commit('offerActionFailed', e as string);
    }
  }

  @Mutation
  offerActionAcceptSuccess() {
    this.status = { type: 'confirmed' };
    this.record = undefined;
  }

  @Mutation
  offerActionDeclineSuccess() {
    this.status = { type: 'declined' };
    this.record = undefined;
  }

  @Mutation
  offerAccepted() {
    this.status = { type: 'confirmed' };
  }

  @Mutation
  offerDeclined() {
    this.status = { type: 'declined' };
  }

  @Mutation
  offerActionFailed(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }
}
