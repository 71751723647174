import { Component, Vue } from 'nuxt-property-decorator';

type Message = string;

@Component
export default class extends Vue {
  showLoading() {
    this.$buefy.toast.open({
      duration: 1000,
      message: this.$t('global.toasts.loading') as string,
      queue: true,
    });
  }

  showSuccess(message?: Message) {
    this.$buefy.toast.open({
      duration: 5000,
      message: message ?? (this.$t('global.toasts.success') as string),
      type: 'is-success',
      queue: true,
    });
  }

  showError(message?: Message): void {
    this.$buefy.toast.open({
      duration: 5000,
      message: message ?? (this.$t('global.toasts.error') as string),
      type: 'is-danger',
      queue: true,
    });
  }
}
