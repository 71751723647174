
  // EXTERNAL DEPENDENCIES
  import { Component, mixins, Watch, Prop } from 'nuxt-property-decorator';
  import { ValidationObserver } from 'vee-validate';

  // MIXINS
  import currentUser from '../mixins/currentUser';
  import storeSubscriber from '../mixins/storeSubscriber';
  import toast from '../mixins/toast';

  // STORE
  import { accountStore, serviceLocationStore } from '../store';

  // COMPONENTS
  import IconBase from '../components/shared/IconBase.vue';
  import AutocompleteCheckbox from './AutocompleteCheckbox.vue';
  import FormField from './shared/FormField.vue';
  import LocationAutocomplete from './LocationAutocomplete.vue';
  import SiteButton from './shared/SiteButton.vue';

  // UTILS
  import { filterOptions } from '~/utils/helpers';
  import { geoLocate } from '~/services/google';

  export enum ActiveStates {
    ACTIVE = 'Active',
    ARCHIVED = 'Archived',
    IN_ACTIVE = 'In Active',
  }

  type ServiceLocationFormData = {
    active: string;
    city: string;
    contactPerson: {
      email?: string;
      name: string;
      phone: string;
    };
    externalQualifier?: string;
    name: string;
    organisation: {
      responsiblePerson: string;
      serviceLocationID1: string;
      serviceLocationID2: string;
    };
    remark: string;
    state: string;
    street: string;
    streetNo: string;
    zipCode: string;
    employees: string[];
    systemId?: string;
    allowedUsers?: string[];
    activeContracts?: boolean;
    officialProducerNo?: string;
  };

  @Component({
    components: {
      AutocompleteCheckbox,
      FormField,
      IconBase,
      LocationAutocomplete,
      SiteButton,
      ValidationObserver,
    },
  })
  export default class ServiceLocationDialog extends mixins(currentUser, toast, storeSubscriber) {
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
    };

    @Prop()
    serviceLocation?: ServiceLocationFormData;

    autoCompleteEmployees: string = '';
    autocompletes: any = {
      zipCodeAutocomplete: 'zipCodeAutocomplete',
      streetAutocomplete: 'streetAutocomplete',
    };
    hideAutoCompleteEmployees: boolean = false;
    formInput: ServiceLocationFormData = {
      active: ActiveStates.ACTIVE,
      city: '',
      contactPerson: {
        email: '',
        name: '',
        phone: '',
      },
      externalQualifier: '',
      name: '',
      organisation: {
        responsiblePerson: '',
        serviceLocationID1: '',
        serviceLocationID2: '',
      },
      remark: '',
      state: '',
      street: '',
      streetNo: '',
      zipCode: '',
      employees: [],
      systemId: '',
      officialProducerNo: '',
    };
    form!: any;

    mounted() {
      accountStore.isTutorialActive ? (this.formInput = this.getFormInputForTutorial) : this.setData(this.serviceLocation);

      this.subscribeEvents((mutation) => {
        if (mutation.type === 'ServiceLocation/serviceLocationCreated') {
          this.showSuccess(this.$t('serviceLocationForm.events.created') as string);
          this.closeForm();
        } else if (mutation.type === 'ServiceLocation/serviceLocationUpdated') {
          this.showSuccess(this.$t('serviceLocationForm.events.updated') as string);
          this.closeForm();
        } else if (mutation.type === 'ServiceLocation/serviceLocationCreateFailed') {
          this.showError(this.$t('serviceLocationForm.events.createFailed') as string);
        } else if (mutation.type === 'ServiceLocation/serviceLocationUpdateFailed') {
          this.showError(this.$t('serviceLocationForm.events.updateFailed') as string);
        }
      });

      const refs = this.$refs as any;
      refs[this.autocompletes.zipCodeAutocomplete].$on(`location-updated-${this.autocompletes.zipCodeAutocomplete}`, (params: any) => {
        this.setFormInputFiels(params, 'zipCode');
      });
      refs[this.autocompletes.streetAutocomplete].$on(`location-updated-${this.autocompletes.streetAutocomplete}`, (params: any) => {
        this.setFormInputFiels(params, 'street');
      });
    }

    updated() {
      const tmpFormInput = this.formInput;
      this.resetForm();
      this.$nextTick(() => {
        this.formInput = tmpFormInput;
      });
    }

    get activeStateOptions() {
      return [
        { value: ActiveStates.ACTIVE, label: this.$t('global.active') as string },
        { value: ActiveStates.IN_ACTIVE, label: this.$t('global.inactive') as string },
        { value: ActiveStates.ARCHIVED, label: this.$t('global.archived') as string },
      ].filter((state) => {
        if (!this.serviceLocation && state.value === ActiveStates.ARCHIVED) return false;
        if (this.hasActiveContracts) return state.value !== ActiveStates.ARCHIVED;
        return true;
      });
    }

    get isArchived() {
      return this.serviceLocation?.active === 'Archived';
    }

    get filteredEmployeeList() {
      return filterOptions(this.employeeList, this.autoCompleteEmployees);
    }

    get selectedEmployeeList() {
      return this.serviceLocation?.allowedUsers ?? [];
    }

    get employeeList() {
      return accountStore.employeeList ?? [];
    }

    get statusMessage() {
      return serviceLocationStore.statusMessage;
    }

    get hasActiveContracts() {
      return !!this.serviceLocation?.activeContracts;
    }

    get getFormInputForTutorial() {
      return {
        active: 'Active',
        city: 'Dernau',
        contactPerson: { name: 'Hans Peter', phone: '0123 234 1234', email: 'test@mail.com' },
        externalQualifier: 'ES-TEST-12345',
        name: 'Tutorial-Leistungsort',
        officialProducerNo: '',
        organisation: { responsiblePerson: 'Test Tester', serviceLocationID1: '310-085780', serviceLocationID2: '' },
        remark: 'Lorem Ipsum te el allen ole.',
        state: 'NW',
        street: 'Teststr',
        streetNo: '0',
        zipCode: '11111',
        employees: [],
      };
    }

    @Watch('serviceLocation', { deep: true, immediate: true })
    serviceLocationChanged(serviceLocation: any) {
      !accountStore.isTutorialActive && this.setData(serviceLocation);
    }

    validateResponsiblePerson() {
      if (this.autoCompleteEmployees.length > 0 && this.formInput.organisation.responsiblePerson.length !== this.autoCompleteEmployees.length) {
        this.formInput.organisation.responsiblePerson = this.autoCompleteEmployees;
      }
    }

    onBlur($event: any) {
      this.validateResponsiblePerson();
      let autocompleteComp: any = [];
      autocompleteComp = $event.path?.filter((path: any) => {
        return path.className?.includes('autocomplete');
      });
      if (autocompleteComp) {
        // FIXME?: Needed because there is a conflict with @select.
        setTimeout(() => {
          autocompleteComp[0].__vue__.isActive = false;
        }, 200);
      }
    }

    onTyping(value: string): void {
      value.length < 3 ? (this.hideAutoCompleteEmployees = true) : (this.hideAutoCompleteEmployees = false);
    }

    async handleSubmit() {
      const coords = await geoLocate(this, this.formInput.street, this.formInput.streetNo, this.formInput.zipCode, this.formInput.city);
      if (coords === undefined) {
        this.showError('Die gewählte Adresse existiert nicht');
        return;
      }

      if (this.serviceLocation) {
        this.$store.dispatch('ServiceLocation/updateServiceLocation', { ...this.formInput });
      } else {
        this.$store.dispatch('ServiceLocation/createServiceLocation', { ...this.formInput });
      }
    }

    setData(serviceLocation?: ServiceLocationFormData) {
      if (!serviceLocation) {
        this.resetForm();
        return;
      }

      this.formInput = {
        active: serviceLocation.active,
        city: serviceLocation.city,
        contactPerson: {
          email: serviceLocation.contactPerson.email,
          name: serviceLocation.contactPerson.name,
          phone: serviceLocation.contactPerson.phone,
        },
        externalQualifier: serviceLocation.externalQualifier,
        name: serviceLocation.name,
        organisation: {
          responsiblePerson: serviceLocation.organisation.responsiblePerson,
          serviceLocationID1: serviceLocation.organisation.serviceLocationID1,
          serviceLocationID2: serviceLocation.organisation.serviceLocationID2,
        },
        remark: serviceLocation.remark,
        state: serviceLocation.state ?? undefined,
        street: serviceLocation.street,
        streetNo: serviceLocation.streetNo,
        zipCode: serviceLocation.zipCode,
        employees: serviceLocation.allowedUsers ?? [],
        systemId: serviceLocation.systemId,
        officialProducerNo: serviceLocation.officialProducerNo,
      };
      this.autoCompleteEmployees = serviceLocation.organisation.responsiblePerson;

      setTimeout(() => {
        this.$refs.validationObserver.validate();
      }, 200);
    }

    setFormInputFiels(params: any, type: 'street' | 'zipCode') {
      if (
        (this.formInput.city !== params?.city && this.formInput.zipCode !== params?.zipCode) ||
        (this.formInput.zipCode !== params?.zipCode && params?.zipCode.length > 0) ||
        this.formInput.zipCode.length === 0
      ) {
        this.formInput.zipCode = params?.zipCode;
      }
      if (type === 'street') {
        this.formInput.street = params?.street ?? '';
      }
      this.formInput.city = params?.city;
      this.formInput.state = params?.state;
      this.formInput.streetNo = params?.streetNo ?? '';
      this.$forceUpdate();
    }

    changeFormInputZipCode(value: string, isCrossClicked: boolean): void {
      if (isCrossClicked) {
        this.formInput.city = '';
      }
      this.formInput.zipCode = value;
    }

    changeFormInputStreet(value: string, _isCrossClicked: boolean): void {
      this.formInput.street = value;
    }

    resetForm() {
      const wrapperElem = document.getElementById('service-location-form');
      if (wrapperElem !== null) {
        wrapperElem.scrollIntoView();
      }

      if (this.$refs.validationObserver) {
        this.$refs.validationObserver.reset();
      }
      this.formInput = {
        active: ActiveStates.ACTIVE,
        city: '',
        contactPerson: {
          email: '',
          name: '',
          phone: '',
        },
        externalQualifier: '',
        name: '',
        organisation: {
          responsiblePerson: '',
          serviceLocationID1: '',
          serviceLocationID2: '',
        },
        remark: '',
        state: 'NRW',
        street: '',
        streetNo: '',
        zipCode: '',
        employees: [],
        systemId: '',
        officialProducerNo: '',
      };
      this.autoCompleteEmployees = '';
    }

    closeForm() {
      this.$emit('close');
      this.resetForm();
      (this.$parent as any).close();
    }
  }
