import { Component, Vue } from 'nuxt-property-decorator';
import { ApiModule } from '../store/ApiModule';

type EventHandlerSet = Record<string, (payload: any) => {} | void>;
@Component
export default class extends Vue {
  store!: ApiModule;
  subscription?: () => void | undefined;

  subscribeEvents(callback: (mutation: { type: string; payload: any }) => void) {
    this.subscription = this.$store.subscribe(callback);
  }

  subscribeEventCollection(eventHandlers: EventHandlerSet) {
    this.subscription = this.$store.subscribe((mutation) => {
      Object.keys(eventHandlers).forEach((key: string) => {
        const regex = new RegExp(key.replace('/', '\\/'), 'g');
        if (mutation.type.match(regex)) {
          eventHandlers[key](mutation.payload);
        }
      });
    });
  }

  destroyed() {
    if (this.subscription) {
      this.subscription();
    }
  }
}
