export enum OfferType {
  // Dispo und Angebote
  CYCLE = 'Cyclic Contract',
  DIRECT = 'Direct Loading Contract',
  ONDEMAND = 'On Demand Contract',
  SALES = 'Sales Contract',
  SHOP = 'Shop',
  STANDARD = 'Standard Contract',

  // nur Dispo
  CLEARANCE = 'Clearance Order',
  EXCHANGE = 'Exchange Order',
  PICKUP = 'PickUp Order',
  SALES_PICKUP = 'Sales PickUp Order',
}

interface OfferStrategy {
  readonly enableWatcher: boolean;
  readonly firstDateLabel: string;
  readonly firstDatesDisabled: boolean;
  readonly getHeadline: string;
  readonly isSecondDateRequired: boolean;
  readonly secondDateLabel: string;
  readonly showOnlyConfirm: boolean;
  showPickUpDate(pickupDate?: string): boolean;
}

export const makeOfferStrategy = (type: OfferType): OfferStrategy => {
  switch (type) {
    case OfferType.CYCLE:
      return new OfferTypeCycle();
    case OfferType.DIRECT:
      return new OfferTypeDirect();
    case OfferType.ONDEMAND:
      return new OfferTypeOnDemand();
    case OfferType.SALES:
      return new OfferTypeSales();
    case OfferType.SHOP:
      return new OfferTypeShop();
    case OfferType.STANDARD:
      return new OfferTypeStandard();
    case OfferType.EXCHANGE:
      return new OfferTypeExchange();
    case OfferType.CLEARANCE:
      return new OfferTypeClearance();
    case OfferType.PICKUP:
      return new OfferTypePickup();
    case OfferType.SALES_PICKUP:
      return new OfferTypeSalesPickup();
    default:
      return new OfferTypeShop();
  }
};

abstract class BaseOfferType {
  get firstDateLabel() {
    return 'global.labels.deliveryDate';
  }

  get isFirstDateTimeRangeRequired() {
    return true;
  }

  get secondDateLabel() {
    return 'global.labels.pickupDate';
  }

  get isSecondDateRequired() {
    return true;
  }

  showPickUpDate() {
    return false;
  }

  get firstDatesDisabled() {
    return true;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.assignmentHeadline';
  }

  get showOnlyConfirm() {
    return false;
  }

  get enableWatcher() {
    return false;
  }
}

class OfferTypeShop extends BaseOfferType implements OfferStrategy {
  showPickUpDate(pickupDate?: string) {
    return pickupDate !== undefined && pickupDate.length > 0;
  }
}
class OfferTypeStandard extends BaseOfferType implements OfferStrategy {
  showPickUpDate(pickupDate?: string) {
    return pickupDate !== undefined && pickupDate.length > 0;
  }
}
class OfferTypeDirect extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.chargeDate';
  }
}
class OfferTypeSales extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.salesDate';
  }
}
class OfferTypeCycle extends BaseOfferType implements OfferStrategy {
  get secondDateLabel() {
    return 'global.labels.firstDeliveryDate';
  }

  showPickUpDate() {
    return true;
  }

  get firstDatesDisabled() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.appointmentHeadline';
  }

  get enableWatcher() {
    return true;
  }
}
class OfferTypeOnDemand extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.deliveryDate';
  }

  get firstDatesDisabled() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.appointmentHeadline';
  }

  get showOnlyConfirm() {
    return false;
  }

  get enableWatcher() {
    return true;
  }
}

class OfferTypeExchange extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.exchangeDate';
  }

  get isFirstDateTimeRangeRequired() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.exchangeHeadline';
  }

  get showOnlyConfirm() {
    return true;
  }
}

class OfferTypeClearance extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.nextClearanceDate';
  }

  get isFirstDateTimeRangeRequired() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.clearanceHeadline';
  }

  get showOnlyConfirm() {
    return true;
  }
}

class OfferTypePickup extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.nextPickupDate';
  }

  get isFirstDateTimeRangeRequired() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.pickupHeadline';
  }

  get showOnlyConfirm() {
    return true;
  }
}

class OfferTypeSalesPickup extends BaseOfferType implements OfferStrategy {
  get firstDateLabel() {
    return 'global.labels.nextPickupDate';
  }

  get isFirstDateTimeRangeRequired() {
    return false;
  }

  get getHeadline() {
    return 'pages.feedback.offerDialog.salesPickupHeadline';
  }

  get showOnlyConfirm() {
    return true;
  }
}
