
  import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import currentUser from '../mixins/currentUser';
  import storeSubscriber from '../mixins/storeSubscriber';
  import { accountStore, serviceLocationStore } from '../store';
  import dataStore from '../mixins/dataStore';
  import toast from '../mixins/toast';
  import { AccountType, KeycloakRealm } from '../types/account';
  import { AccountPermission, Permission } from '../store/entities/account';
  import RichTextBox from '../components/shared/RichTextBox.vue';
  import DatePicker from './common/DatePicker.vue';
  import FormField from './shared/FormField.vue';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';
  import AutocompleteCheckbox from './AutocompleteCheckbox.vue';
  import { Tutorial, TutorialValue } from '~/store/entities/account';

  type Employee = {
    email: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    tutorials: Tutorial;
    permissions: { serviceLocations: string; invoicing: string; administration: string };
    allowedServiceLocations: string[];
    realm: string;
    accountIdentifier: {
      type: AccountType;
      companyExternalQualifier: string;
    };
  };

  @Component({
    components: {
      SiteButton,
      FormField,
      IconBase,
      DatePicker,
      AutocompleteCheckbox,
      ValidationObserver,
      RichTextBox,
    },
    middleware: ['auth'],
    meta: { auth: { role: 'admin' } }, // router property to pass values to middlewares
  })
  export default class AddEmployeeForm extends mixins(currentUser, dataStore, storeSubscriber, toast) {
    preselectedServiceLocations: string[] = [];
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
    };

    @Prop()
    showPermissions?: boolean;

    @Prop()
    employee?: Employee;

    @Prop()
    changeDataFromLoggedInUser!: boolean;

    Permission = Permission;
    accountStore = accountStore;
    KeycloakRealm = KeycloakRealm;

    showServiceLocationAdministration: boolean = false;
    showAccessableServiceLocations: boolean = false;
    showInvoicesAndCoupons: boolean = false;
    showEmployeeAdministration: boolean = false;

    formInput: any = {
      email: '',
      firstname: '',
      lastname: '',
      phoneNumber: '',
      tutorials: {},
      permissions: {},
      serviceLocations: [],
      accountIdentifier: {
        type: '' as AccountType,
        companyExternalQualifier: '',
      },
    };

    isSubmitBtnLoading: boolean = false;

    mounted() {
      if (this.employee) {
        this.setEmployee(JSON.parse(JSON.stringify(this.employee)));
      } else {
        this.resetForm();
      }
      // Subscriber
      this.subscribeEvents((mutation) => {
        if (mutation.type.match(/Account\/account(.*)/)) {
          this.isSubmitBtnLoading = false;
        }
        if (mutation.type.match(/Account\/account(.*)Success/)) {
          this.closeModal();
        }
      });
    }

    get getFormTitle() {
      return this.getIsNewEmployee
        ? this.$t('addEmployeeForm.headline')
        : this.changeDataFromLoggedInUser
          ? this.$t('addEmployeeForm.headlineMyDataAlt')
          : this.$t('addEmployeeForm.headlineAlt');
    }

    get getIsNewEmployee(): boolean {
      return !this.employee || !this.employee.permissions;
    }

    get serviceLocationList() {
      return serviceLocationStore.allServiceLocationList;
    }

    get getSortedPermissionKeys() {
      return ['distribution', 'disposition', 'invoicing', 'administration'];
    }

    handleCancel() {
      (this.$parent as any).close();
    }

    handleSubmit() {
      this.isSubmitBtnLoading = true;
      if (this.getIsNewEmployee) {
        this.$store.dispatch('Account/createAccount', { ...this.formInput, realm: accountStore.realm });
      } else {
        const formData = { ...this.formInput, realm: accountStore.realm };
        this.$store.dispatch('Account/updateAccount', { formData, isEmployee: this.changeDataFromLoggedInUser });
      }
    }

    resetForm() {
      this.$refs.validationObserver?.reset();
      this.formInput.email = '';
      this.formInput.firstname = '';
      this.formInput.lastname = '';
      this.formInput.phoneNumber = '';
      this.formInput.serviceLocations = [];
      this.setInitFormInputPermissions();
      this.setInitFormInputTutorials();

      accountStore.resetCurrentEmployeeRecord();
    }

    setInitFormInputPermissions(): AccountPermission {
      return (this.formInput.permissions =
        this.accountStore.realm === KeycloakRealm.CUSTOMER
          ? { serviceLocations: Permission.WRITE, invoicing: Permission.READ, administration: Permission.NONE }
          : { distribution: Permission.WRITE, invoicing: Permission.WRITE, disposition: Permission.WRITE, administration: Permission.READ });
    }

    setInitFormInputTutorials(): Tutorial {
      return (this.formInput.tutorials =
        this.accountStore.realm === KeycloakRealm.CUSTOMER
          ? {
              showTutorialDashboard: TutorialValue.TRUE,
              showTutorialServiceLocation: TutorialValue.FALSE,
              showTutorialInvoicing: TutorialValue.FALSE,
              showTutorialAdministration: TutorialValue.FALSE,
            }
          : // TODO: add disposer tut properties
            {});
    }

    setEmployee(employee: Employee): void {
      this.formInput.accountIdentifier = employee.accountIdentifier;
      this.formInput.email = employee.email;
      this.formInput.firstname = employee.firstname;
      this.formInput.lastname = employee.lastname;
      this.formInput.permissions = employee.permissions;
      this.formInput.phoneNumber = employee.phoneNumber;
      this.formInput.tutorials = employee.tutorials;
      this.preselectedServiceLocations = employee.allowedServiceLocations ?? [];
    }

    closeModal() {
      this.resetForm();
      (this.$parent as any).close();
    }

    @Watch('employee', { deep: true, immediate: true })
    employeeChanged(employee: any) {
      if (employee) this.setEmployee(employee);
    }

    @Watch('formInput.permissions.administration')
    onAdministratorPermissionChange(newPermission: string) {
      if (newPermission === 'none') this.formInput.tutorials.showTutorialAdministration = TutorialValue.FALSE;
    }
  }
