import { PartialDeep } from 'type-fest';
import { Mutation, Action, VuexModule } from 'vuex-module-decorators';
import type { ApiInterface } from './apiInterface';

type DataRecord = { externalQualifier: string };
type StatusType = 'loading' | 'error' | 'notfound' | 'success' | 'confirmed' | 'declined' | '';
const LIMIT_PER_PAGE = 10;

export abstract class ApiModule extends VuexModule {
  protected $api!: ApiInterface;
  records: DataRecord[] = [];
  record?: PartialDeep<DataRecord | undefined> = undefined;
  visibleRecords: DataRecord[] = [];

  tableLoading: boolean = true;
  tableError: string = '';

  countVisibleRecords: number = 0;
  currentPage = 1;

  status: { type: StatusType; msg?: string } = { type: '' };

  filtersExist: boolean = false;
  currentSort?: { field: string; order: string };

  customerNumber: string = '';

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  get currentCustomerNumber() {
    return this.customerNumber;
  }

  abstract get serviceUrl(): string;
  abstract get tableRecords(): any[];
  abstract get tableModalContent(): object | undefined;

  @Mutation
  setApiInstance(api: ApiInterface) {
    this.$api = api;
  }

  get api(): ApiInterface {
    return this.$api;
  }

  @Action({ rawError: true })
  async fetchRecords(payload: any) {
    const xlanguage = payload.xlanguage;
    try {
      this.context.commit('loadTable');
      const response = await this.$api.get(this.serviceUrl, {
        params: {
          xlanguage,
        },
      });
      this.context.commit('setRecords', response.data.data);
      this.context.commit('showNextPage');
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action({ rawError: true })
  async getRecordDetails(recordHash: string) {
    try {
      const response = await this.$api.get(`${this.serviceUrl}/${recordHash}`);
      this.context.commit('recordDetailSuccess', response.data.data);
    } catch (e) {
      this.context.commit('recordDetailError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  recordNotFound(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  @Mutation
  recordDetailError(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
    this.tableLoading = false;
  }

  @Mutation
  recordDetailSuccess(record: DataRecord) {
    this.status = { type: 'success' };
    this.record = { ...record };
  }

  @Mutation
  loadTable() {
    this.tableLoading = true;
  }

  @Mutation
  hideLoader() {
    this.tableLoading = false;
  }

  @Mutation
  setRecords(records: DataRecord[]) {
    this.tableLoading = false;
    this.records = records;
  }

  @Mutation
  setCurrentRecordById(externalQualifier: string) {
    this.record = this.records.find((record) => record.externalQualifier === externalQualifier);
    this.status = { type: '' };
  }

  @Mutation
  recordsError(error: string) {
    this.tableLoading = false;
    this.tableError = error;
  }

  @Mutation
  showNextPage(limitPerPage: number = LIMIT_PER_PAGE) {
    if (this.countVisibleRecords <= this.records.length - 1) {
      for (let i = this.countVisibleRecords; i < limitPerPage * this.currentPage; i++) {
        if (i > this.records.length - 1) {
          break;
        }
        this.visibleRecords.push(this.records[i]);
        this.countVisibleRecords++;
      }
      this.currentPage++;
    }
  }

  @Mutation
  actionLoading() {
    this.status = { type: 'loading' };
  }

  @Mutation
  setStatus(type: StatusType, errorMessage?: string) {
    this.status = { type, msg: errorMessage as string };
  }

  @Mutation
  setRecord(record?: DataRecord | Record<string, unknown>) {
    this.status = { type: '' };
    this.record = record;
  }

  @Mutation
  setCurrentRecord(externalQualifier: string) {
    this.status = { type: '' };
    this.record = this.records.find((record) => record.externalQualifier === externalQualifier);
  }

  @Mutation
  updateRecord(updated: DataRecord) {
    this.status = { type: 'success' };
    this.record = updated as PartialDeep<DataRecord>;
    this.records.filter((record) => record.externalQualifier === updated.externalQualifier);
    this.visibleRecords.filter((record) => record.externalQualifier === updated.externalQualifier);
    this.records.push(updated);
    this.visibleRecords.push(updated);
  }

  @Mutation
  removeCurrentRecord() {
    this.records = this.records.filter((record) => record.externalQualifier !== this.record?.externalQualifier);
    this.visibleRecords = this.visibleRecords.filter((record) => record.externalQualifier !== this.record?.externalQualifier);
  }

  @Mutation
  resetCurrentRecord() {
    this.status = { type: '' };
    this.record = undefined;
  }

  @Mutation
  setFilters(filtersExist: boolean) {
    this.filtersExist = filtersExist;
  }

  @Mutation
  setSort(sort: { field: string; order: string }) {
    this.currentSort = sort;
  }

  get recordExternalQualifier(): string | undefined {
    return this.record?.externalQualifier;
  }

  get hasReachedLastPage(): boolean {
    return this.visibleRecords.length >= this.records.length;
  }

  get currentRecord(): PartialDeep<DataRecord | undefined> {
    return this.record;
  }

  get hasCurrentRecord(): boolean {
    return this.record !== undefined;
  }

  get statusMessage(): {} {
    return this.status;
  }

  get isActionLoading(): {} {
    return this.status.type === 'loading';
  }

  get hasActiveFilter() {
    return this.filtersExist;
  }

  get isTableLoading(): boolean {
    return this.tableLoading;
  }
}
