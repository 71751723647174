import { extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { double, email, max, max_value, min, min_value, numeric, required } from 'vee-validate/dist/rules';
import { Context } from '@nuxt/types';
import { isPostalCode, isIBAN, isBIC } from 'class-validator';

export default function configureVeeValidate(context: Context) {
  setVeeValidateRules(context);

  context.app.i18n.onLanguageSwitched = () => {
    setVeeValidateRules(context);
  };
}

function setVeeValidateRules(context: Context) {
  extend('alpha_num_1', {
    // eslint-disable-next-line camelcase
    validate: (value: string) => {
      // Accept: a-z, A-Z, 0-9, including _ (underscore), - (minus) and / (forward slash)
      const regexp = /^[\w-/]+$/;
      return regexp.test(value);
    },
    message: 'Es sind nur Buchstaben, Zahlen und folgende drei Sonderzeichen: _ (Unterstrich), - (Minus) und / (Schrägstrich) erlaubt.',
  });

  extend('bic', {
    validate: (value) => {
      return isBIC(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.bic') as string,
  });

  extend('double', {
    ...double,
    validate: (value, params: Record<string, any>) => {
      return new RegExp('^[0-9]+(?:(,|.)[0-9]{1,' + params.decimals + '})?$').test(value);
    },
    message: 'Bitte geben Sie eine gültige Gleitkommazahl mit maximal {decimals} Stellen ein.',
  });

  extend('email', {
    ...email,
    message: context.app.i18n.t('global.forms.errorMessages.email') as string,
  });

  extend('iban', {
    validate: (value) => {
      return isIBAN(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.iban') as string,
  });

  extend('max', {
    ...max,
    message: context.app.i18n.t('global.forms.errorMessages.max') as string,
  });

  extend('max_value', {
    // eslint-disable-next-line camelcase
    ...max_value,
    validate: (value, params: Record<string, any>) => {
      const normalizedValue = parseFloat(value.replace(',', '.'));
      return normalizedValue <= params.max;
    },
    message: 'Die maximale Menge beträgt {max}.',
  });

  extend('min', {
    ...min,
    message: context.app.i18n.t('global.forms.errorMessages.min') as string,
  });

  extend('min_value', {
    // eslint-disable-next-line camelcase
    ...min_value,
    validate: (value, params: Record<string, any>) => {
      const normalizedValue = parseFloat(value.replace(',', '.'));
      return normalizedValue >= params.min;
    },
    message: 'Die minimale Menge beträgt {min}.',
  });

  extend('greater_than_zero', {
    // eslint-disable-next-line camelcase
    validate: (value, params: Record<string, any>) => {
      const normalizedValue = parseFloat(value.replace(',', '.'));
      return normalizedValue > 0;
    },
    message: 'Die Menge muss größer als 0 sein.',
  });

  extend('numeric', {
    ...numeric,
    message: 'Bitte geben Sie eine positive Zahl ein.',
  });

  extend('phone', {
    validate: (value: string) => {
      // Accept: 0-9 including whitespace, - (minus), + (plus),
      const regexp = /^[\s0-9-+()]+$/;
      return regexp.test(value);
    },
    message: context.app.i18n.t('global.forms.errorMessages.phone') as string,
  });

  extend('required', {
    ...required,
    message: context.app.i18n.t('global.forms.errorMessages.required') as string,
  });

  extend('zipCode', {
    validate: (value) => {
      return isPostalCode(value, 'DE');
    },
    message: context.app.i18n.t('global.forms.errorMessages.zipCode') as string,
  });
}
