import { Context } from '@nuxt/types';
import { Store } from 'vuex';
import {
  accountStore,
  contractsStore,
  customerRequestsStore,
  dispositionStore,
  initialiseStores,
  invoiceStore,
  offerStore,
  partnerInvoiceStore,
  partnerOrdersStore,
  portalStore,
  serviceLocationStore,
  userStore,
} from '~/utils/store-accessor';

const initializer = (store: Store<any>) => initialiseStores(store);
export const plugins = [initializer];

export const actions = {
  async nuxtServerInit(_storeCtx: Context, _ctx: Context) {},
};

export {
  accountStore,
  contractsStore,
  customerRequestsStore,
  dispositionStore,
  invoiceStore,
  offerStore,
  partnerInvoiceStore,
  partnerOrdersStore,
  portalStore,
  serviceLocationStore,
  userStore,
};
