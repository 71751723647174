
  import { ValidationObserver } from 'vee-validate';
  import { Component, mixins, Watch, Prop } from 'nuxt-property-decorator';
  import currentUser from '../mixins/currentUser';
  import toast from '../mixins/toast';
  import storeSubscriber from '../mixins/storeSubscriber';
  import dataStore from '../mixins/dataStore';
  import OrderFeedbackType from './OrderFeedbackType';
  import FormField from './shared/FormField.vue';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';
  import { XLanguageService } from '~/types/content';

  @Component({
    components: {
      ValidationObserver,
      SiteButton,
      FormField,
      IconBase,
    },
    middleware: ['auth'],
    meta: { auth: { role: 'admin' } }, // router property to pass values to middlewares
  })
  export default class OrderFeedbackForm extends mixins(currentUser, storeSubscriber, toast, dataStore) {
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
    };

    validForm: Boolean = false;
    OrderFeedbackType: any = OrderFeedbackType;
    loadingButton: boolean = false;

    langService = new XLanguageService();

    formData: any = {
      orderFeedbackType: OrderFeedbackType.Incident,
      description: '',
    };

    @Prop()
    orderFeedbackExternalQualifier?: string;

    @Watch('formData.orderFeedbackType')
    validateRadioButtons() {
      this.validateFormData();
    }

    @Watch('formData.description')
    validateDescription() {
      this.validateFormData();
    }

    validateFormData() {
      // Check Radio Buttons Input

      const radioButtonValid =
        this.formData.orderFeedbackType &&
        this.formData.orderFeedbackType !== '' &&
        (this.formData.orderFeedbackType === OrderFeedbackType.Incident ||
          this.formData.orderFeedbackType === OrderFeedbackType.NonService ||
          this.formData.orderFeedbackType === OrderFeedbackType.Other);

      // Check Description Input
      const descriptionValid = this.formData.description && this.formData.description.length >= 4 && this.formData.description.length <= 80;

      // Submit button
      this.validForm = radioButtonValid && descriptionValid;
    }

    handleCancel() {
      this.resetForm();
      (this.$parent as any).close();
    }

    handleSendOrderFeedback() {
      this.loadingButton = true;
      setTimeout(() => {
        if (this.validForm) {
          const data: Record<string, unknown> = {
            externalQualifier: this.orderFeedbackExternalQualifier,
            feedbackType: this.formData.orderFeedbackType,
            feedbackNote: this.formData.description,
            xlanguage: this.langService.getLanguageString(this.$i18n.locale),
          };

          this.$store.dispatch('CustomerContracts/createOrderFeedback', data);
        }
      }, 1000);
    }

    resetForm() {
      this.formData.orderFeedbackType = OrderFeedbackType.Incident;
      this.formData.description = '';
      this.validForm = false;
    }

    mounted() {
      this.handleEvents();
    }

    handleEvents() {
      this.subscribeEvents((mutation) => {
        if (mutation.type === 'CustomerContracts/createOrderFeedbackSuccess') {
          this.showSuccess(this.$t('global.toasts.orderFeedbackSuccess') as string);
          this.handleCancel();
        }
        if (mutation.type === 'CustomerContracts/createOrderFeedbackError') {
          this.showError(this.$t('global.toasts.orderFeedbackFailed') as string);
          this.loadingButton = false;
        }
      });
    }
  }
