export enum XLanguage {
  DE = 'de-DE',
  EN = 'en-US',
}

export class XLanguageService {
  getLanguageString(langstring: string) {
    switch (langstring) {
      case 'de': {
        return 'de-DE';
      }
      case 'en': {
        return 'en-US';
      }
      default: {
        return 'de-DE';
      }
    }
  }
}
