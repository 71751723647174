import { Context, Middleware } from '@nuxt/types';
import logger from '@redooo/shared/dist/services/logging';
import { accountStore } from '../store';
import { ErrorType } from '../types/errorTypes';
import { isAnonymousRoute, isHomeRoute, redirectToHomeRoute } from '../services/auth';
import { AccountRole } from '~/types/account';

const isUserAuthorized = (context: Context): boolean => {
  try {
    if (accountStore.isAdmin) return true;

    const definedRole = (context.route as any).meta.find((meta: Record<string, any>) => {
      return meta.auth.roles;
    });

    if (definedRole?.auth.roles.length > 0) return definedRole.auth.roles.filter((el: AccountRole) => accountStore.roles.includes(el)).length > 0;
    else return true;
  } catch (e) {
    logger.error('isUserAuthorized - catch', context.route);
    return false;
  }
};

const authMiddleware: Middleware = (context: Context) => {
  if (process.server || isAnonymousRoute(context.route.path)) return;

  if (accountStore.accountFailedWith503) {
    logger.warn('account not available', context.route.path);
    context.error({ message: ErrorType.NOT_AVAILABLE, path: context.route.path });
  } else if (!accountStore.isAuthenticated) {
    if (isHomeRoute(context)) redirectToHomeRoute(context, window.location);
    logger.warn("user isn't authenticated for", context.route.path);
    context.error({ message: ErrorType.NOT_AUTHENTICATED, path: context.route.path });
  } else if (!isUserAuthorized(context)) {
    logger.warn('sorry but you arent authorized for this route', context.route.path);
    context.error({ message: ErrorType.NOT_AUTHORIZEDROUTE, path: context.route.path });
  }
};

export default authMiddleware;
