
  import dayjs from 'dayjs';
  import { Prop, Component, mixins, Watch } from 'nuxt-property-decorator';
  import { HolidayCalendarService, DeliveryDateService } from '@redooo/shared/dist/services';
  import IconBase from '../shared/IconBase.vue';
  import SiteButton from '../shared/SiteButton.vue';
  import FormField from '../shared/FormField.vue';
  import setMinMaxPossibleDate from '../../mixins/setMinMaxPossibleDate';

  const convertDate = (utcDate: Date) => {
    return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  };

  @Component({
    components: {
      IconBase,
      SiteButton,
      FormField,
    },
  })
  export default class DatePicker extends mixins(setMinMaxPossibleDate) {
    @Prop({ default: 'NW' })
    countryState?: string;

    @Prop({ default: 'Datum auswählen' })
    placeholder?: string;

    @Prop()
    preselected?: dayjs.Dayjs;

    @Prop()
    label?: string;

    @Prop({ default: 2 })
    minDateDays!: number;

    @Prop()
    minDateDefined?: dayjs.Dayjs;

    @Prop({ default: false })
    maxDateDefined?: dayjs.Dayjs;

    @Prop({ default: 10 })
    maxDateWeeks!: number;

    @Prop()
    displayOnly?: any;

    @Prop({ default: 'is-top-right' })
    position?: string;

    @Prop({ default: false })
    isResetButton?: boolean;

    @Prop({ default: false })
    required!: boolean;

    @Prop({ default: false })
    unselectPreselectedDate!: boolean;

    @Prop()
    rules!: string;

    @Prop()
    disabled?: boolean;

    @Prop({ default: () => [] })
    disabledWeekDays!: number[];

    @Prop({ default: false })
    allowWeekends!: boolean;

    additionalDisabledWeekDays: number[] = [];

    datePickerConfig = {
      holidayDaysLookAhead: 360,
      yearsRange: [-100, 1],
      unselectableDates: [new Date()],
    };

    date = {
      selected: null as unknown as Date,
      focused: new Date(),
    };

    dateService = new DeliveryDateService([]);

    mounted() {
      this.initializeDatePicker();
    }

    get unselectableDaysOfWeek() {
      return this.allowWeekends ? [] : [0, 6, ...this.additionalDisabledWeekDays];
    }

    @Watch('preselected', { deep: true, immediate: true })
    watchPreselected(preselected: dayjs.Dayjs) {
      if (this.unselectPreselectedDate) {
        this.date.selected = this.setMinMaxPossibleDate(preselected, 1).toDate();
      } else this.date.selected = preselected?.toDate();
      // const currentPickUpDate = this.dateService.toDayjs(this.date.selected);
      // this.$emit('update:date', currentPickUpDate);
    }

    @Watch('disabledWeekDays')
    watchDisabledWeekDays(weekDays: number[]) {
      this.additionalDisabledWeekDays = weekDays;
    }

    get minDate(): Date {
      return this.minDateDefined ? this.minDateDefined.toDate() : dayjs().add(this.minDateDays, 'days').toDate();
    }

    get maxDate(): Date {
      return this.maxDateDefined ? this.maxDateDefined.toDate() : dayjs().add(this.maxDateWeeks, 'weeks').toDate();
    }

    get currentLocale() {
      return this.$i18n.locale;
    }

    get displayOnlyDate() {
      return dayjs(this.displayOnly).format('DD.MM.YYYY');
    }

    initializeDatePicker() {
      const holidays = HolidayCalendarService.getHolidays(360, 'DE', this.countryState);
      this.dateService = new DeliveryDateService(holidays);
      this.datePickerConfig.unselectableDates = holidays.map((item) => item.start);
      if (this.unselectPreselectedDate) this.datePickerConfig.unselectableDates.push(this.preselected?.toDate() as Date);
    }

    onDateChange(value: Date) {
      this.date.selected = convertDate(value);
      const currentPickUpDate = this.dateService.toDayjs(this.date.selected);
      this.$emit('update:date', currentPickUpDate);
      this.$emit('change', currentPickUpDate);
    }

    onChangeMonth(value: number) {
      this.$emit('on-change-month', value);
    }

    resetPickUpFields() {
      this.date.selected = null as unknown as Date;
      this.date.focused = null as unknown as Date;
      this.initializeDatePicker();
      this.$emit('reset');
    }

    dateFormatter(date: Date) {
      return dayjs(date).format('DD.MM.YYYY');
    }
  }
