import jwt, { JsonWebTokenError } from 'jsonwebtoken';

class AccessHelpers {
  static useOrderId = async (accessToken: string) => {
    try {
      const decodedToken: any = await jwt.decode(accessToken);

      if (decodedToken && decodedToken.use_custom_order_id && decodedToken.use_custom_order_id === true) {
        return true;
      }
      return false;
    } catch (error) {
      if (error instanceof JsonWebTokenError) {
        console.error('Fehler beim Dekodieren des Tokens:', error.message);
      } else {
        console.error('Unbekannter Fehler:', error);
      }
      return false;
    }
  };
}

export default AccessHelpers;
