import dayjs from 'dayjs';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { getCurrentServerDateTime } from '~/services/portal/date';
import { HolidayCalendarService, DeliveryDateService } from '~/../shared/dist/services';

const initDateService = (countryState: string, serverTime: dayjs.Dayjs) => {
  const holidays = HolidayCalendarService.getHolidays(360, undefined, countryState);
  return new DeliveryDateService(holidays, serverTime);
};

@Module({
  name: 'Delivery',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class DeliveryStoreModule extends VuexModule {
  serverTime?: dayjs.Dayjs;
  countryState: string = 'NW';

  deliveryDate?: dayjs.Dayjs = undefined;
  pickupDate?: dayjs.Dayjs = undefined;

  dateService!: DeliveryDateService;

  @Action
  async fetchServerTime(axios: any) {
    const serverTime = await getCurrentServerDateTime(axios);
    const dateService = initDateService(this.countryState, serverTime);

    const deliveryDate = dateService.getDeliveryDate();
    const pickupDate = dateService.getPickupDate(deliveryDate);
    this.context.commit('updateDates', { serverTime, deliveryDate, pickupDate });
  }

  @Mutation
  updateDeliveryDate(deliveryDate: string) {
    const dateService = initDateService(this.selectedCountryState, this.currentTime);
    this.pickupDate = dateService.getPickupDate(dayjs(deliveryDate).startOf('day'));
  }

  @Mutation
  setCountryState(countryState: string) {
    this.countryState = countryState;
  }

  @Mutation
  updateDates(params: { serverTime: dayjs.Dayjs; deliveryDate: dayjs.Dayjs; pickupDate: dayjs.Dayjs }) {
    this.serverTime = params.serverTime;
    this.deliveryDate = params.deliveryDate.startOf('day');
    this.pickupDate = params.pickupDate.startOf('day');
  }

  setMinMaxPossibleDate(day: dayjs.Dayjs, setShowDays: number = 2) {
    const tempDay = day;
    let dayCounter = 0;
    let counter = 0;
    do {
      dayCounter++;
      if (tempDay?.startOf('day').add(dayCounter, 'day').get('day') === 0 || tempDay?.startOf('day').add(dayCounter, 'day').get('day') === 6)
        continue;
      else counter++;
    } while (counter < setShowDays);
    return tempDay?.startOf('day').add(dayCounter, 'day');
  }

  get selectedCountryState(): string {
    return this.countryState;
  }

  get currentTime(): dayjs.Dayjs {
    return this.serverTime ?? dayjs();
  }

  get nextPossibleDelivery() {
    return this.deliveryDate;
  }

  get nextPossiblePickup() {
    return this.pickupDate;
  }
}
