
  import { Component, Vue } from 'nuxt-property-decorator';

  import { Navigation } from '@redooo/shared/dist/types/content/navigation';
  import { plainToClass } from 'class-transformer';
  import IconBase from '../shared/IconBase.vue';
  import MainNavigation from '../shared/MainNavigation.vue';
  import SiteButton from '../shared/SiteButton.vue';
  import SiteHeader from '../shared/SiteHeader.vue';
  import { accountStore, dispositionStore, offerStore, partnerInvoiceStore, partnerOrdersStore, portalStore } from '../../store';
  import { makeNavigation } from '../../utils/navigation';
  import { getAbsolutePathToHomeRoute } from '../../services/auth';
  import LoginSwitch from './LoginSwitch.vue';

  @Component({
    components: { IconBase, LoginSwitch, MainNavigation, SiteButton, SiteHeader },
  })
  export default class HeaderWrapper extends Vue {
    head() {
      return {
        title: portalStore.isCustomer ? this.$t('dashboard.customerPortalTitle') : this.$t('dashboard.partnerPortalTitle'),
      };
    }

    closeFlyout() {
      (this.$refs as any).mainNavigation.closeFlyout();
    }

    get isSettingActive() {
      return this.$route.name?.match(/^account.*/) !== null;
    }

    gotoContactPage(): string {
      if (window.location.href.includes('site')) {
        if (this.$i18n.locale === 'en') {
          return location.origin.replace(/kundenportal|partnerportal/, 'site') + 'en/contact';
        } else {
          return location.origin.replace(/kundenportal|partnerportal/, 'site') + 'kontakt';
        }
      } else {
        const prodRedoooDomain = 'https://www.redooo.de/';
        const localeContactLink = this.$i18n.locale === 'en' ? 'en/contact' : 'kontakt';
        return prodRedoooDomain + localeContactLink;
      }
    }

    get userName() {
      return accountStore.userName;
    }

    get isLoggedIn() {
      return accountStore.isAuthenticated;
    }

    get permissions() {
      return accountStore.accountPermissions;
    }

    get isDev() {
      return window.origin.match(/localhost/);
    }

    get portalNavigation(): Navigation[] {
      if (!this.isLoggedIn) {
        return [];
      }
      const classNavigation = makeNavigation(
        portalStore.filteredMainNavigationLinks.map((item) => {
          return { ...item, name: this.$t(item.name) as string };
        })
      );

      const plainNavigation = plainToClass(Navigation, classNavigation);

      return plainNavigation.map((itemObj: any, index: number) => {
        if (index === 0) return { ...itemObj, key: 'dashboard' };
        else return { ...itemObj, key: itemObj.slug };
      });
    }

    get portalMetaNavigation(): Navigation[] {
      return makeNavigation(
        portalStore.metaNavigationLinks.map((item) => {
          return { ...item, name: this.$t(item.name) as string };
        })
      );
    }

    get isSiteOnMaintenance(): boolean {
      return !!this.$route.path.match(/(wartungsarbeiten)|(maintenance)/i);
    }

    get getCounterData(): Record<string, number> {
      return {
        offer: offerStore.offerCount,
        disposition: dispositionStore.recordsCount,
        documentationOrdersOpen: partnerOrdersStore.ordersCount,
        invoiceOpen: partnerInvoiceStore.countOpen,
      };
    }

    login() {
      if (accountStore.accountFailedWith503) {
        this.$keycloak.clearToken();
        this.$keycloak.logout();
        return;
      }
      this.$keycloak.login({ redirectUri: getAbsolutePathToHomeRoute(window.location) });
    }

    mounted() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'Account/accountLogoutSuccess') {
          this.$keycloak.logout({ redirectUri: location.origin + '/' });
        }
      });
    }

    logOut() {
      accountStore.logout();
    }
  }
