
  import { ValidationObserver } from 'vee-validate';
  import { Component, mixins, Prop } from 'nuxt-property-decorator';
  import toast from '../mixins/toast';
  import FormField from './shared/FormField.vue';
  import IconBase from './shared/IconBase.vue';
  import SiteButton from './shared/SiteButton.vue';
  import TextBoxFloat from './TextBoxFloat.vue';
  import { downloadFile } from '~/utils/helpers';
  import storeSubscriber from '~/mixins/storeSubscriber';
  import { partnerInvoiceStore } from '~/store';
  import { toCurrency } from '~/plugins/filters';

  @Component({
    methods: { toCurrency },
    components: {
      FormField,
      IconBase,
      SiteButton,
      TextBoxFloat,
      ValidationObserver,
    },
    middleware: ['auth'],
    meta: { auth: { role: 'admin' } }, // router property to pass values to middlewares
  })
  export default class CheckDocumentForm extends mixins(toast, storeSubscriber) {
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
    };

    @Prop()
    record: any;

    formData: any = {
      acceptCreditAdvice: 'accept',
      note: '',
      partnerDocumentNo: '',
    };

    mounted() {
      this.subscribeEventCollection({
        acceptedSuccess: () => {
          this.showSuccess(this.$t('global.toasts.receiptAcceptSuccess') as string);
          this.closeModal();
        },
        declinedSuccess: () => {
          this.showSuccess(this.$t('global.toasts.receiptDeclinedSuccess') as string);
          this.closeModal();
        },
        actionError: () => this.showError(this.$t('global.toasts.receiptFailed') as string),
      });
    }

    handleSubmit() {
      this.$store.dispatch('PartnerInvoice/accept', {
        documentAccepted: this.formData.acceptCreditAdvice === 'accept',
        documentExternalQualifier: this.record.documentExternalQualifier,
        partnerDocumentNo: this.formData.partnerDocumentNo ?? '',
        approvalRemark: this.formData.note ?? '',
      });
    }

    onDownloadInvoice() {
      downloadFile(this.record.invoiceUrl, 'invoice.pdf');
    }

    get isLoading() {
      return partnerInvoiceStore.isActionLoading;
    }

    resetForm() {
      const wrapperElem = document.getElementById('check-document-form');
      if (wrapperElem !== null) {
        wrapperElem.scrollIntoView();
      }

      this.$refs.validationObserver?.reset();
      this.formData.acceptCreditAdvice = '';
      this.formData.note = '';
    }

    closeModal() {
      this.resetForm();
      (this.$parent as any).close();
    }

    getLocale() {
      return this.$i18n.locale;
    }
  }
