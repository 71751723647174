const states: any = {
  BW: 'BW',
  BY: 'BY',
  BE: 'BE',
  BB: 'BB',
  HB: 'HB',
  HH: 'HH',
  HE: 'HE',
  NDS: 'NI',
  NI: 'NI',
  MV: 'MV',
  NRW: 'NW',
  NW: 'NW',
  RP: 'RP',
  SL: 'SL',
  SN: 'SN',
  SA: 'ST',
  ST: 'ST',
  SH: 'SH',
  TH: 'TH',
};

export const stateMapper = (state: string) => {
  return states[state];
};
