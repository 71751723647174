import { Context } from '@nuxt/types';

export const isAnonymousRoute = (path: string) => {
  const routes = ['/logout', '/angebotspruefung', '/en/offerconfirm'];
  return routes.includes(path);
};

export const getAbsolutePathToHomeRoute = (location: Location): string => {
  return location.origin + '/';
};

export const isHomeRoute = (context: Context): boolean => {
  return context.route.path === '/';
};

export const redirectToHomeRoute = (context: Context, location: Location): void => {
  context.redirect(getAbsolutePathToHomeRoute(location));
};
