
  import { Vue, Prop, Component, Watch } from 'nuxt-property-decorator';
  import { DeliveryTimeRange } from '@redooo/shared/dist/types/order/deliveryTimeRange';
  import SiteButton from '../shared/SiteButton.vue';
  import FormField from '../shared/FormField.vue';

  @Component({
    components: {
      SiteButton,
      FormField,
    },
  })
  export default class TimeRangeSelect extends Vue {
    @Prop()
    timeRangeSelectClasses?: string;

    @Prop()
    label?: string;

    @Prop()
    placeholder?: string;

    @Prop()
    timeRangeData!: string[];

    @Prop()
    disabled?: boolean;

    @Prop()
    displayOnly?: string;

    @Prop({ default: false })
    isResetButton?: boolean;

    @Prop({ default: '' })
    selected?: string;

    @Prop({ default: false })
    required!: boolean;

    @Prop()
    rules!: string;

    currentSelect: string | null = null;

    @Watch('selected', { deep: true, immediate: true })
    watchSelected(selected: string) {
      this.currentSelect = selected as string;
    }

    onSelectChange() {
      this.$emit('update:timerange', this.currentSelect);
    }

    get deliveryTimeRangeOptions() {
      return Object.values(DeliveryTimeRange);
    }

    get getLabel() {
      return this.label ? this.label : this.$t('global.labels.timeRange');
    }

    get getPlaceholder() {
      return this.placeholder ? this.placeholder : this.$t('global.forms.placeholders.timerange');
    }

    get timeRangeTranslated() {
      return this.timeRangeData
        ? this.timeRangeData.map((item: string) => {
            switch (item) {
              case 'FullDay':
                return { label: this.$i18n.t('timerange.fullday'), value: this.deliveryTimeRangeOptions[0] };
              case 'Morning':
                return { label: this.$i18n.t('timerange.morning'), value: this.deliveryTimeRangeOptions[1] };
              case 'Afternoon':
                return { label: this.$i18n.t('timerange.afternoon'), value: this.deliveryTimeRangeOptions[2] };
              default:
                return { label: this.$i18n.t('timerange.fullday'), value: this.deliveryTimeRangeOptions[0] };
            }
          })
        : [];
    }

    get displayOnlyTranslate() {
      switch (this.displayOnly) {
        case 'FullDay':
          return this.$i18n.t('timerange.fullday');
        case 'Morning':
          return this.$i18n.t('timerange.morning');
        case 'Afternoon':
          return this.$i18n.t('timerange.afternoon');
        default:
          return this.$i18n.t('timerange.fullday');
      }
    }

    resetPickUpFields() {
      this.$emit('reset');
    }
  }
