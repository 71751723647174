import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { AccountPermission, CustomerAccountPermission, PartnerAccountPermission, Permission } from './entities/account';
import { KeycloakRealm } from '~/types/account';

type NavLink = { name: string; url: string };
const defaultMetaNav = [
  { name: 'header.metaNavi.businessCustomer', url: 'businessCustomer' },
  { name: 'header.metaNavi.privateCustomer', url: 'privateCustomer' },
  { name: 'header.metaNavi.partner', url: 'partner' },
];

export type DashboardPane = {
  headline: string;
  copy: string;
  image?: string;
  link?: string;
  action?: string;
};

interface Portal {
  name(): string;
  mainNavigation(): NavLink[];
  metaNavigation(): NavLink[];
  dashboardPanes(): DashboardPane[];
}

class PartnerPortal implements Portal {
  public name() {
    return 'Partnerportal';
  }

  public mainNavigation(): NavLink[] {
    return [
      { name: 'header.mainNavi.home', url: '/' },
      { name: 'header.mainNavi.offers', url: 'offers' },
      { name: 'header.mainNavi.disposition', url: 'disposition' },
      { name: 'header.mainNavi.operative', url: 'operatives' },
      { name: 'header.mainNavi.documentation', url: 'documentation' },
      { name: 'header.mainNavi.billing', url: 'invoices' },
    ];
  }

  public metaNavigation(): NavLink[] {
    return defaultMetaNav;
  }

  public dashboardPanes(): DashboardPane[] {
    return [
      {
        headline: 'dashboard.offers.header',
        copy: 'dashboard.offers.copy',
        image: 'partner-01-easily-manage.png',
        link: 'offers',
      },
      {
        headline: 'dashboard.disposition.header',
        copy: 'dashboard.disposition.copy',
        image: 'about-02-reliable-safe.png',
        link: 'disposition',
      },
      {
        headline: 'dashboard.operatives.header',
        copy: 'dashboard.operatives.copy',
        image: 'home-03-drop-off.png',
        link: 'operatives',
      },
      {
        headline: 'dashboard.documentation.header',
        copy: 'dashboard.documentation.copy',
        image: 'home-05-monitoring.png',
        link: 'documentation',
      },
    ];
  }
}

class KundenPortal implements Portal {
  public name() {
    return 'Kundenportal';
  }

  public mainNavigation(): NavLink[] {
    return [
      { name: 'header.mainNavi.home', url: '/' },
      { name: 'header.mainNavi.serviceLocations', url: 'serviceLocations' },
      { name: 'header.mainNavi.requests', url: 'requests' },
      { name: 'header.mainNavi.contracts', url: 'contracts' },
      { name: 'header.mainNavi.orders', url: 'orders' },
      { name: 'header.mainNavi.billing', url: 'receipts' },
    ];
  }

  public metaNavigation(): NavLink[] {
    return defaultMetaNav;
  }

  public dashboardPanes(): DashboardPane[] {
    return [
      {
        headline: 'dashboard.serviceLocations.header',
        copy: 'dashboard.serviceLocations.copy',
        image: 'home-03-drop-off.png',
        link: 'serviceLocations',
      },
      {
        headline: 'dashboard.contracts.header',
        copy: 'dashboard.contracts.copy',
        image: 'partner-01-easily-manage.png',
        link: 'orders',
      },
      {
        headline: 'dashboard.newServiceLocation.header',
        copy: 'dashboard.newServiceLocation.copy',
        image: 'about-02-reliable-safe.png',
        action: 'new-service-location',
      },
      {
        headline: 'global.createContract',
        copy: 'dashboard.newContract.copy',
        image: 'home-05-monitoring.png',
        action: 'new-contract',
      },
    ];
  }
}

@Module({
  name: 'Portal',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class PortalStoreModule extends VuexModule {
  name: string = '';
  mainNavigation: NavLink[] = [];
  metaNavigation: NavLink[] = [];
  dashboardPanes: DashboardPane[] = [];
  permissions: any = {};
  defaultPermissions: any = {};

  @Mutation
  initializeRealm(realm: KeycloakRealm) {
    const portal = realm === KeycloakRealm.PARTNER ? new PartnerPortal() : new KundenPortal();
    this.name = portal.name();
    this.mainNavigation = portal.mainNavigation();
    this.metaNavigation = portal.metaNavigation();
    this.dashboardPanes = portal.dashboardPanes();
  }

  @Mutation
  initialize(type: string) {
    const portal = type === 'partnerportal' ? new PartnerPortal() : new KundenPortal();
    this.name = portal.name();
    this.mainNavigation = portal.mainNavigation();
    this.metaNavigation = portal.metaNavigation();
    this.dashboardPanes = portal.dashboardPanes();
  }

  @Mutation
  setPermissions(permissions: any) {
    this.permissions = permissions;
  }

  @Mutation
  setDefaultPermissions(defaultPermissions: any) {
    this.defaultPermissions = defaultPermissions;
  }

  get getDefaultPermissions(): AccountPermission {
    return this.defaultPermissions;
  }

  get getMasterPermissions(): AccountPermission {
    return this.isCustomer
      ? { serviceLocations: Permission.WRITE, invoicing: Permission.READ, administration: Permission.WRITE }
      : { distribution: Permission.WRITE, disposition: Permission.WRITE, invoicing: Permission.READ, administration: Permission.WRITE };
  }

  get portalName(): string {
    return this.name;
  }

  get isPartner(): boolean {
    return /partner/i.test(this.name);
  }

  get isCustomer(): boolean {
    return /kunde/i.test(this.name);
  }

  get filteredMainNavigationLinks(): NavLink[] {
    return this.mainNavigation
      .filter((item) => {
        if (this.isCustomer) {
          const customerPermissions = this.permissions as CustomerAccountPermission;
          if (
            (!item.url.match(/\/|receipts/i) && customerPermissions.serviceLocations === Permission.NONE) ||
            (item.url.match(/invoices|receipts/i) && customerPermissions.invoicing === Permission.NONE)
          ) {
            return false;
          }
        } else {
          const partnerPermissions = this.permissions as PartnerAccountPermission;
          if (
            (!!item.url.match(/(offers)/i) && partnerPermissions.distribution === Permission.NONE) ||
            (!!item.url.match(/(disposition)|(operatives)/i) && partnerPermissions.disposition === Permission.NONE) ||
            (!!item.url.match(/(documentation)|(invoices)/i) && partnerPermissions.invoicing === Permission.NONE)
          ) {
            return false;
          }
        }
        return true;
      })
      .slice();
  }

  get metaNavigationLinks(): NavLink[] {
    return this.metaNavigation;
  }

  get dashboardPanesList(): DashboardPane[] {
    return this.dashboardPanes.filter((pane) => {
      if (this.isCustomer) {
        const customerPermissions = this.permissions as CustomerAccountPermission;
        if (
          (pane.headline.match(/servicelocations|contract/i) && customerPermissions.serviceLocations === Permission.NONE) ||
          (pane.headline.match(/newservicelocation|createcontract/i) && customerPermissions.serviceLocations !== Permission.WRITE)
        ) {
          return false;
        }
      } else {
        const partnerPermissions = this.permissions as PartnerAccountPermission;
        if (
          (pane.headline.match(/offers/i) && partnerPermissions.distribution === Permission.NONE) ||
          (pane.headline.match(/disposition|operatives/i) && partnerPermissions.disposition === Permission.NONE) ||
          (pane.headline.match(/documentation|invoices/i) && partnerPermissions.invoicing === Permission.NONE)
        ) {
          return false;
        }
      }
      return true;
    });
  }
}
