
  import { Vue, Prop, Component } from 'nuxt-property-decorator';

  @Component({})
  export default class TextBoxFloat extends Vue {
    @Prop()
    title?: string;

    @Prop()
    content?: [];

    @Prop({ default: false })
    valueBold?: boolean;

    @Prop()
    hasBackground!: 'blue' | 'white';

    @Prop({ default: true })
    labelHasColon?: boolean;
  }
