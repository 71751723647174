
  import { Component, Vue } from 'nuxt-property-decorator';
  import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
  import { LocaleObject } from 'nuxt-i18n/types';
  import SiteButton from './shared/SiteButton.vue';
  import { XLanguageService } from '~/types/content';
  import { accountStore, dispositionStore, offerStore } from '~/store';

  @Component({
    components: { SiteButton },
  })
  export default class LanguageSwitch extends Vue {
    currentLocale = '';
    langService = new XLanguageService();

    mounted() {
      this.currentLocale = this.$i18n.locale;
      this.setStoresLanguage(this.currentLocale);
    }

    activated() {
      this.currentLocale = this.$i18n.locale;
      this.setStoresLanguage(this.currentLocale);
    }

    get path() {
      return this.$route.path;
    }

    get getLocales() {
      return this.$i18n.locales;
    }

    activateButton(language: any) {
      this.$i18n.setLocale(language);
      this.setStoresLanguage(this.currentLocale);
      this.refreshAccount(language);
    }

    async refreshAccount(language: any) {
      await accountStore.fetchAccount(this.getLangString(language));
    }

    getLangString(language: string) {
      return this.langService.getLanguageString(language);
    }

    setStoresLanguage(language: string) {
      const langString = this.getLangString(language);
      offerStore.setSelectedLanguage(langString);
      dispositionStore.setSelectedLanguage(langString);
    }

    getLocaleName(localeCode: LOCALE_CODE) {
      const localeObject = (this.getLocales as LocaleObject[]).find((locale) => locale.code === localeCode);
      return localeObject ? localeObject.name : 'n/a';
    }
  }
