
  // External Dependencies
  import dayjs from 'dayjs';

  import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import { DeliveryTimeRange } from '@redooo/shared/dist/types/order/deliveryTimeRange';
  import { accountStore } from '../store';
  import toast from '../mixins/toast';
  import setMinMaxPossibleDate from '../mixins/setMinMaxPossibleDate';
  import DatePicker from './common/DatePicker.vue';
  import TimeRangeSelect from './common/TimeRangeSelect.vue';
  import FormField from './shared/FormField.vue';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';
  import AutocompleteCheckbox from './AutocompleteCheckbox.vue';
  import storeSubscriber from '~/mixins/storeSubscriber';
  import { XLanguageService } from '~/types/content';
  import AccessHelper from '~/utils/accessHelper';

  @Component({
    components: {
      SiteButton,
      FormField,
      IconBase,
      DatePicker,
      AutocompleteCheckbox,
      ValidationObserver,
      TimeRangeSelect,
    },
  })
  export default class BookInteractionForm extends mixins(toast, storeSubscriber, setMinMaxPossibleDate) {
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
    };

    @Prop()
    bookInteractionType!: string;

    @Prop()
    bookInteractionRowContent!: Record<string, string | number | undefined>;

    formData: any = {
      contractType: '',
      serviceLocation: '',
      wasteType: '',
      containerType: '',
      containerQuantity: 0,
      containerQuantityActual: 0,
      containerQuantityArrange: 1,
      date: dayjs.Dayjs,
      timeRange: '',
      assignmentInterval: '',
      orderNotes: '',
      customOrderId: '',
    };

    isLoading: boolean = false;
    useCustomOrderId: boolean = false;

    mounted() {
      this.fillData();
      this.handleEvents();

      const accessToken: string | undefined = this.$keycloak.token;
      if (accessToken) {
        AccessHelper.useOrderId(accessToken)
          .then((result) => {
            this.useCustomOrderId = result;
          })
          .catch((error) => {
            console.error('Error fetching "use_custom_order_id":', error);
          });
      }
    }

    fillData() {
      const bookInteractionRowContent = this.bookInteractionRowContent as any;
      this.formData.assignmentInterval = bookInteractionRowContent.customerIntervalExternalQualifier;
      this.formData.containerQuantity = bookInteractionRowContent.containerQuantity;
      this.formData.containerQuantityActual = bookInteractionRowContent.containerQuantityActual;
      this.formData.containerType = bookInteractionRowContent.containerType;
      this.formData.contractType = bookInteractionRowContent.contractType;
      this.formData.date = this.nextPossibleDate;
      this.formData.timeRange = DeliveryTimeRange.GANZTAGS;
      this.formData.serviceLocation = bookInteractionRowContent.serviceLocationName;
      this.formData.wasteType = bookInteractionRowContent.wasteType;
    }

    handleEvents() {
      this.subscribeEvents((mutation) => {
        if (mutation.type === 'CustomerContracts/interactionContractSuccess') {
          this.showSuccess(this.$t('global.toasts.' + this.bookInteractionType) as string);
          this.closeModal();
        }
        if (mutation.type === 'CustomerContracts/interactionContractError') {
          this.showError(this.$t('global.toasts.' + this.bookInteractionType + 'Failed') as string);
          this.isLoading = false;
        }
      });
    }

    get timeRangeOptions() {
      return Object.values(DeliveryTimeRange);
    }

    get nextPossibleDate() {
      const currentDay = dayjs().startOf('day');
      const deliveryDate = dayjs(this.bookInteractionRowContent.deliveryDate).startOf('day');

      // deliveryDate is in the past -> return currentDay + 1 day
      if (deliveryDate.isBefore(currentDay)) return this.setMinMaxPossibleDate(currentDay, 1);

      // deliveryDate is in the future -> return deliveryDate + 1 day
      return this.setMinMaxPossibleDate(deliveryDate, 1);
    }

    get getFormTitle() {
      return this.$t(`bookInteractionForm.${this.bookInteractionType}Headline`);
    }

    get getSubHeadline() {
      return this.$t(`bookInteractionForm.${this.bookInteractionType}SubHeadline`);
    }

    get getDatePlaceholder() {
      return this.$t(`global.forms.placeholders.generalDate`);
    }

    get getDateLabel() {
      return this.$t(`global.labels.${this.bookInteractionType}Date`);
    }

    get assignmentIntervalOptions() {
      return accountStore.intervalsList;
    }

    handleSubmit() {
      const langService = new XLanguageService();
      this.isLoading = true;
      setTimeout(() => {
        const bookInteraction = this.bookInteractionRowContent as any;
        this.$store.dispatch('CustomerContracts/bookInteraction', {
          date: dayjs(this.formData.date).add(2, 'hours').toISOString(),
          externalQualifier: bookInteraction.externalQualifier,
          quantity: this.formData.containerQuantityArrange,
          timeRange: this.formData.timeRange,
          type: this.bookInteractionType,
          wasteType: this.formData.wasteType,
          orderNotes: this.formData.orderNotes,
          orderId: this.formData.customOrderId,
          xlanguage: langService.getLanguageString(this.$i18n.locale),
        });
      }, 1000);
    }

    resetForm() {
      const wrapperElem = document.getElementById('book-interaction-form');
      if (wrapperElem !== null) {
        wrapperElem.scrollIntoView();
      }
      this.isLoading = false;
      this.$refs.validationObserver?.reset();
    }

    closeModal() {
      this.resetForm();
      (this.$parent as any).close();
    }

    @Watch('formData.containerQuantityArrange')
    onContainerQuantityChange(quantity: number) {
      if (quantity <= 0) this.formData.containerQuantityArrange = 1;
      else if (quantity > this.formData.containerQuantity) this.formData.containerQuantityArrange = this.formData.containerQuantity;
    }
  }
