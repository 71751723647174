
  // External Dependencies
  import dayjs from 'dayjs';
  import { Prop, Component, getModule, Watch, mixins } from 'nuxt-property-decorator';
  import { PickUpTimeRange } from '@redooo/shared/dist/types/order/pickUpTimeRange';
  import { DeliveryTimeRange } from '@redooo/shared/dist/types/order/deliveryTimeRange';

  // Internal Depenencies
  import { deliveryStore } from '../utils/store-accessor';
  import DeliveryStoreModule from '../store/Delivery';
  import { OfferType } from '../types/offerType';

  // Mixins
  import setMinMaxPossibleDate from '../mixins/setMinMaxPossibleDate';

  // Components
  import DatePicker from './common/DatePicker.vue';
  import TimeRangeSelect from './common/TimeRangeSelect.vue';

  type DeliveryPickupFormData = {
    deliveryDate: dayjs.Dayjs | undefined;
    deliveryTimeRange: string | null;
    pickupDate: dayjs.Dayjs | undefined;
    pickupTimeRange: string | null;
  };

  @Component({ components: { DatePicker, TimeRangeSelect } })
  export default class DeliveryPickupSelect extends mixins(setMinMaxPossibleDate) {
    $refs!: {
      deliveryDate: any;
      deliveryTimeRange: any;
      pickupDate: any;
      pickupTimeRange: any;
    };

    @Prop({ default: true })
    showDelivery?: boolean;

    @Prop({ default: true })
    showPickup?: boolean;

    @Prop({ default: false })
    isDirectLoading?: boolean;

    @Prop()
    countryState!: string;

    @Prop({ default: false })
    disabled?: boolean;

    @Prop({ default: false })
    isSecondDateDisabled?: boolean;

    @Prop({ default: null })
    initDates?: DeliveryPickupFormData;

    @Prop()
    firstDateLabel?: string;

    @Prop()
    secondDateLabel?: string;

    @Prop({ default: true })
    isSecondDatePreselected?: boolean;

    @Prop({ default: false })
    isSecondDateRequired?: boolean;

    @Prop({ default: false })
    isFirstTimeRangeRequired?: boolean;

    @Prop({ default: false })
    isSecondTimeRangeRequired?: boolean;

    @Prop({ default: true })
    enableWatcher?: boolean;

    @Prop({ default: false })
    isAlternativeDate?: boolean;

    @Prop({ default: () => [] })
    disabledWeekDays!: number[];

    @Prop({ default: false })
    resetSecondDate?: boolean;

    @Prop({ default: false })
    isBigBagOffer?: boolean;

    @Prop()
    offerType!: string;

    OfferType = OfferType;

    formData: DeliveryPickupFormData = {
      deliveryDate: undefined,
      deliveryTimeRange: null,
      pickupDate: undefined,
      pickupTimeRange: null,
    };

    deliveryDateMonth: number = 0;
    pickupDateMonth: number = 0;

    subscription: any;
    disabledWeekDaysForPickup: number[] = [];

    mounted() {
      const deliveryStore = getModule(DeliveryStoreModule, this.$store);
      deliveryStore.fetchServerTime(this.$axios);

      if (this.initDates) {
        if (this.initDates.deliveryDate) {
          this.formData.deliveryDate = dayjs(this.initDates.deliveryDate).startOf('day');
          this.formData.deliveryTimeRange = this.initDates.deliveryTimeRange;
        }
        if (this.initDates.pickupDate) {
          this.formData.pickupDate = dayjs(this.initDates.pickupDate).startOf('day');
          this.formData.pickupTimeRange = this.initDates.pickupTimeRange;
        }
        if (this.isAlternativeDate) {
          this.formData.deliveryDate = this.getInitDeliveryDate;
          if (this.initDates.pickupDate) {
            this.formData.pickupDate = this.getInitPickupDate;
          }
        }
      } else {
        this.subscription = this.$store.subscribe((mutation) => {
          if (mutation.type === 'Delivery/updateDates') {
            this.formData.deliveryDate = mutation.payload.deliveryDate;
          }
        });
      }
    }

    get getMinDateDeliveryDate() {
      let tmpMinDate: dayjs.Dayjs | undefined;
      if (this.offerType === OfferType.ONDEMAND) {
        // init deliveryDate - 3 Days
        tmpMinDate = this.setMinMaxPossibleDate(dayjs(this.initDates?.deliveryDate), 3, true);
      } else {
        tmpMinDate = this.isAlternativeDate ? this.getInitDeliveryDate : this.getNextPossibleDelivery;
      }
      const calculateDeliveryDateisBeforeCurrentDate = dayjs(tmpMinDate).startOf('day').isBefore(dayjs().startOf('day'));
      if (calculateDeliveryDateisBeforeCurrentDate) {
        return dayjs().startOf('day').add(1, 'day');
      } else {
        return tmpMinDate;
      }
    }

    get getMaxDateDeliveryDate() {
      if (this.offerType === OfferType.ONDEMAND) {
        // init deliveryDate + 7 Days
        return this.setMinMaxPossibleDate(dayjs(this.initDates?.deliveryDate), 7);
      } else {
        return this.isAlternativeDate ? this.getMaxPossibleAlternativeDeliveryDate : false;
      }
    }

    onDeliveryDateChange() {
      if (!this.formData.deliveryTimeRange) {
        this.formData.deliveryTimeRange = DeliveryTimeRange.GANZTAGS;
      }
    }

    onDeliveryDateMonthChange(value: number) {
      this.setDeliveryDateMonth(value);
      if (dayjs(this.formData.deliveryDate).month() === value) this.setPickupDateMonth(value);
    }

    onPickupDateMonthChange(value: number) {
      if (dayjs(this.formData.pickupDate).month() === value) this.setPickupDateMonth(value);
    }

    onPickupDateChange() {
      if (!this.formData.pickupTimeRange) {
        this.formData.pickupTimeRange = PickUpTimeRange.GANZTAGS;
      }
    }

    onPickupDateReset() {
      this.$refs.pickupTimeRange?.resetPickUpFields();
    }

    onResetPickUpFields() {
      this.$refs.deliveryDate?.resetPickUpFields();
      this.$refs.deliveryTimeRange?.resetPickUpFields();
      this.$refs.pickupDate?.resetPickUpFields();
      this.$refs.pickupTimeRange?.resetPickUpFields();
    }

    get getDeliveryTimeRangeOptions() {
      return Object.values(DeliveryTimeRange);
    }

    get getPickupTimeRangeOptions() {
      return Object.values(PickUpTimeRange);
    }

    get getNextPossibleDelivery() {
      return deliveryStore.nextPossibleDelivery;
    }

    get getNextPossiblePickupDate() {
      if (this.preselectedDeliveryDate && (this.preselectedDeliveryDate as dayjs.Dayjs).isAfter(deliveryStore.nextPossiblePickup)) {
        return this.setMinMaxPossibleDate(this.preselectedDeliveryDate as dayjs.Dayjs, 0);
      }
      return deliveryStore.nextPossiblePickup;
    }

    get getInitDeliveryDate() {
      if (this.showPickup) {
        return dayjs(this.initDates?.deliveryDate).startOf('day');
      }
      return this.setMinMaxPossibleDate(dayjs(this.initDates?.deliveryDate), 1);
    }

    get getInitPickupDate() {
      const diffInitDatesDays = dayjs(this.initDates?.deliveryDate).startOf('day').diff(dayjs(this.initDates?.pickupDate).startOf('day'), 'days');
      const deliveryDateIsBeforePickupDate = dayjs(this.formData.deliveryDate)
        .startOf('day')
        .isBefore(dayjs(this.initDates?.pickupDate).startOf('day'));
      if (deliveryDateIsBeforePickupDate || this.isBigBagOffer) {
        return dayjs(this.initDates?.pickupDate).startOf('day');
      } else {
        return this.setMinMaxPossibleDate(dayjs(this.formData.deliveryDate).startOf('day'), Math.abs(diffInitDatesDays));
      }
    }

    get getMaxPossibleAlternativeDeliveryDate() {
      if (this.showPickup) {
        return this.setMinMaxPossibleDate(this.getInitDeliveryDate, 2);
      }
      return this.setMinMaxPossibleDate(this.getInitDeliveryDate, 1);
    }

    get getMaxPossiblePickupDate() {
      return this.setMinMaxPossibleDate(this.getInitPickupDate);
    }

    get preselectedDeliveryDate() {
      if (this.initDates) {
        if (this.initDates.deliveryDate) {
          if (this.isAlternativeDate) {
            return this.getInitDeliveryDate;
          }
          return dayjs(this.initDates.deliveryDate);
        }
      } else {
        return this.getNextPossibleDelivery;
      }
    }

    get preselectedPickupDate() {
      if (this.initDates) {
        if (this.initDates.pickupDate) {
          return dayjs(this.initDates.pickupDate);
        }
        if (this.initDates.pickupDate === undefined) {
          return this.initDates.pickupDate;
        }
      } else {
        return this.isSecondDatePreselected ? this.getNextPossiblePickupDate : null;
      }
    }

    get getFirstDateLabel() {
      if (this.isDirectLoading) {
        return this.$t('global.labels.chargeDate');
      }
      return this.firstDateLabel ? this.firstDateLabel : this.$t('global.labels.deliveryDate');
    }

    get getSecondDateLabel() {
      if (this.secondDateLabel) return this.secondDateLabel;
      return this.$t('global.labels.pickupDate');
    }

    setDeliveryDateMonth(month: number) {
      this.deliveryDateMonth = month;
    }

    setPickupDateMonth(month: number, hasInitalValue: boolean = false) {
      // Sync inital deliverydate-month and pickupdate-month
      this.pickupDateMonth = month;
      if (this.$refs.pickupDate && !hasInitalValue && !this.isBigBagOffer) {
        this.$refs.pickupDate.$refs.datepicker.focusedDateData.month = this.$refs.deliveryDate.$refs.datepicker.focusedDateData.month;
      }
    }

    @Watch('resetSecondDate')
    onResetSecondDateChange(_newVal: boolean, _oldVal: boolean) {
      this.$refs.pickupDate?.resetPickUpFields();
      this.$refs.pickupTimeRange?.resetPickUpFields();
    }

    @Watch('disabledWeekDays')
    watchDisabledWeekDays(weekDays: number[]) {
      this.disabledWeekDaysForPickup = weekDays;
    }

    @Watch('formData', { deep: true })
    formDataChanged(formData: DeliveryPickupFormData) {
      this.$emit('update:data', formData);
    }

    @Watch('formData.deliveryDate')
    onChangeDeliveryDate(newDeliveryDate: dayjs.Dayjs, oldDeliveryDate: dayjs.Dayjs) {
      if (!this.enableWatcher) return;
      if (!this.formData.pickupDate) this.setPickupDateMonth(dayjs(newDeliveryDate).month());
      if (!oldDeliveryDate && newDeliveryDate && !this.initDates?.deliveryTimeRange) this.formData.deliveryTimeRange = DeliveryTimeRange.GANZTAGS;
      if (oldDeliveryDate && newDeliveryDate) {
        deliveryStore.updateDeliveryDate(dayjs(this.formData.deliveryDate).toISOString());
      }
      if (dayjs(newDeliveryDate).diff(dayjs(this.formData.pickupDate), 'day') >= 0 && !this.isBigBagOffer) {
        this.$refs.pickupDate?.resetPickUpFields();
      }
    }

    @Watch('formData.pickupDate')
    onChangePickupDate(newPickupDate: dayjs.Dayjs, oldPickupDate: dayjs.Dayjs) {
      console.log('onChangePickupDate - newPickupDate:', newPickupDate);
      if (!this.enableWatcher) return;
      this.setPickupDateMonth(dayjs(newPickupDate).month(), newPickupDate && !oldPickupDate);
      if (!oldPickupDate && newPickupDate && !this.formData.pickupTimeRange) this.formData.pickupTimeRange = PickUpTimeRange.GANZTAGS;
      if (!newPickupDate && oldPickupDate) this.setPickupDateMonth(dayjs(this.formData.deliveryDate).month());
      if (this.$refs.pickupTimeRange) {
        const element = this.$refs.pickupTimeRange.$children[0].$children[0];
        if (element.flags) {
          element.flags.validated = true;
        }
      }
    }

    @Watch('formData.pickupTimeRange')
    onChangePickupTimeRange(newPickupTimeRange: String, oldPickupTimeRange: String) {
      if (!this.enableWatcher) return;

      if (oldPickupTimeRange && !newPickupTimeRange && !this.initDates) {
        this.formData.pickupDate = undefined;
        this.$refs.pickupDate?.resetPickUpFields();
      }
    }

    destroyed() {
      if (this.subscription) this.subscription();
    }
  }
