import { Module, Action, Mutation } from 'vuex-module-decorators';
import { PartialDeep } from 'type-fest';
import { plainToClass } from 'class-transformer';
import logger from '@redooo/shared/dist/services/logging';
import { CustomerIntervals, emptyPositiveList, GroupedbyTypePositivelist, KeycloakRealm } from '../types/account';
import { ApiModule } from './ApiModule';
import { getAccountType } from './accountFactory';
import { Account, Employee, AccountPermission, Tutorial, TutorialEnum, TutorialValue } from './entities/account';
import { serviceLocationStore } from './index';
import { isValueTrueOfKeyValuePair } from '~/utils/helpers';

type KeycloakUser = {
  id?: string;
  email?: string;
  username: string;
  roles?: string[];
  firstName?: string;
  lastName?: string;
  enabled?: boolean;
  phoneNumber?: string;
};

type CreateAccountRequest = {
  accountIdentifier: Record<string, string>;
  email: string;
  firstname: string;
  lastname: string;
  phoneNumber: string;
  permissions: AccountPermission;
  serviceLocations: string[];
  companyExternalQualifier: string | undefined;
  realm: string;
  tutorials: Tutorial;
};
type UpdateAccountRequest = CreateAccountRequest;

@Module({
  name: 'Account',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class AccountStoreModule extends ApiModule {
  keycloakRealm!: KeycloakRealm;
  user?: KeycloakUser = undefined;
  account!: Account;
  accountLoginKey: string = '';
  customerNumber: string = '';

  userIsAuthenticated = false;
  sessionExpired = false;
  accountError?: Error;

  employees: Account[] = [];
  updatingEmployee?: PartialDeep<Employee> = undefined;

  positiveList: GroupedbyTypePositivelist = emptyPositiveList;
  customerIntervals: CustomerIntervals = [];

  keycloakAccountRoles: string[] = [];
  tutorials: Tutorial = {};
  isTutorialActive: boolean = false;

  get serviceUrl(): string {
    return '/portal/account';
  }

  get currentCustomerNumber() {
    return this.customerNumber;
  }

  @Action
  async fetchAccount(xlanguage: string) {
    try {
      const store = (this as any).store;
      const realm = store.$keycloak.realm;
      const userProfile = await store.$keycloak.loadUserProfile();
      const keycloakAccountResponse = await this.api.get(`/portal/account/${realm}/${userProfile.username}`, { params: { xlanguage } });
      const responseData = keycloakAccountResponse.data.data as any;

      // Set TutorialAttributes
      const hasAccountTutorialAttributes = Object.keys(responseData.tutorials).length > 0;
      if (!hasAccountTutorialAttributes) {
        // set default tutorial attributes
        if (realm === KeycloakRealm.CUSTOMER) {
          responseData.tutorials = {
            showTutorialDashboard: TutorialValue.TRUE,
            showTutorialServiceLocation: TutorialValue.FALSE,
            showTutorialInvoicing: TutorialValue.FALSE,
            showTutorialAdministration: TutorialValue.FALSE,
          };
        } else if (realm === KeycloakRealm.PARTNER) {
          // TODO: add disposer tutorial attributes
        }
      }

      // Set account
      const account = getAccountType(responseData);

      const company = account.accountIdentifier.companyExternalQualifier;
      if (!company.match(/^GP[0-9]+/)) {
        this.context.commit('accountFailed', '503 invalid companyExternalQualifier');
        return;
      }

      this.context.commit('accountSuccess', account);
      this.context.commit('ServiceLocation/initAccount', account, { root: true });
      this.context.commit(
        'Portal/setPermissions',
        realm === KeycloakRealm.CUSTOMER ? account.mappedCustomerPermissions : account.mappedPartnerPermissions,
        { root: true }
      );
      this.context.commit(
        'Portal/setDefaultPermissions',
        realm === KeycloakRealm.CUSTOMER ? account.mappedCustomerPermissions : account.mappedPartnerPermissions,
        { root: true }
      );
      this.context.commit('Disposition/setCustomerNumber', company, { root: true });
      this.context.commit('Offer/setCustomerNumber', company, { root: true });
      this.context.commit('CustomerContracts/setCustomerNumber', company, { root: true });
      this.context.commit('PartnerContracts/setCustomerNumber', company, { root: true });
      this.context.commit('PartnerOrders/setCustomerNumber', company, { root: true });
      this.context.commit('CustomerInvoice/setCustomerNumber', company, { root: true });
      this.context.commit('PartnerInvoice/setCustomerNumber', company, { root: true });
    } catch (e) {
      this.context.commit('accountFailed', e as string);
    }
  }

  @Action
  async fetchEmployees() {
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(`/portal/account/employees/${this.realm}/${this.customerId}`);
      this.context.commit('employeesSuccess', response.data.data);
    } catch (e) {
      this.context.commit('employeesFailed', e as string);
    }
  }

  @Mutation
  accountSuccess(account: Account) {
    this.records = account.serviceLocations;
    this.positiveList = account.positiveList;
    this.customerIntervals = account.intervals;
    this.customerNumber = account.accountIdentifier.companyExternalQualifier;

    this.employees = account.employees.map((employee) => plainToClass(Account, employee));
    this.tableLoading = false;
    this.keycloakAccountRoles = account.roles;
    this.tutorials = account.tutorials;
    this.account = account;
    this.userIsAuthenticated = true;
  }

  @Mutation
  employeesSuccess(employees: Account[]) {
    this.tableLoading = false;
    this.employees = employees.map((employee) => plainToClass(Account, employee));
  }

  @Mutation
  employeesFailed(_error: string) {
    this.tableLoading = false;
  }

  @Mutation
  @Mutation
  resetCurrentEmployeeRecord() {
    this.status = { type: '' };
    this.updatingEmployee = undefined;
  }

  @Mutation
  accountFailed(error: Error) {
    this.accountError = error;
  }

  get accountFailedWith503() {
    return this.accountErrorOccured?.message.includes('503');
  }

  @Action
  async createAccount(formData: CreateAccountRequest) {
    try {
      const response = await this.api.post(`/portal/accounts/${this.realm}`, {
        ...formData,
        companyExternalQualifier: this.customerId,
        // roles are defined in the api via mapPermissionsToRoles - makeCreateAccount
      });
      const added = response.data.data as Account;
      this.context.commit('accountCreatedSuccess', { ...added, allowedServiceLocations: (formData as any).serviceLocations });
    } catch (e) {
      this.context.commit('accountCreatedError', e as string);
    }
  }

  @Action({ rawError: true })
  async updateAccount(params: { formData: UpdateAccountRequest; isEmployee: boolean; isTutorialActive: boolean }) {
    const { formData, isEmployee, isTutorialActive } = params;
    const state = this.context.state as any;
    let formDataCurrentLoggedInAccount = {};

    if (!formData) {
      formDataCurrentLoggedInAccount = {
        accountIdentifier: state.account?.accountIdentifier,
        email: state.user?.email,
        firstname: state.user?.firstName,
        lastname: state.user?.lastName,
        phoneNumber: state.user?.phoneNumber,
        permissions: this.realm === KeycloakRealm.CUSTOMER ? state.account?.mappedCustomerPermissions : state.account?.mappedPartnerPermissions,
        serviceLocations: serviceLocationStore.allowedServiceLocations,
        tutorials: state.tutorials,
        // roles are defined in the api via mapPermissionsToRoles - makeUpdateAccount
      };
    }

    const currentFormData = formData || formDataCurrentLoggedInAccount;
    const currentEmployeeId = this.getUpdatingEmployee?.id || state.account?.id;

    try {
      const response = await this.api.put(`/portal/accounts/${this.realm}/${currentEmployeeId}`, {
        ...currentFormData,
        companyExternalQualifier: this.customerId,
      });
      const updated: Account = response.data.data as Account;
      const account = { ...updated, allowedServiceLocations: (currentFormData as any).serviceLocation };

      // Trigger success-toast-messages
      if (isEmployee) this.context.commit('accountUpdatedSuccess', { account, isEmployee, isTutorialActive });
      else this.context.commit('accountUpdatedSuccess', { account, isEmployee, isTutorialActive });
    } catch (e) {
      // Trigger error-toast-messages
      if (isEmployee) this.context.commit('accountUpdatedError', e as string);
      else this.context.commit('accountUpdatedError', e as string);
    }
  }

  @Mutation
  accountCreatedSuccess(_account: Account) {
    this.status = { type: 'success' };
    // this.employees.push(account);
  }

  @Mutation
  accountUpdatedSuccess(params: { account: Account; isEmployee: boolean }) {
    const { account } = params;
    this.employees = this.employees.filter((employee: Account) => employee.id !== account.id);
    // this.employees.push(account);
  }

  @Mutation
  accountCreatedError(error: string) {
    console.log(error);
  }

  @Mutation
  accountUpdatedError(error: string) {
    console.log(error);
  }

  @Action
  async deleteAccount(accountId: string) {
    try {
      await this.api.delete(`/portal/accounts/${this.currentCustomerNumber}/${this.realm}/employees/${accountId}`);
      this.context.commit('accountDeleteSuccess', accountId);
    } catch (e) {
      this.context.commit('accountDeleteError', e as string);
    }
  }

  @Action
  async logout() {
    try {
      await this.api.post(`/portal/account/${this.currentCustomerNumber}/${this.realm}/${this.accountId}/logout`);
      this.context.commit('accountLogoutSuccess');
    } catch (e) {
      this.context.commit('accountLogoutError', e as string);
    }
  }

  get accountId() {
    return this.account?.id;
  }

  @Mutation
  accountLogoutSuccess() {}

  @Mutation
  accountLogoutError() {}

  @Mutation
  accountDeleteSuccess(accountId: string) {
    this.employees = this.employees.filter((account) => account.id !== accountId);
  }

  @Mutation
  accountDeleteError(error: string) {
    console.log(error);
  }

  @Mutation
  setLoginKey(loginKey: string) {
    this.accountLoginKey = loginKey;
  }

  @Mutation
  setSessionExpired(sessionExpired: boolean) {
    this.sessionExpired = sessionExpired;
  }

  @Mutation
  setCurrentRealm(realm: KeycloakRealm) {
    logger.info('Accountstore - REALM', realm);
    this.keycloakRealm = realm;
  }

  @Mutation
  public setUserInfo(userInfo: KeycloakUser) {
    this.user = userInfo;
  }

  get tableRecords() {
    return this.employees
      .filter((employee: Account) => this.account?.id !== employee.id)
      .map((employee: Account) => {
        return {
          isAdmin: employee.isAdmin,
          id: employee.id,
          name: employee.firstname + ' ' + employee.lastname,
          email: employee.email,
          phoneNumber: employee.phoneNumber,
          assignedServiceLocations: (employee.allowedServiceLocations ?? []).length,
          ...(employee.permissions ?? this.realm === KeycloakRealm.CUSTOMER ? employee.mappedCustomerPermissions : employee.mappedPartnerPermissions),
        };
      });
  }

  @Mutation
  setCurrentEmployeeById(accountId: string) {
    if (accountId === this.account?.id) {
      this.updatingEmployee = {
        ...this.account,
        permissions: this.keycloakRealm === KeycloakRealm.CUSTOMER ? this.account?.mappedCustomerPermissions : this.account?.mappedPartnerPermissions,
      };
    } else {
      const employee = this.employees.find((employee) => employee.id === accountId);
      this.updatingEmployee = {
        ...employee,
        permissions: this.keycloakRealm === KeycloakRealm.CUSTOMER ? employee?.mappedCustomerPermissions : employee?.mappedPartnerPermissions,
      };
    }
    this.status = { type: '' };
  }

  get getIsTutorialDashboard(): boolean {
    return isValueTrueOfKeyValuePair(this.tutorials, TutorialEnum.SHOW_TUTORIAL_DASHBOARD);
  }

  get getIsTutorialServiceLocation(): boolean {
    return isValueTrueOfKeyValuePair(this.tutorials, TutorialEnum.SHOW_TUTORIAL_SERVICELOCATION);
  }

  get getIsTutorialInvoicing(): boolean {
    return isValueTrueOfKeyValuePair(this.tutorials, TutorialEnum.SHOW_TUTORIAL_INVOICING);
  }

  get getIsTutorialAdmininstration(): boolean {
    return isValueTrueOfKeyValuePair(this.tutorials, TutorialEnum.SHOW_TUTORIAL_ADMINISTRATION);
  }

  @Mutation
  setTutorials(newTutorials: Tutorial): void {
    this.tutorials = Object.assign(this.tutorials, newTutorials);
  }

  @Mutation
  setIsTutorialActive(isTutorialActive: boolean): void {
    this.isTutorialActive = isTutorialActive;
  }

  get getIsTutorialActive(): boolean {
    return this.isTutorialActive;
  }

  get getLoggedInAccount(): Account | undefined {
    return this.account;
  }

  get accountErrorOccured() {
    return this.accountError;
  }

  get currentEmployee(): PartialDeep<any | undefined> {
    return this.updatingEmployee;
  }

  get positiveListItems(): GroupedbyTypePositivelist {
    return this.positiveList;
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  get findServiceLocation() {
    return (externalQualifier: string) => this.records.find((serviceLocation) => serviceLocation.externalQualifier === externalQualifier);
  }

  public get userName() {
    return this.user?.username;
  }

  public get realm() {
    return this.keycloakRealm;
  }

  public get getUpdatingEmployee() {
    return this.updatingEmployee;
  }

  public get roles() {
    return this.account?.roles ?? [];
  }

  public get userFullName() {
    return this.user?.firstName + ' ' + this.user?.lastName;
  }

  public get userEmail() {
    return this.user?.email;
  }

  public get userAccount() {
    return this.account;
  }

  public get userPhone() {
    return this.user?.phoneNumber;
  }

  public get companyName() {
    return this.account?.companyName;
  }

  public get companyName2() {
    return this.account?.companyName2;
  }

  public get accountPermissions() {
    return this.keycloakRealm === KeycloakRealm.CUSTOMER ? this.account?.mappedCustomerPermissions : this.account?.mappedPartnerPermissions;
  }

  public get isAdmin() {
    return this.account?.isAdmin;
  }

  public get isAuthenticated() {
    return this.userIsAuthenticated;
  }

  public get customerId() {
    return this.customerNumber;
  }

  public get employeeList() {
    return this.employees
      .map((employee) => {
        return { id: employee.id, name: employee.firstname + ' ' + employee.lastname };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public get intervalsList() {
    return this.customerIntervals.map((interval: { externalQualifier: string; description: string }) => {
      return { id: interval.externalQualifier, name: interval.description };
    });
  }

  get getTutorials(): Tutorial {
    return this.tutorials;
  }

  // TODO: RDO-749
  get getNamesFromAllowedServiceLocations(): string[] {
    const allowedServiceLocationsNames = this.account.serviceLocations.filter((serviceLocation) => {
      return this.account.allowedServiceLocations.includes((serviceLocation as any).externalQualifier as string);
    });
    return allowedServiceLocationsNames.map((a) => (a as any).name).sort();
  }
}
