import { Mutation, Action } from 'vuex-module-decorators';
import { ApiModule } from './ApiModule';

const ENTRIES_PER_PAGE = 60;

function uniqueList(objList: any[], key: string) {
  return [...new Map(objList.map((item) => [item[key], item])).values()];
}

export abstract class OpenClosedApiModule extends ApiModule {
  openRecords: any[] = [];
  closedRecords: any[] = [];
  lastPageOpen = false;
  lastPageClosed = false;
  pageOpen: number = 1;
  pageClosed: number = 1;
  openOrderTypes: string[] = [];
  closedOrderTypes: string[] = [];

  qualifierField = 'externalQualifier';

  @Action
  async fetchNextPage(payload: { params: { open: boolean; filter: Record<string, string>; xlanguage: string; isEn?: boolean } }) {
    const { open, filter, xlanguage, isEn } = payload.params;
    let modifiedXLanguage = xlanguage;
    if (isEn !== undefined) {
      modifiedXLanguage = isEn ? 'en-US' : 'de-DE';
    }
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(this.serviceUrl, {
        params: {
          open: open ? 1 : 0,
          page: open ? this.numPageOpen : this.numPageClosed,
          filter: filter ? JSON.stringify({ ...filter }) : '{}',
          limit: ENTRIES_PER_PAGE,
          xlanguage: modifiedXLanguage,
        },
      });
      this.context.commit(open ? 'openRecordsSuccess' : 'closedRecordsSuccess', response.data);
    } catch (e) {
      this.context.commit('recordsFailed', e as string);
    }
  }

  @Action
  async fetchFilteredRecords(payload: { params: { open: boolean; filter: Record<string, string>; xlanguage: string; isEn?: boolean } }) {
    const { open, filter, xlanguage, isEn } = payload.params;
    let modifiedXLanguage = xlanguage;
    if (isEn !== undefined) {
      modifiedXLanguage = isEn ? 'en-US' : 'de-DE';
    }
    try {
      this.context.commit('loadTable');
      const response = await this.$api.get(this.serviceUrl, {
        params: {
          open: open ? 1 : 0,
          page: open ? this.numPageOpen : this.numPageClosed,
          filter: filter ? JSON.stringify({ ...filter }) : '{}',
          limit: ENTRIES_PER_PAGE,
          xlanguage: modifiedXLanguage,
        },
      });
      this.context.commit(open ? 'setPaginatedOpenRecords' : 'setPaginatedClosedRecords', response.data);
    } catch (e) {
      this.context.commit('recordsFailed', e as string);
    }
  }

  @Mutation
  resetPagination() {
    this.lastPageOpen = false;
    this.lastPageClosed = false;
    this.pageOpen = 1;
    this.pageClosed = 1;
  }

  @Mutation
  openRecordsSuccess(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    if (response.data.length > 0) {
      this.pageOpen++;
      this.lastPageOpen = response.data.length < ENTRIES_PER_PAGE;
      this.openRecords = uniqueList([...this.openRecords, ...response.data], this.qualifierField);
    }
    this.openOrderTypes = response.options.types ?? [];
  }

  @Mutation
  setPaginatedOpenRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.openRecords = response.data;
    this.openOrderTypes = response.options.types ?? [];
  }

  @Mutation
  closedRecordsSuccess(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    if (response.data.length > 0) {
      this.pageClosed++;
      this.lastPageClosed = response.data.length < ENTRIES_PER_PAGE;
      this.closedRecords = uniqueList([...this.closedRecords, ...response.data], this.qualifierField);
    }
    this.closedOrderTypes = response.options.types ?? [];
  }

  @Mutation
  setPaginatedClosedRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.closedRecords = response.data;
    this.closedOrderTypes = response.options.types ?? [];
  }

  @Mutation
  recordsFailed(_error: string) {
    this.tableLoading = false;
  }

  get isLastPageOpen() {
    return this.lastPageOpen;
  }

  get isLastPageClosed() {
    return this.lastPageClosed;
  }

  get numPageOpen() {
    return this.pageOpen;
  }

  get numPageClosed() {
    return this.pageClosed;
  }

  get getOpenRecords() {
    return this.openRecords;
  }

  get getClosedRecords() {
    return this.closedRecords;
  }

  get openInvoiceOrderTypes() {
    return this.openOrderTypes;
  }

  get closedInvoiceOrderTypes() {
    return this.closedOrderTypes;
  }
}
