
  import 'reflect-metadata';
  import { Component, mixins } from 'nuxt-property-decorator';
  import SiteFooter from '../components/shared/SiteFooter.vue';
  import CustomerModals from '../components/CustomerModals.vue';
  import HeaderWrapper from '../components/layout/HeaderWrapper.vue';
  import HeaderFeedback from '../components/layout/HeaderFeedback.vue';
  import ContentWrapper from '../components/layout/ContentWrapper.vue';
  import LanguageSwitch from '../components/LanguageSwitch.vue';
  import { accountStore } from '../store';
  import toast from '../mixins/toast';

  @Component({
    components: { SiteFooter, HeaderWrapper, HeaderFeedback, ContentWrapper, CustomerModals, LanguageSwitch },
  })
  export default class DefaultLayout extends mixins(toast) {
    layoutTransition!: {
      name: 'layout';
      mode: 'out-in';
    };

    externalLinkPaths: Record<string, Record<string, string>> = {
      de: {
        contact: 'kontakt',
        agb: 'agb',
        privacyPolicy: 'datenschutz',
        cancellationRight: 'widerrufsrecht',
        impressum: 'impressum',
      },
      en: {
        contact: 'en/contact',
        agb: 'en/terms-of-use',
        privacyPolicy: 'en/data-protection',
        cancellationRight: 'en/right-of-withdrawal',
        impressum: 'en/imprint',
      },
    };

    get accountErrorOccured() {
      return accountStore.accountErrorOccured;
    }

    get getSessionExpired(): boolean {
      return accountStore.sessionExpired;
    }

    get isFeedbackPage(): boolean {
      return !!this.$route.name?.match(/confirm/);
    }

    get isSiteOnMaintenance(): boolean {
      return !!this.$route.path.match(/(wartungsarbeiten)|(maintenance)/i);
    }

    setExternalLink(type: string): string {
      const prodRedoooDomain = 'https://www.redooo.de/';
      return prodRedoooDomain + this.externalLinkPaths[this.$i18n.locale][type];
    }

    setLinkTitle(type: string) {
      return this.$t(`footerNav.` + type);
    }
  }
