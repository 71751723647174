
  import { Component, Vue } from 'nuxt-property-decorator';
  import { Navigation } from '@redooo/shared/dist/types/content/navigation';
  import MainNavigation from '../shared/MainNavigation.vue';
  import SiteHeader from '../shared/SiteHeader.vue';
  import { makeNavigation } from '../../utils/navigation';
  import { portalStore } from '../../store';

  @Component({
    components: { SiteHeader, MainNavigation },
  })
  export default class HeaderWrapper extends Vue {
    get portalMetaNavigation(): Navigation[] {
      return makeNavigation(
        portalStore.metaNavigationLinks.map((item) => {
          return { ...item, name: this.$t(item.name) as string };
        })
      );
    }

    gotoContactPage() {
      window.location.href = location.origin + '/kontakt';
    }
  }
