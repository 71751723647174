
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { ValidationProvider } from 'vee-validate';

  @Component({
    components: { ValidationProvider },
  })
  export default class FormField extends Vue {
    $refs!: {
      validationProvider: InstanceType<typeof ValidationProvider>;
    };

    @Prop()
    label?: string;

    @Prop({ default: false })
    showLabel?: boolean;

    @Prop()
    rules?: string;

    // set the mode from the validation-provider
    @Prop({ default: 'eager' })
    mode?: string;

    @Prop({ default: false })
    required!: boolean;

    @Prop({ default: 'on-border' })
    labelPosition?: string;

    // disables vee-validate even if required prop is set, useful if a field should still be marked as required but not validated.
    @Prop({ default: false })
    preventValidation!: boolean;

    @Prop({ default: true })
    hasAddons?: boolean;

    get generatedRules() {
      if (this.rules) {
        return this.required ? 'required|' + this.rules : this.rules;
      } else {
        return 'required';
      }
    }
  }
