import { ServiceLocation } from '~/types/contractOffer';

export enum OrderType {
  CONTRACT = 'contract',
  SUBMISSION = 'submission',
  EXCHANGE = 'exchange',
  PICKUP = 'pickup',
}

export enum OrderStatus {
  RELEASE = 'Release',
  ORDER = 'Order',
  COMPLETED_ORDER = 'Completed Order',
}
export class ContractOrder {
  companyExternalQualifier!: string;
  containerDescription!: string;
  contractNo!: string;
  externalQualifier!: string;
  isOrderClosed!: boolean;
  orderNo!: string;
  orderType!: OrderType;
  serviceLocation!: ServiceLocation;
  taskDate!: string;
  timeRange!: string;
  wastetypeDescription!: string;
  documentType!: OrderStatus;
  avalMatched!: boolean;

  public isFinished() {
    return this.isOrderClosed;
  }
}
