
  // External Dependencies
  import dayjs from 'dayjs';

  import { ValidationObserver } from 'vee-validate';
  import { Component, getModule, mixins, Prop, Watch } from 'nuxt-property-decorator';
  import { PickUpTimeRange } from '@redooo/shared/dist/types/order/pickUpTimeRange';
  import { DeliveryTimeRange } from '@redooo/shared/dist/types/order/deliveryTimeRange';
  import { accountStore, contractsStore, serviceLocationStore } from '../store';
  import type { ContractRequestFormData } from '../types/contractRequest';
  import ContractsStoreModule from '../store/CustomerContracts';
  import currentUser from '../mixins/currentUser';
  import toast from '../mixins/toast';
  import storeSubscriber from '../mixins/storeSubscriber';
  import dataStore from '../mixins/dataStore';
  import DatePicker from './common/DatePicker.vue';
  import TimeRangeSelect from './common/TimeRangeSelect.vue';
  import DeliveryPickupSelect from './DeliveryPickupSelect.vue';
  import FormField from './shared/FormField.vue';
  import SiteButton from './shared/SiteButton.vue';
  import IconBase from './shared/IconBase.vue';
  import { DeliveryDateService } from '~/../shared/dist/services';
  import DeliveryStoreModule from '~/store/Delivery';
  import { deliveryStore } from '~/utils/store-accessor';
  import { filterOptions } from '~/utils/helpers';
  import { XLanguage, XLanguageService } from '~/types/content';

  const RENTAL_TOILET: string[] = ['8600010', '8600020'];
  const LOSE_MENGE: string[] = ['9000030'];

  @Component({
    components: {
      ValidationObserver,
      SiteButton,
      FormField,
      IconBase,
      DatePicker,
      TimeRangeSelect,
      DeliveryPickupSelect,
    },
    middleware: ['auth'],
    meta: { auth: { role: 'admin' } }, // router property to pass values to middlewares
  })
  export default class CreateContractForm extends mixins(currentUser, storeSubscriber, toast, dataStore) {
    $refs!: {
      validationObserver: InstanceType<typeof ValidationObserver>;
      deliveryDate: any;
      deliveryTimeRange: any;
      pickupDate: any;
      pickupTimeRange: any;
    };

    MAX_AMOUNT: number = 99;

    formData: ContractRequestFormData = {
      serviceLocation: '',
      contractType: 'Standard',
      wasteType: '',
      containerType: '',
      containerQuantity: '1',
      assignmentInterval: undefined,
      directLoading: false,
      deliveryDate: '',
      deliveryTimeRange: undefined,
      pickupDate: '',
      pickupTimeRange: undefined,
      publicGround: false,
      notes: '',
      orderNumber: '',
      contactPerson: '',
      contactPhone: '',
      request: '',
      state: '',
    };

    autoCompleteServiceLocation: string = '';
    autocompleteContainerType: string = '';
    autocompleteWasteType: string = '';
    selectedCountryState: string = 'NW';
    salesProductType: string = '';
    salesEquipmentType: string = '';
    showLoseMengeNotes: boolean = false;
    dateService!: DeliveryDateService;
    langService = new XLanguageService();

    @Prop()
    preselectedServiceLocation?: { id: string; name: string };

    @Prop({ default: false })
    isContractEditing?: boolean;

    @Prop({ default: null })
    contractData?: Record<string, unknown>;

    mounted() {
      const deliveryStore = getModule(DeliveryStoreModule, this.$store);
      deliveryStore.fetchServerTime(this.$axios);

      this.bindStore(getModule(ContractsStoreModule, this.$store));
      document.getElementsByClassName('animation-content')[0].classList.add('animation-content-overflow');
      this.autoCompleteServiceLocation = this.preselectedServiceLocation ? this.preselectedServiceLocation.name : '';
      this.formData.serviceLocation = this.preselectedServiceLocation ? this.preselectedServiceLocation.id : '';
      contractsStore.setStatus('success');
      this.handleEvents();

      this.formData.deliveryTimeRange = DeliveryTimeRange.GANZTAGS;

      if (this.isContractEditing) {
        const contractData = this.contractData as any;
        this.autoCompleteServiceLocation = contractData.serviceLocation.name;
        this.formData.assignmentInterval = contractData.customerIntervalExternalQualifier;
        this.formData.contactPerson = contractData.contactPerson.name;
        this.formData.contactPhone = contractData.contactPerson.phone;
        this.formData.containerQuantity = contractData.containerQuantity.toString();
        this.formData.containerType = contractData.containerDescription;
        this.formData.contractType = contractData.contractType;
        this.formData.directLoading = contractData.directLoading;
        this.formData.notes = contractData.contractRemark;
        this.formData.orderNumber = contractData.customOrderNumber;
        this.formData.publicGround = contractData.publicGround.toString();
        this.formData.wasteType = contractData.wastetypeDescription;
      } else {
        this.formData.contractType = this.defaultContractType;
      }
    }

    get defaultContractType(): string {
      if (!this.hasStandardContainerTypeOptions && !this.hasCycleContainerTypeOptions && !this.hasSalesContainerTypeOptions) {
        return 'Request';
      }
      if (!this.hasStandardContainerTypeOptions && !this.hasCycleContainerTypeOptions) {
        return 'Sales';
      }
      if (!this.hasStandardContainerTypeOptions) {
        return 'Cycle';
      }
      return 'Standard';
    }

    get getContractDataDates() {
      const contractData = this.contractData as any;
      return {
        deliveryDate: dayjs(contractData.deliveryDate),
        deliveryTimeRange: contractData.deliveryTimeRange,
        pickupDate: dayjs(contractData.pickupDate),
        pickupTimeRange: contractData.pickupTimeRange,
      };
    }

    handleEvents() {
      this.subscribeEvents((mutation) => {
        if (mutation.type.match(/Contracts\/(create|update)ContractSuccess/)) {
          this.showSuccess(this.$t(mutation.type.match(/update/) ? 'global.toasts.updated' : 'global.toasts.created') as string);
          this.handleCancel();
          this.resetForm();
          if (mutation.type.match(/update/)) {
            const xlanguage = this.langService.getLanguageString(this.$i18n.locale);
            this.$store.dispatch('CustomerContracts/fetchContractDetails', { contract: mutation.payload.externalQualifier, xlanguage });
          }
        }
        if (mutation.type.match(/Contracts\/createContractError/)) {
          this.showError(this.$t('global.toasts.createFailed') as string);
        }
        if (mutation.type.match(/Contracts\/updateContractError/)) {
          this.showError(this.$t('global.toasts.updateFailed') as string);
        }
        if (mutation.type === 'Delivery/updateDates') {
          this.formData.deliveryDate = mutation.payload.deliveryDate;
        }
      });
    }

    onDeliveryDateChange(deliveryDate: string) {
      deliveryStore.updateDeliveryDate(deliveryDate);
      if (!this.formData.deliveryTimeRange) {
        this.formData.deliveryTimeRange = DeliveryTimeRange.GANZTAGS;
      }
    }

    onPickupDateChange() {
      if (!this.formData.pickupTimeRange) {
        this.formData.pickupTimeRange = PickUpTimeRange.GANZTAGS;
      }
    }

    onPickupTimeReset() {
      this.$refs.pickupDate?.resetPickUpFields();
    }

    @Watch('preselectedServiceLocation')
    updatePreselectedServiceLocation() {
      this.autoCompleteServiceLocation = this.preselectedServiceLocation ? this.preselectedServiceLocation.name : '';
    }

    @Watch('formData.serviceLocation')
    clearWasteTypeIfServiceLocationCleared() {
      if (this.formData.serviceLocation === '' || this.formData.serviceLocation === null || this.formData.serviceLocation === undefined) {
        this.formData.wasteType = '';
        this.autocompleteWasteType = '';
      }
      this.formData.contactPerson = this.getContactPersonFromSelectedServiceLocation.name;
      this.formData.contactPhone = this.getContactPersonFromSelectedServiceLocation.phone;
    }

    get getContactPersonFromSelectedServiceLocation(): { name: string; phone: string } {
      return serviceLocationStore.serviceLocationList.filter((serviceLocation) => serviceLocation.id === this.formData.serviceLocation)[0]
        .contactPerson;
    }

    @Watch('formData.wasteType')
    clearContainerTypeIfWasteTypeCleared(wasteType: string, prevWasteType: string) {
      if (this.isContractEditing) return;
      if (wasteType !== prevWasteType && prevWasteType && this.formData.contractType !== 'Sales') {
        this.formData.containerType = '';
        this.autocompleteContainerType = '';
      }
    }

    onSelectWasteType(option: any) {
      this.formData.wasteType = option ? option.id : '';
    }

    onSelectContainerType(option: any) {
      const wasteType = this.positiveListItems.wasteTypes.find((entry) => entry.id === option?.parent);
      if (wasteType) {
        this.formData.wasteType = option.parent;
        this.autocompleteWasteType = wasteType ? wasteType.name : '';
      }
      this.formData.containerType = option ? option.id : '';
    }

    @Watch('formData.containerType')
    onContainerTypeChanged(containerType: string) {
      if (this.isContractEditing) return;

      if (RENTAL_TOILET.includes(containerType)) {
        // TODO: maximales pickupdate +4 wochen
        // this.contractData.pickupDate = dayjs(this.contractData.pickupDate).add(4, 'week').toISOString();
      }
      const isLoseMenge = LOSE_MENGE.includes(containerType);
      this.formData.directLoading = isLoseMenge;
      this.showLoseMengeNotes = isLoseMenge;
    }

    @Watch('formData.contractType')
    clearContainerAndWasteTypeIfContractSwitch(contractType: string) {
      if (this.isContractEditing) return;
      if (contractType === 'Request') {
        this.formData.notes = '';
        this.$refs.validationObserver.reset();
      }
      this.autocompleteWasteType = '';
      this.autocompleteContainerType = '';
      this.formData.wasteType = '';

      if (contractType === 'Cycle') {
        this.formData.directLoading = false;
      }

      if (!this.isContractEditing) {
        this.formData.containerType = '';
        this.formData.wasteType = '';
      }
    }

    @Watch('formData.directLoading')
    clearPickupTimeRange() {
      if (this.isContractEditing) return;
      this.formData.pickupTimeRange = undefined;
    }

    @Watch('formData.containerQuantity')
    onContainerQuantityChange(quantity: string) {
      if (this.isContractEditing) return;
      // RegExp allow only numbers from 0-9 and whitespace
      const regexp = /^[0-9 ]*$/;
      if (regexp.test(quantity)) {
        if (+quantity > 99) {
          this.formData.containerQuantity = '99';
        }
      } else {
        this.formData.containerQuantity = '1';
      }
    }

    triggerValidationOnInput() {
      if (this.formData.notes && this.formData.notes?.length > 5) {
        this.$refs.validationObserver.validate();
      }

      if (this.formData.contactPerson.length > 0 && this.formData.contactPhone.length > 5) {
        this.$refs.validationObserver.validate();
      }
    }

    get deliveryTimeRangeOptions() {
      return Object.values(DeliveryTimeRange);
    }

    get pickupTimeRangeOptions() {
      return Object.values(PickUpTimeRange);
    }

    get assignmentIntervalOptions() {
      const intervalList = accountStore.intervalsList;

      if (this.$i18n.locale === 'en') {
        return this.translateAssignmentIntervalOptions(intervalList);
      }
      return intervalList;
    }

    get requestValidation() {
      return this.formData.notes && this.formData.notes.length > 5;
    }

    get nextPossibleDelivery() {
      return deliveryStore.nextPossibleDelivery;
    }

    get nextPossiblePickupDate() {
      return deliveryStore.nextPossiblePickup;
    }

    get statusMessage() {
      return contractsStore.statusMessage;
    }

    get getContractTypeText() {
      return this.formData.contractType === 'Standard'
        ? this.$t('contracts.form.contractTypes.dailyBusinessText')
        : this.formData.contractType === 'Cycle'
          ? this.$t('contracts.form.contractTypes.permanentBusinessText')
          : this.formData.contractType === 'Sales'
            ? this.$t('contracts.form.contractTypes.salesText')
            : this.$t('contracts.form.contractTypes.requestText');
    }

    translateAssignmentIntervalOptions(intervalList: { id: string; name: string }[]): { id: string; name: string }[] {
      const translations: Record<string, string> = {
        '0_ON DEMAND': 'on demand',
        '1_1WO': '1 time per week',
        '1_2WO': '2 times per week',
        '1_3WO': '3 times per week',
        '1_4WO': '4 times per week',
        '1_5WO': '5 times per week',
        '1_A2': 'every 2 weeks',
        '1_A3': 'every 3 weeks',
        '1_A4': 'every 4 weeks',
        '1_A6': 'every 6 weeks',
        '1_A8': 'every 8 weeks',
        '2_A12': 'every 12 weeks',
      };

      return intervalList.map((option) => {
        const translatedName = translations[option.id] || option.name;
        return { ...option, name: translatedName };
      });
    }

    handleSubmit() {
      const data: Record<string, unknown> = {
        companyExternalQualifier: accountStore.customerNumber,
        contactPerson: {
          name: this.formData.contactPerson,
          phone: this.formData.contactPhone,
        },
        containerQuantity: +this.formData.containerQuantity,
        containertypeExternalQualifier: this.formData.containerType || null,
        customOrderNumber: this.formData.orderNumber,
        deliveryDate: this.formData.deliveryDate ?? null,
        deliveryTimeRange: this.formData.deliveryTimeRange ?? null,
        directLoading: this.formData.directLoading,
        pickupTimeRange: this.formData.pickupTimeRange ?? null,
        pickupDate: this.formData.pickupDate ?? null,
        publicGround: !!this.formData.publicGround,
        remark: this.formData.notes,
        requestType: this.formData.contractType,
        serviceLocationExternalQualifier: this.formData.serviceLocation,
        wastetypeExternalQualifier: this.formData.wasteType,
        customerIntervalExternalQualifier: undefined,
        xlanguage: XLanguage.DE,
        serviceExternalQualifier: undefined,
        state: this.selectedCountryState,
      };

      if (this.formData.directLoading) {
        this.formData.pickupDate = '';
        this.formData.pickupTimeRange = '';
      }

      if (this.formData.contractType === 'Cycle' && this.formData.assignmentInterval) {
        data.customerIntervalExternalQualifier = this.formData.assignmentInterval;
        data.pickupTimeRange = null;
      }

      this.$store.dispatch('CustomerContracts/createContract', data);
    }

    handleUpdateContract() {
      const langService = new XLanguageService();
      this.$store.dispatch('CustomerContracts/updateContract', {
        name: this.formData.contactPerson,
        phone: this.formData.contactPhone,
        xlanguage: langService.getLanguageString(this.$i18n.locale),
        externalQualifier: this.contractData?.externalQualifier,
      });
    }

    get wasteTypeOptions() {
      return filterOptions(this.positiveListItems.wasteTypes, this.autocompleteWasteType ?? '');
    }

    get isInvalidWastetype() {
      return (
        this.autocompleteWasteType.length > 0 &&
        this.positiveListItems.wasteTypes.filter((item) => item.name === this.autocompleteWasteType).length === 0
      );
    }

    get containerTypeOptionsDefault() {
      const selectedWasteType = this.formData.wasteType;
      if (this.isInvalidWastetype) {
        return [];
      }
      return filterOptions(
        this.positiveListItems.containerTypes.filter((item) => {
          if (!selectedWasteType && RENTAL_TOILET.includes(item.id)) return true;
          return item.parent === selectedWasteType;
        }),
        this.autocompleteContainerType
      );
    }

    get containerTypeOptionsSales() {
      const selectedWasteType = this.formData.wasteType;
      return filterOptions(
        this.positiveListItems.containerTypes.filter((item) => {
          if (!selectedWasteType) return true;
          return item.parent === selectedWasteType;
        }),
        this.autocompleteContainerType
      );
    }

    onBlurWasteType() {
      setTimeout(() => {
        if (!this.positiveListItems.wasteTypes.find((entry) => entry.name === this.autocompleteWasteType)) {
          this.resetWasteType();
        }
      }, 200);
    }

    onBlurContainerType() {
      setTimeout(() => {
        if (!this.containerTypeOptionsDefault.find((entry) => entry.name === this.autocompleteContainerType)) {
          this.resetContainerType();
        }
      }, 200);
    }

    onBlurContainerTypeSales() {
      setTimeout(() => {
        if (!this.containerTypeOptionsSales.find((entry) => entry.name === this.autocompleteContainerType)) {
          this.resetContainerType();
        }
      }, 200);
    }

    onSelectContainerTypeSales(option: any) {
      if (option === null) {
        this.formData.wasteType = '';
        this.autocompleteWasteType = '';
        return;
      }
      this.formData.containerType = option ? option.id : null;
    }

    resetWasteType() {
      this.formData.wasteType = '';
      this.autocompleteWasteType = '';
    }

    resetContainerType() {
      this.formData.containerType = '';
      this.autocompleteContainerType = '';
    }

    onSelectServiceLocation(option: { id: string; state: string }) {
      this.selectedCountryState = option?.state;
      this.formData.serviceLocation = option ? option.id : undefined;
      deliveryStore.setCountryState(option?.state);
    }

    get hasStandardContainerTypeOptions() {
      return accountStore.positiveListItems.standard.containerTypes.length > 0;
    }

    get hasSalesContainerTypeOptions() {
      return accountStore.positiveListItems.sales.containerTypes.length > 0;
    }

    get hasCycleContainerTypeOptions() {
      return accountStore.positiveListItems.cycle.containerTypes.length > 0;
    }

    get positiveListItems() {
      const contractType = this.formData.contractType.toLowerCase();
      switch (contractType) {
        case 'sales':
          return accountStore.positiveListItems.sales;
        case 'cycle':
          return accountStore.positiveListItems.cycle;
        default:
          return accountStore.positiveListItems.standard;
      }
    }

    get serviceLocationList() {
      return filterOptions(serviceLocationStore.serviceLocationList, this.autoCompleteServiceLocation);
    }

    get getDatePickerData() {
      return {
        deliveryDate: this.formData.deliveryDate,
        deliveryTimeRange: this.formData.deliveryTimeRange,
        pickupDate: this.formData.pickupDate,
        pickupTimeRange: this.formData.pickupTimeRange,
      };
    }

    set getDatePickerData(datePickerData: Object) {
      this.formData = {
        ...this.formData,
        ...datePickerData,
      };
    }

    checkContainerQuantityValue(event: Event): void {
      const containerQuantityValue = (event.target as HTMLInputElement).value;
      // Set containerQuantity back to default-value
      if (!containerQuantityValue) {
        this.formData.containerQuantity = '1';
      }
    }

    decreaseIncreaseContainerQuantity(type: number): void {
      if (this.formData.containerQuantity === '') {
        this.formData.containerQuantity = '1';
      } else {
        let containerQuantityNumber = +this.formData.containerQuantity;
        if (type < 0) {
          containerQuantityNumber > 1 && containerQuantityNumber--;
        } else {
          containerQuantityNumber < this.MAX_AMOUNT && containerQuantityNumber++;
        }
        this.formData.containerQuantity = containerQuantityNumber.toString();
      }
    }

    resetForm() {
      const wrapperElem = document.getElementById('create-contract-form');
      if (wrapperElem !== null) {
        wrapperElem.scrollIntoView();
      }
      this.autoCompleteServiceLocation = '';
      this.autocompleteContainerType = '';
      this.autocompleteWasteType = '';
      this.$refs.validationObserver.reset();

      this.formData = {
        serviceLocation: '',
        contractType: 'Standard',
        wasteType: '',
        containerType: '',
        containerQuantity: '1',
        assignmentInterval: '',
        directLoading: false,
        deliveryDate: '',
        deliveryTimeRange: '',
        pickupDate: '',
        pickupTimeRange: '',
        publicGround: false,
        notes: '',
        orderNumber: '',
        contactPerson: '',
        contactPhone: '',
        request: '',
        state: '',
      };
      this.$refs.deliveryDate?.resetPickUpFields();
      this.$refs.deliveryTimeRange?.resetPickUpFields();
      this.$refs.pickupDate?.resetPickUpFields();
      this.$refs.pickupTimeRange?.resetPickUpFields();
    }

    handleCancel() {
      this.resetForm();
      (this.$parent as any).close();
    }
  }
