import { Navigation } from '@redooo/shared/dist/types/content/navigation';
export const makeNavigation = (links: { name: string; url: string }[]) => {
  return (
    links &&
    links.map((item) => {
      const navItem = new Navigation();
      navItem.title = item.name;
      navItem.slug = item.url;
      navItem.display = true;
      navItem.level = 0;
      navItem.children = [];

      // navItem.link = link;
      return navItem;
    })
  );
};
