import { Module, Mutation } from 'vuex-module-decorators';
import { PaginatedApiModule } from './PaginatedApiModule';
@Module({
  name: 'CustomerInvoice',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class CustomerInvoiceStoreModule extends PaginatedApiModule {
  customerNumber!: string;
  protected recordKey: string = 'documentExternalQualifier';

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  get serviceUrl(): string {
    const state = this.context.state as any;
    return '/portal/customer/' + state.customerNumber + '/invoices';
  }

  get tableRecords(): any[] {
    return this.records.map((invoice: any) => {
      return {
        ...invoice,
        id: invoice.documentExternalQualifier,
      };
    });
  }

  get tableModalContent(): object | undefined {
    return {};
  }
}
