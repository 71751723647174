import Keycloak from 'keycloak-js';
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import logger from '@redooo/shared/dist/services/logging';
import { getModule } from 'vuex-module-decorators';
import { maxLastChars } from '../lib/utils';
import { getAbsolutePathToHomeRoute, isAnonymousRoute } from '../services/auth';
import { injectApiServiceHandler } from '../services/api/apiServiceHandler';
import AccountStoreModule from '../store/Account';
import ServiceLocationStoreModule from '../store/ServiceLocation';
import { KeycloakRealm } from '../types/account';
import ContractsStoreModule from '~/store/CustomerContracts';
import PartnerContractsStoreModule from '~/store/PartnerContracts';
import PartnerOrdersStoreModule from '~/store/PartnerOrders';
import PartnerInvoiceStoreModule from '~/store/PartnerInvoice';
import CustomerInvoiceStoreModule from '~/store/CustomerInvoice';
import DispositionStoreModule from '~/store/Disposition';
import OfferStoreModule from '~/store/Offer';
import { portalStore } from '~/store';

type KeycloakProfileExtended = Keycloak.KeycloakProfile & { attributes: Record<string, string[]> };

const extractPhoneNumberFromProfile = (userProfile: KeycloakProfileExtended): string => {
  if (userProfile.attributes?.phoneNumber) {
    return userProfile.attributes?.phoneNumber[0];
  }
  if (userProfile.attributes?.mobile) {
    return userProfile.attributes?.mobile[0];
  }
  return '';
};

const getLanguageFromUrlPath = (url: string): string => {
  // TODO: Check if there is a better solution for future language implementations
  const isEnRegex: RegExp = /\/en/;
  const isEn: boolean = isEnRegex.test(url);
  let xlanguage = 'de-DE';
  if (isEn === true) {
    xlanguage = 'en-US';
  }

  return xlanguage;
};

export default (context: Context, inject: Inject) => {
  return new Promise((resolve) => {
    // Set current realm
    let currentRealm: KeycloakRealm;
    if (context.$config.LOGIN_AS_PARTNER) {
      currentRealm = KeycloakRealm.PARTNER;
    } else if (context.$config.LOGIN_AS_CUSTOMER) {
      currentRealm = KeycloakRealm.CUSTOMER;
    } else {
      currentRealm = window.location.hostname.match(/partner/) ? KeycloakRealm.PARTNER : KeycloakRealm.CUSTOMER;
    }

    const localStorageLoginStateKey = `${context.$config.OAUTH_CLIENT_ID}-${currentRealm}-loggedIn`;
    const onLoad = window.location.pathname === '/' ? 'check-sso' : undefined;
    const browserLanguageIsDE = navigator.language.includes('de');

    const keycloak = new Keycloak({
      url: context.$config.OAUTH_SERVER_BASE_PATH,
      realm: currentRealm,
      clientId: context.$config.OAUTH_CLIENT_ID,
    });

    // (1) init
    keycloak.init({
      onLoad,
      enableLogging: true,
      checkLoginIframe: false,
      silentCheckSsoFallback: false,
      pkceMethod: 'S256',
      redirectUri: `${window.location.href}${!browserLanguageIsDE ? 'en' : ''}`,
    });
    logger.info('Keycloak: (1) Keycloak init');

    // (2) on auth access success
    keycloak.onAuthSuccess = async () => {
      logger.info('Keycloak: (2) On auth access success', maxLastChars(keycloak.refreshToken), maxLastChars(keycloak.token));
      const userProfile = (await keycloak.loadUserProfile()) as KeycloakProfileExtended;
      const accountStore = getModule(AccountStoreModule, context.store);
      const contractsStore = getModule(ContractsStoreModule, context.store);
      const partnerOrdersStore = getModule(PartnerOrdersStoreModule, context.store);

      accountStore.setLoginKey(localStorageLoginStateKey);
      accountStore.setCurrentRealm(currentRealm);
      contractsStore.setCurrentRealm(currentRealm);
      portalStore.initializeRealm(currentRealm);

      const profile = {
        username: userProfile.username as string,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        phoneNumber: extractPhoneNumberFromProfile(userProfile),
        enabled: userProfile.enabled,
      };
      accountStore.setUserInfo(profile);
      contractsStore.initOrderer(profile);
      partnerOrdersStore.initOrderer(profile);
    };

    // (3) Is authenticated?
    keycloak.onReady = async (authenticated) => {
      logger.info('Keycloak: (3) Is authenticated?', authenticated);

      try {
        const api = await injectApiServiceHandler(context, keycloak);
        inject('api', api);

        if (isAnonymousRoute(window.location.pathname)) {
          return resolve(true);
        }

        if (authenticated) {
          logger.info('Keycloak: (4a) Fetch account-data');
          const accountStore = getModule(AccountStoreModule, context.store);
          const serviceLocationStore = getModule(ServiceLocationStoreModule, context.store);
          const contractStore = getModule(ContractsStoreModule, context.store);
          const partnerContractsStore = getModule(PartnerContractsStoreModule, context.store);
          const partnerOrderStore = getModule(PartnerOrdersStoreModule, context.store);
          const partnerInvoiceStore = getModule(PartnerInvoiceStoreModule, context.store);
          const customerInvoiceStore = getModule(CustomerInvoiceStoreModule, context.store);
          const offerStore = getModule(OfferStoreModule, context.store);
          const dispositionStore = getModule(DispositionStoreModule, context.store);

          accountStore.setApiInstance(api);
          serviceLocationStore.setApiInstance(api);
          contractStore.setApiInstance(api);
          partnerContractsStore.setApiInstance(api);
          partnerOrderStore.setApiInstance(api);
          partnerInvoiceStore.setApiInstance(api);
          customerInvoiceStore.setApiInstance(api);
          offerStore.setApiInstance(api);
          dispositionStore.setApiInstance(api);

          context.store.subscribe((mutation) => {
            if (mutation.type === 'Account/accountSuccess') {
              logger.info('Keycloak: (5) Account successfully loaded');
            }
            if (mutation.type === 'Account/accountFailed') {
              keycloak.clearToken();
              window.localStorage.removeItem(localStorageLoginStateKey);
            }
          });

          // Fetch Language string
          const xlanguage = getLanguageFromUrlPath(window.location.pathname);

          // Fetch account-data
          await accountStore.fetchAccount(xlanguage);

          // Fetch counters for customerportal
          if (window.location.hostname.match(/kunden/) || context.$config.LOGIN_AS_CUSTOMER) {
            await contractStore.fetchContracts({ open: true, filter: {}, page: 1, xlanguage });
          }

          // Fetch counters for partnerportral
          if (window.location.hostname.match(/partner/) || context.$config.LOGIN_AS_PARTNER) {
            // Workaround, because keycloakadapter has no nuxt context and therefore no language
            const isEnRegex: RegExp = /\/en/;
            const isEn: boolean = isEnRegex.test(window.location.pathname);

            // Fetch offers/acceptance
            await offerStore.fetchOffers(isEn);

            // Fetch dispositions
            await dispositionStore.fetchDispositions(isEn);

            // Fetch open, reportedBack, and closed orders
            await partnerOrderStore.fetchNextPage({
              params: { open: true, filter: {}, xlanguage: '', isEn },
            });
            await partnerOrderStore.fetchNextPage({
              params: { open: false, filter: {}, xlanguage: '', isEn },
            });

            // Fetch unbooked and booked invoices
            await partnerInvoiceStore.fetchNextPage({
              params: { open: true, filter: {}, xlanguage: '', isEn },
            });
            await partnerInvoiceStore.fetchNextPage({
              params: { open: false, filter: {}, xlanguage: '', isEn },
            });
          }

          return resolve(true);
        }

        const redirectUri = window.location.pathname === '/' ? getAbsolutePathToHomeRoute(window.location) : window.location.href;
        logger.info('Keycloak: (4b) Login user with redirect uri', redirectUri);

        return keycloak.login({ redirectUri });
      } catch (e) {
        console.log('error', e);
        return resolve(false);
      }
    };

    keycloak.onAuthRefreshSuccess = () => {
      logger.success('Keycloak: On auth access refresh success!', maxLastChars(keycloak.refreshToken), maxLastChars(keycloak.token));
    };

    // keycloak.onTokenExpired = () => {
    //   logger.info('keycloak.onTokenExpired ' + new Date());
    // };

    keycloak.onAuthLogout = () => {
      window.localStorage.setItem(localStorageLoginStateKey, '0');
      logger.info('Keycloak: User logout action occured!');
    };

    keycloak.onAuthRefreshError = () => {
      // Close all modals in the customer portal
      if (window.location.hostname.match(/kunden/) || context.$config.LOGIN_AS_CUSTOMER) {
        window.$nuxt.$emit('close-modals');
      }
      context.store.commit('Account/setSessionExpired', true);
      context.error({ message: 'sessionTimeout', path: context.route.path });
    };

    inject('keycloak', keycloak);

    // used to logout the user, when a logout process was initiated in a different browser tab
    window.addEventListener('storage', function (e) {
      if (e.key === localStorageLoginStateKey && e.newValue === '0') {
        logger.info('user has been logged out from different tab');
        keycloak.clearToken();
      }
    });
  });
};
