import { AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from '@redooo/shared/dist/services/logging';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

type tokenRefreshCallBack = () => Promise<string | undefined>;

export class AxiosInterceptor {
  constructor(
    private axiosInstance: NuxtAxiosInstance,
    private tokenRefreshCallBack: tokenRefreshCallBack
  ) {
    axiosInstance.interceptors.request.use(this.onRequest);
    axiosInstance.interceptors.response.use(this.onResponse, this.onError);
  }

  private onRequest = async (axiosRequestConfig: any): Promise<AxiosRequestConfig> => {
    const freshAccessToken = await this.tokenRefreshCallBack();
    if (!freshAccessToken) {
      throw new Error('auth error, no valid access token');
    }
    this.axiosInstance.setToken('Bearer ' + freshAccessToken);
    return Promise.resolve(axiosRequestConfig);
  };

  private onResponse = (axiosResponse: any): AxiosResponse => {
    return axiosResponse;
  };

  private onError = (error: Error): Promise<Error> => {
    logger.error('we got an error ', error);
    return Promise.reject(error);
  };

  public get axios() {
    return this.axiosInstance;
  }
}
