import { Context } from '@nuxt/types';
import { KeycloakInstance } from 'keycloak-js';
import logger from '@redooo/shared/dist/services/logging';
import ApiService from '../apiService';
import { AxiosInterceptor } from './axiosInterceptor';

export const injectApiServiceHandler = async (context: Context, keycloak: KeycloakInstance) => {
  const api = context.$axios.create({
    baseURL: context.$config.SERVICE_BASE_URL,
    withCredentials: true,
  });

  if (!keycloak.authenticated) {
    return new ApiService(api);
  }

  const interceptor = new AxiosInterceptor(api, async (): Promise<string | undefined> => {
    try {
      /*
      Token (Access Token Lifespan) will be refreshed as long as refreshed token (SSO Session Idle) has not expired.
      This will work for the duration of SSO Session Max.
      Example Token (Access Token Lifespan) will expire in 2 min you can refresh it during 5 min with refreshed token (SSO Session Idle).
      And it will work maximum during 8 hours SSO Session Max
      */
      // Returns true if the token has less than 900 seconds left before it expires.
      // If the token expires within 900 seconds, the token is refreshed.
      // logger.info('keycloak.isTokenExpired(30):', keycloak.isTokenExpired(30));
      await keycloak.updateToken(900);
      return keycloak.token;
    } catch (e) {
      context.$sentryLogger('we got an error inside token refresh callback', { originError: e });
      logger.error('we got an error inside token refresh callback', e);
    }
    return undefined;
  });

  const csrfToken = await api.get('/portal');
  interceptor.axios.setHeader('csrf-token', csrfToken.data);

  return new ApiService(interceptor.axios);
};
