import { AccountRole, AccountType, CustomerIntervals, emptyPositiveList, GroupedbyTypePositivelist } from '../../types/account';

export enum KeycloakRealm {
  DENKWERK = 'denkwerk',
  PARTNER = 'disposer',
  CUSTOMER = 'commercial-customer',
}

export enum Permission {
  NONE = 'none',
  READ = 'read',
  WRITE = 'write',
}

export type GlobalAccountPermission = { invoicing: Permission; administration: Permission };
export type CustomerAccountPermission = { serviceLocations: Permission } & GlobalAccountPermission;
export type PartnerAccountPermission = { distribution: Permission; disposition: Permission } & GlobalAccountPermission;
export type AccountPermission = CustomerAccountPermission | PartnerAccountPermission;

export type Employee = {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  permissions: AccountPermission;
  phoneNumber: string;
  realm: string;
  serviceLocations: string[];
};

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export enum TutorialEnum {
  // GLOBAL
  SHOW_TUTORIAL_DASHBOARD = 'showTutorialDashboard',
  SHOW_TUTORIAL_ADMINISTRATION = 'showTutorialAdministration',
  SHOW_TUTORIAL_INVOICING = 'showTutorialInvoicing',
  // CUSTOMER
  SHOW_TUTORIAL_SERVICELOCATION = 'showTutorialServiceLocation',
  // PARTNER
}
export type TutorialKey =
  | TutorialEnum.SHOW_TUTORIAL_ADMINISTRATION
  | TutorialEnum.SHOW_TUTORIAL_DASHBOARD
  | TutorialEnum.SHOW_TUTORIAL_INVOICING
  | TutorialEnum.SHOW_TUTORIAL_SERVICELOCATION;

export enum TutorialValue {
  TRUE = 'true',
  FALSE = 'false',
}
export type Tutorial = PartialRecord<TutorialKey, TutorialValue>;

export class Account {
  accountIdentifier!: {
    type: AccountType;
    companyExternalQualifier: string;
  };
  allowedServiceLocations: string[] = [];
  companyName!: string;
  companyName2?: string;
  customerNumber!: string;
  email!: string;
  employees: Account[] = [];
  firstname!: string;
  id!: string;
  intervals: CustomerIntervals = [];
  lastname!: string;
  permissions?: AccountPermission;
  phoneNumber?: string;
  positiveList: GroupedbyTypePositivelist = emptyPositiveList;
  roles: AccountRole[] = [];
  serviceLocations: [] = [];
  tutorials!: Tutorial;
  username!: string;

  // GLOBAL
  get hasAdministrationReadPermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.ADMINISTRATION_READ) || this.roles.includes(AccountRole.ADMINISTRATION_WRITE);
  }
  get hasAdministrationWritePermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.ADMINISTRATION_WRITE);
  }
  get hasInvoicingReadPermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.INVOICING_READ) || this.roles.includes(AccountRole.INVOICING_WRITE);
  }
  get hasInvoicingWritePermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.INVOICING_WRITE);
  }

  // CUSTOMER
  get hasServiceLocationsReadPermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.SERVICE_LOCATION_READ) || this.roles.includes(AccountRole.SERVICE_LOCATION_WRITE);
  }
  get hasServiceLocationsWritePermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.SERVICE_LOCATION_WRITE);
  }

  // PARTNER
  get hasDistributionReadPermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.DISTRIBUTION_READ) || this.roles.includes(AccountRole.DISTRIBUTION_WRITE);
  }
  get hasDistributionWritePermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.DISTRIBUTION_WRITE);
  }
  get hasDispositionReadPermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.DISPOSITION_READ) || this.roles.includes(AccountRole.DISPOSITION_WRITE);
  }
  get hasDispositionWritePermissions(): boolean {
    return this.isAdmin || this.roles.includes(AccountRole.DISPOSITION_WRITE);
  }

  get isAdmin(): boolean {
    return this.roles.includes(AccountRole.ADMIN) || this.roles.includes(AccountRole.MASTER);
  }

  get mappedCustomerPermissions(): CustomerAccountPermission {
    if (this.isAdmin) {
      return { serviceLocations: Permission.WRITE, administration: Permission.WRITE, invoicing: Permission.READ };
    }
    return {
      administration: this.hasAdministrationWritePermissions
        ? Permission.WRITE
        : this.hasAdministrationReadPermissions
          ? Permission.READ
          : Permission.NONE,
      serviceLocations: this.hasServiceLocationsWritePermissions
        ? Permission.WRITE
        : this.hasServiceLocationsReadPermissions
          ? Permission.READ
          : Permission.NONE,
      invoicing: this.hasInvoicingReadPermissions ? Permission.READ : Permission.NONE,
    };
  }

  get mappedPartnerPermissions(): PartnerAccountPermission {
    if (this.isAdmin) {
      return { distribution: Permission.WRITE, invoicing: Permission.WRITE, disposition: Permission.WRITE, administration: Permission.WRITE };
    }
    return {
      administration: this.hasAdministrationWritePermissions
        ? Permission.WRITE
        : this.hasAdministrationReadPermissions
          ? Permission.READ
          : Permission.NONE,
      distribution: this.hasDistributionWritePermissions ? Permission.WRITE : this.hasDistributionReadPermissions ? Permission.READ : Permission.NONE,
      disposition: this.hasDispositionWritePermissions ? Permission.WRITE : this.hasDispositionReadPermissions ? Permission.READ : Permission.NONE,
      invoicing: this.hasInvoicingWritePermissions ? Permission.WRITE : this.hasInvoicingReadPermissions ? Permission.READ : Permission.NONE,
    };
  }
}
