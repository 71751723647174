export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/builds/denkwerk/redooo-platform/packages/portal/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"confirm/index":{"de":"/angebotspruefung","en":"/offerconfirm"},"offers/index":{"de":"/angebote","en":"/offers"},"disposition/index":{"de":"/disposition","en":"/disposition"},"operatives/index":{"de":"/abwicklung","en":"/operatives"},"operatives/_slug":{"de":"/abwicklung/:slug","en":"/operatives/:slug"},"orders/index":{"de":"/auftraege","en":"/orders"},"receipts/index":{"de":"/beleguebersicht","en":"/receipts"},"invoices/index":{"de":"/abrechnung","en":"/invoices"},"reporting/index":{"de":"/auswertung","en":"/reporting"},"serviceLocations/index":{"de":"/leistungsorte","en":"/servicelocations"},"serviceLocations/_slug":{"de":"/leistungsorte/:slug","en":"/servicelocations/:slug"},"contracts/index":{"de":"/vertraege","en":"/contracts"},"contracts/_slug":{"de":"/vertraege/:slug","en":"/contracts/:slug"},"requests/index":{"de":"/anfragen","en":"/requests"},"maintenance/index":{"de":"/wartungsarbeiten","en":"/maintenance"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js"}],
  localeCodes: ["en","de"],
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'de-DE.js': () => import('../../lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
