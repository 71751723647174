import dayjs from 'dayjs';
import { Module, Action, Mutation } from 'vuex-module-decorators';
import { plainToClass } from 'class-transformer';
import { PartialDeep } from 'type-fest';
import type { ContractRequestFormData, UpdateContractRequest, InteractionRequest } from '../types/contractRequest';
import type { OrderFeedbackFormData } from '../types/orderFeedback';
import { ContractOrder } from './entities/contractOrder';
import { ApiModule } from './ApiModule';
import { Contract } from '~/types/contract';
import { Orderer } from '~/types/orderer';
import { XLanguage } from '~/types/content';

type KeycloakProfileExtended = Keycloak.KeycloakProfile & { phoneNumber: string };
const ENTRIES_PER_PAGE = 60;

function uniqueList(objList: any[], key: string) {
  return [...new Map(objList.map((item) => [item[key], item])).values()];
}

function orderByTimerange(orders: ContractOrder[]) {
  const timeRangeOrder: Record<string, number> = { Morning: 1, Afternoon: 2, Fullday: 3 };
  return [...orders].sort((a, b) => (timeRangeOrder[a.timeRange] < timeRangeOrder[b.timeRange] ? -1 : 0));
}

type CalendarRange = { start: dayjs.Dayjs | undefined; end: dayjs.Dayjs | undefined };

@Module({
  name: 'CustomerContracts',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class ContractsStoreModule extends ApiModule {
  records: any[] = [];
  closedRecords: any[] = [];
  contractTypes: string[] = [];

  orders: ContractOrder[] = [];
  pendingOrders: ContractOrder[] = [];
  closedOrders: ContractOrder[] = [];
  calendarOrders: ContractOrder[] = [];
  loadedRange: CalendarRange = { start: dayjs(), end: dayjs() };
  orderTypes: string[] = [];

  customerNumber: string = '';
  realm: string = '';
  contractsError: string = '';
  profile?: Orderer;

  contractsPage = 1;
  ordersPage = 1;

  lastPendingPage = false;
  lastPageOpen = false;
  lastPageClosed = false;

  get serviceUrl(): string {
    const state = this.context.state as any;
    return `/portal/customer/${state.customerNumber}/contracts/?realm=${state.realm}`;
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  @Action
  async fetchContracts(params: { page: number; open: boolean; filter: Record<string, string>; xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const state = this.context.state as any;
      const filters = { ...params.filter };
      const response = await this.api.get(`/portal/customer/${state.customerNumber}/contracts`, {
        params: {
          open: params.open ? 1 : 0,
          page: params.page,
          filter: JSON.stringify(filters),
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit(params.open ? 'contractsOpenSuccess' : 'contractsClosedSuccess', response.data);
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  contractsOpenSuccess(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.lastPageOpen = response.data.length < ENTRIES_PER_PAGE;
    this.records = uniqueList([...this.records, ...response.data], 'contractNo');
    this.contractTypes = response.options.types;
  }

  @Mutation
  contractsClosedSuccess(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.lastPageClosed = response.data.length < ENTRIES_PER_PAGE;
    this.closedRecords = uniqueList([...this.closedRecords, ...response.data], 'contractNo');
    this.contractTypes = response.options.types;
  }

  @Mutation
  loadTable() {
    this.tableLoading = true;
  }

  @Mutation
  showNextPage() {
    this.contractsPage++;
  }

  @Mutation
  resetContractsPagination() {
    this.lastPageOpen = false;
    this.lastPageClosed = false;
    this.records = [];
    this.contractsPage = 1;
  }

  @Mutation
  resetOrderPagination() {
    this.lastPageOpen = false;
    this.lastPageClosed = false;
    this.ordersPage = 1;
  }

  @Action
  async createContract(data: ContractRequestFormData) {
    try {
      this.context.commit('actionLoading');
      const response = await this.api.post(`/portal/customer/${this.currentCustomerNumber}/contracts`, {
        ...data,
        ...this.orderer,
        state: data.state.length > 0 ? data.state : 'NW',
        xlanguage: XLanguage.DE,
      });
      this.context.commit('createContractSuccess', response.data.data);
    } catch (e) {
      this.context.commit('createContractError', (e as any).response?.data.msg);
    }
  }

  @Action
  async createOrderFeedback(data: OrderFeedbackFormData) {
    try {
      this.context.commit('actionLoading');
      data.loggedInUserFirstname = this.orderer?.ordererFirstname;
      data.loggedInUserLastname = this.orderer?.ordererLastname;
      data.loggedInUserEmail = this.orderer?.ordererEmail;
      data.loggedInUserPhone = this.orderer?.ordererPhoneNumber;

      const response = await this.api.post(`/portal/customer/${this.currentCustomerNumber}/orderfeedback`, {
        ...data,
      });
      this.context.commit('createOrderFeedbackSuccess', response.data.data);
    } catch (e) {
      this.context.commit('createOrderFeedbackError', (e as any).response?.data.msg);
    }
  }

  @Action
  async updateContract(request: UpdateContractRequest) {
    try {
      this.context.commit('actionLoading');
      const response = await this.api.put(`/portal/customer/${this.currentCustomerNumber}/contracts/${request.externalQualifier}`, {
        ...request,
      });
      this.context.commit('updateContractSuccess', response.data.data);
    } catch (e) {
      this.context.commit('updateContractError', (e as any).response?.data.msg);
    }
  }

  @Action
  async bookInteraction(data: InteractionRequest) {
    const state = this.context.state as any;
    const tmpProfile = { ...state.profile, ordererPhoneNumber: undefined };

    try {
      this.context.commit('actionLoading');
      const response = await this.api.put(`/portal/customer/${this.currentCustomerNumber}/contracts/interaction/${data.externalQualifier}`, {
        ...tmpProfile,
        wastetypeExternalQualifier: data.wastetypeExternalQualifier,
        containerQuantity: data.quantity,
        serviceDate: data.date,
        serviceTimeRange: data.timeRange,
        type: data.type,
        orderNotes: data.orderNotes,
        orderId: data.orderId,
        xlanguage: data.xlanguage,
      });
      this.context.commit('interactionContractSuccess', response.data.data);
    } catch (e) {
      this.context.commit('interactionContractError', (e as any).response?.data.msg);
    }
  }

  @Action
  async fetchContractDetails(contractObject: any) {
    try {
      this.context.commit('actionLoading');
      this.context.commit('loadTable');
      const contract = contractObject.contract;
      const xlanguage = contractObject.xlanguage;
      const params = {
        xlanguage,
      };
      const response = await this.api.get(`/portal/customer/${this.currentCustomerNumber}/contracts/${contract}`, { params });
      this.context.commit('setRecord', response.data.data);
    } catch (e) {
      this.context.commit('recordDetailError', e);
    }
  }

  @Mutation
  setContractDetail(record: Contract | undefined) {
    this.status = { type: '' };
    this.record = record;
  }

  @Action
  async fetchOrders(params: { page: number; status: string; filter: Record<string, string>; xlanguage: string }) {
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(`/portal/customer/${this.currentCustomerNumber}/orders`, {
        params: {
          status: params.status,
          page: params.page,
          filter: JSON.stringify({ ...params.filter }),
          xlanguage: params.xlanguage,
        },
      });
      this.context.commit('ordersSuccess', { data: response.data.data, options: response.data.options, status: params.status });
    } catch (e) {
      this.context.commit('ordersFailed', e as string);
    }
  }

  @Action
  async fetchOrdersForDateRange(dateRange: { start?: string; end?: string; xlanguage: string }) {
    try {
      const { start, end, xlanguage } = dateRange;

      const calendarParams = {
        start: start ?? this.loadedRange.start?.format('YYYY-MM-DD'),
        end: end ?? this.loadedRange.end?.format('YYYY-MM-DD'),
        xlanguage,
      };

      this.context.commit('loadTable');
      const response = await this.api.get(`/portal/customer/${this.currentCustomerNumber}/ordersDateRange`, { params: { ...calendarParams } });

      const calendarRange = { start: dayjs(calendarParams.start), end: dayjs(calendarParams.end) };
      const loadedRange = (this.context.state as any).loadedRange as CalendarRange;
      this.context.commit('ordersDateRangeSuccess', { data: response.data.data, range: calendarRange, loadedRange });
    } catch (e) {
      this.context.commit('ordersFailed', e as string);
    }
  }

  get calendarLoadedRange() {
    return this.loadedRange;
  }

  get inLoadedRange(): (range: CalendarRange) => boolean {
    return (range: CalendarRange) => {
      return ((this.loadedRange.start?.isBefore(range.start) || this.loadedRange.start?.isSame(range.start)) &&
        (this.loadedRange.end?.isAfter(range.end) || this.loadedRange.end?.isSame(range.end))) as boolean;
    };
  }

  @Mutation
  ordersDateRangeSuccess(response: { data: ContractOrder[]; range: CalendarRange; loadedRange: CalendarRange; status: string }) {
    this.tableLoading = false;
    this.calendarOrders = uniqueList([...this.calendarOrders, ...response.data], 'externalQualifier');
    const loadedRange = response.loadedRange;
    const range = response.range;

    if (range.start?.isBefore(loadedRange.start)) {
      this.loadedRange.start = range.start;
    }
    if (range.end?.isAfter(loadedRange.end)) {
      this.loadedRange.end = range.end;
    }
  }

  @Mutation
  ordersSuccess(response: { data: ContractOrder[]; status: string; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.orderTypes = response.options.types;
    if (response.status === 'open') {
      this.orders = uniqueList([...this.orders, ...response.data], 'orderNo');
      this.lastPageOpen = response.data.length < ENTRIES_PER_PAGE;
    } else if (response.status === 'pending') {
      this.pendingOrders = uniqueList([...this.pendingOrders, ...response.data], 'orderNo');
      this.lastPendingPage = response.data.length < ENTRIES_PER_PAGE;
    } else {
      this.closedOrders = uniqueList([...this.closedOrders, ...response.data], 'orderNo');
      this.lastPageClosed = response.data.length < ENTRIES_PER_PAGE;
    }
  }

  get isLastPageOpen() {
    return this.lastPageOpen;
  }

  get isLastPagePending() {
    return this.lastPendingPage;
  }

  get isLastPageClosed() {
    return this.lastPageClosed;
  }

  @Mutation
  ordersOpenSuccess(orders: ContractOrder[]) {
    this.tableLoading = false;
    this.lastPageOpen = orders.length < ENTRIES_PER_PAGE;
    orders.forEach((order) => {
      this.orders.push(plainToClass(ContractOrder, order));
    });
  }

  @Mutation
  ordersClosedSuccess(orders: any[]) {
    this.tableLoading = false;
    this.lastPageClosed = orders.length < ENTRIES_PER_PAGE;
    orders.forEach((record) => {
      this.closedOrders.push(record);
    });
  }

  @Mutation
  ordersFailed(_error: string) {
    this.tableLoading = false;
  }

  @Mutation
  initOrderer(user: KeycloakProfileExtended) {
    this.profile = {
      loggedInUser: user.username as string,
      ordererEmail: user.email as string,
      ordererFirstname: user.firstName as string,
      ordererLastname: user.lastName as string,
      ordererPhoneNumber: user.phoneNumber as string,
    };
  }

  @Mutation
  createContractSuccess() {
    this.status = { type: 'success' };
  }

  @Mutation
  createOrderFeedbackSuccess() {
    this.status = { type: 'success' };
  }

  @Mutation
  updateContractSuccess() {
    this.status = { type: 'success' };
  }

  @Mutation
  interactionContractSuccess(response: { hasContainer: boolean; contractExternalQualifier: string }) {
    if (!response.hasContainer) {
      const record = this.records.find((record) => record.externalQualifier === response.contractExternalQualifier);
      if (record) {
        record.exchangeAllowed = false;
        record.pickupAllowed = false;
        record.clearanceAllowed = false;
      }
    }
    this.status = { type: 'success' };
  }

  @Mutation
  createContractError(error: string) {
    this.status = { type: 'error' };
    this.contractsError = error;
  }

  @Mutation
  createOrderFeedbackError(error: string) {
    this.status = { type: 'error' };
    this.contractsError = error;
  }

  @Mutation
  updateContractError(error: string) {
    this.status = { type: 'error' };
    this.contractsError = error;
  }

  @Mutation
  interactionContractError(error: string) {
    this.status = { type: 'error' };
    this.contractsError = error;
  }

  @Mutation
  setCustomerNumber(customerNumber: string) {
    this.customerNumber = customerNumber;
  }

  get currentCustomerNumber(): string {
    return this.customerNumber;
  }

  @Mutation
  setCurrentRealm(realm: string) {
    this.realm = realm;
  }

  get currentRecord(): PartialDeep<any> {
    return this.record;
  }

  get currentContractOrders() {
    if (!this.currentRecord) {
      return [];
    }
    const orders = this.currentRecord.customerOrders;
    return orders?.map((order: ContractOrder) => {
      return {
        ...order,
        taskDate: dayjs(order.taskDate).format('DD.MM.YYYY'),
        timestamp: new Date(order.taskDate).getTime(),
      };
    });
  }

  get orderer(): Orderer | undefined {
    return this.profile;
  }

  get contractOrders() {
    return this.mapOrders(this.orders);
  }

  get calendarContractOrdersByTimeRange() {
    return orderByTimerange(this.calendarOrders);
  }

  get pendingContractOrders() {
    return this.mapOrders(this.pendingOrders);
  }

  get closedContractOrders() {
    return this.mapOrders(this.closedOrders);
  }

  get openContracts() {
    return this.mapContracts(this.records);
  }

  get closedContracts() {
    return this.mapContracts(this.closedRecords);
  }

  get contractTypeOptions() {
    return this.contractTypes;
  }

  get mapContracts() {
    return (records: any[]) =>
      records.map((item) => {
        return {
          ...item,
          containerType: item.containerDescription,
          id: item.externalQualifier,
          streetAddress: item.serviceLocationAddress,
          wasteType: item.wastetypeDescription,
        };
      });
  }

  get mapOrders() {
    return (orders: any[]) =>
      orders.map((order: any) => {
        return {
          ...order,
          streetAddress: order.serviceLocationAddress ?? order.streetAddress,
          taskDate: dayjs(order.taskDate),
          timestamp: new Date(order.taskDate).getTime(),
        };
      });
  }

  get contractsCount() {
    return this.records.length;
  }

  get ordersCount() {
    return this.orders.length;
  }

  get tableRecords() {
    return this.records;
  }
}
