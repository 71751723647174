
  import { Component, Vue } from 'nuxt-property-decorator';
  import { accountStore, portalStore } from '../../utils/store-accessor';
  import IconBase from '../shared/IconBase.vue';
  import type { PortalType } from '../../types/portalTypes';

  @Component({
    components: { IconBase },
  })
  export default class LoginSwitch extends Vue {
    loginNavigationOpen = false;

    mounted() {
      document.body.addEventListener('click', ({ target }: any) => {
        if (!this.$el.contains(target)) {
          this.toggleLoginNavigation(true);
        }
      });
    }

    get userName() {
      return accountStore.userFullName;
    }

    get isUserAuthenticated() {
      return accountStore.userIsAuthenticated;
    }

    get activePortal() {
      return portalStore.portalName;
    }

    toggleLoginNavigation(forceClose = false) {
      if (forceClose) {
        this.loginNavigationOpen = false;
        return;
      }
      this.loginNavigationOpen = !this.loginNavigationOpen;
    }

    selectPortal(portalType: string) {
      // this.toggleLoginNavigation(true);

      const matches = window.location.origin.match(/-(.[^.]*)/);
      const env = matches && matches.length > 1 ? matches[1] : 'dev';

      if (env !== 'qa') {
        portalStore.initialize(portalType as PortalType);
        this.$router.push('/');
        this.$emit('closeFlyout');
      } else {
        this.redirectToPortal(portalType, env);
      }
    }

    redirectToPortal(portalType: string, env: string) {
      if (portalType.match(/partner/)) {
        location.href = `https://partnerportal-${env}.redooo.de`;
      } else {
        location.href = `https://kundenportal-${env}.redooo.de`;
      }
    }
  }
