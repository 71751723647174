
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  type AutoCompleteItem = {
    id: string;
    name: string;
  };

  @Component({})
  export default class AutocompleteCheckbox extends Vue {
    @Prop({ default: '' })
    title?: string;

    @Prop({ default: '' })
    modelName?: string;

    @Prop({ default: () => [] })
    preselected?: string[];

    @Prop({ default: () => [] })
    items?: AutoCompleteItem[];

    @Prop({ default: false })
    disabled!: boolean;

    @Prop({ default: true })
    defaultselectall?: boolean;

    autocompleteInput: string = '';

    selectedIds: string[] = [];

    showSelectPane = false;

    data: AutoCompleteItem[] = [];

    mounted() {
      this.initializeSelected();
    }

    @Watch('items')
    watchItems() {
      this.initializeSelected();
    }

    @Watch('preselected')
    watchpreselected(value: string[]) {
      this.selectedIds = value ?? [];
    }

    @Watch('defaultselectall')
    watchDefaultselectall(value: boolean) {
      console.log('select all', value);
      if (value) {
        this.selectAll();
      }
    }

    @Watch('selectedIds')
    watchSelectedIds() {
      this.$emit('update:selected', this.selectedIds);
    }

    initializeSelected() {
      this.data = this.items ?? [];
      if (this.defaultselectall) {
        this.selectAll();
      } else {
        this.selectedIds = this.preselected ?? [];
      }
    }

    selectAll() {
      this.selectedIds = this.data.map((item) => item.id);
    }

    toggleSelectPane() {
      if (this.disabled) {
        return;
      }
      this.showSelectPane = !this.showSelectPane;
    }

    clearSearchInput() {
      this.autocompleteInput = '';
    }

    hideSelectPane() {
      this.showSelectPane = false;
    }

    toggleAll(e: MouseEvent) {
      e.preventDefault();
      this.selectedIds = !this.areAllOptionsSelected ? this.data.map((item) => item.id) : [];
    }

    get selectedData() {
      return this.data
        .filter((item: AutoCompleteItem) => this.selectedIds.includes(item.id as string))
        .sort((a: AutoCompleteItem, b: AutoCompleteItem) => (a.name > b.name ? 1 : -1));
    }

    get areAllOptionsSelected() {
      return this.selectedIds.length > 0 && this.selectedIds.length === this.data.length;
    }

    get selectedString() {
      if (this.selectedIds.length === 0) {
        return this.$t('global.none');
      }
      if (this.areAllOptionsSelected) {
        return this.$t('global.all');
      }
      const selectedString = this.selectedData.map((item: AutoCompleteItem) => item.name).join(', ');
      if (selectedString.length > 30) {
        return this.selectedData.length + ' ' + (this.modelName ?? (this.$t('global.autocompleteCheckbox.entries') as string));
      }

      return this.selectedData.length > 0 ? selectedString : (this.$t('global.autocompleteCheckbox.select') as string);
    }

    get filteredData() {
      return this.autocompleteInput?.length > 0
        ? this.data.filter((option) => {
            return option.name.toString().toLowerCase().includes(this.autocompleteInput?.toLowerCase());
          })
        : this.data;
    }

    setIsFocused(optionId: string) {
      this.selectedIds.forEach((id) => {
        console.log(optionId === id);
        return id === optionId;
      });
    }
  }
