import { NuxtAxiosInstance } from '@nuxtjs/axios';
import logger from '@redooo/shared/dist/services/logging';
import dayjs from 'dayjs';

export const getCurrentServerDateTime = async ($axios: NuxtAxiosInstance): Promise<dayjs.Dayjs> => {
  let response: any;

  try {
    response = await $axios.$get(`/date/serverDateTime`);
  } catch (error) {
    logger.error('Error getting server date time');
  }

  if (response && response.data) {
    return dayjs(new Date(response.data.timeUTC));
  } else {
    return dayjs();
  }
};
