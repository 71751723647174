import { XLanguage } from '~/types/content';

export const filterOptions = (options: { id: string; name: string }[], filter: string) => {
  if (!filter) {
    return options;
  }

  const input = filter.toLowerCase();
  if (input.length === 0) {
    return options;
  }
  const filteredOptions = options.filter((option) => {
    return option.name.toString().toLowerCase().includes(input);
  });
  const findEqual = filteredOptions.find((option) => option.name === filter);
  if (filteredOptions.length === 1 && findEqual) {
    return options;
  }
  return filteredOptions;
};

export const sortByDate = (a: { timestamp: number }, b: { timestamp: number }, isAsc: boolean) => {
  return isAsc ? a.timestamp - b.timestamp : b.timestamp - a.timestamp;
};

export const toLocaleDate = (date: string) => {
  const d = new Date(date);
  const timeStr: string = d.toLocaleTimeString(XLanguage.DE);
  return d.toLocaleDateString(XLanguage.DE, { day: '2-digit', month: '2-digit', year: 'numeric' }) + ' ' + timeStr.substr(0, timeStr.length - 3);
};

export const downloadFile = (fileUrl: string, fileName: string = 'download') => {
  const save = document.createElement('a');
  if (typeof save.download !== 'undefined') {
    save.href = fileUrl;
    save.target = '_blank';
    save.download = fileName;
    save.dispatchEvent(new MouseEvent('click'));
  } else {
    window.location.href = fileUrl; // so that it opens new tab for IE11
  }
};

export const grabInnerHtmlFromHtmlTag = (htmlTag: string): string => {
  return htmlTag.split(/[><]/)[2];
};

export function uniqueList(objList: any[], key: string) {
  return [...new Map(objList.map((item) => [item[key], item])).values()];
}

export const sortUnique = <T>(types: T[]): T[] => {
  return [...new Set(types)].sort((a, b) => (a < b ? -1 : 0));
};

export const isValueTrueOfKeyValuePair = (obj: Record<string, string>, pairKey: string): boolean => {
  let isValueTrue = false;
  Object.entries(obj).forEach((entry) => {
    const [key, value] = entry;
    if (key === pairKey && value === 'true') {
      return (isValueTrue = true);
    }
  });
  return isValueTrue;
};
