import { Module, Mutation, Action } from 'vuex-module-decorators';
import dayjs from 'dayjs';
import { PartialDeep } from 'type-fest';
import type { ContractOffer } from '../types/contractOffer';
import { BaseOfferStore } from './BaseOfferStore';
import { XLanguageService } from '~/types/content';

@Module({
  name: 'Disposition',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class DispositionStoreModule extends BaseOfferStore {
  records: ContractOffer[] = [];
  visibleRecords: ContractOffer[] = [];
  record?: PartialDeep<ContractOffer | undefined> = undefined;
  timer: number = 0;
  currentTime: dayjs.Dayjs = dayjs();
  timerInterval: any;
  customerNumber: string = '';
  types: string[] = [];
  langService = new XLanguageService();
  private selectedLanguage: string = this.langService.getLanguageString('de');

  get serviceUrl(): string {
    const state = this.context.state as any;
    return '/portal/partner/' + state.customerNumber + '/dispositions';
  }

  get partnerId() {
    return this.customerNumber;
  }

  get language(): string {
    return this.selectedLanguage;
  }

  @Mutation
  setSelectedLanguage(language: string) {
    this.selectedLanguage = language;
  }

  @Action
  async fetchDispositions(isEn: boolean) {
    try {
      const language = isEn ? 'en-US' : this.selectedLanguage;
      this.context.commit('loadTable');
      const response = await this.api.get(this.serviceUrl, {
        params: {
          xlanguage: language,
        },
      });
      this.context.commit('setDispositionRecords', response.data);
      this.context.commit('showNextPage');
    } catch (e) {
      console.error(e);
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  setDispositionRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.records = response.data;
    this.types = response.options.types;
  }

  get offerTypes() {
    return this.types;
  }

  @Mutation
  offerActionFailed(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  @Mutation
  offerValidationError() {
    this.status = { type: 'success' };
  }

  @Mutation
  offerNotFound(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  get recordsCount(): number {
    return this.records.length;
  }

  get currentRecord(): PartialDeep<ContractOffer> | undefined {
    return this.record;
  }

  get tableRecords() {
    // const records = this.filtersExist ? this.records : this.visibleRecords;

    return this.records.map((offer) => {
      return {
        taskDate: dayjs(offer.taskDate).format('DD.MM.YYYY'),
        timestamp: new Date(offer.taskDate).getTime(),
        externalQualifier: offer.externalQualifier,
        wastetypeDescription: offer.wastetypeDescription,
        containerDescription: offer.containerDescription,
        interval: offer.customerIntervalDescription,
        customerName: offer.companyName?.length > 0 ? offer.companyName : offer.contactPerson?.name,
        street: offer.serviceLocation?.street,
        streetNo: offer.serviceLocation?.streetNo,
        zipCode: offer.serviceLocation?.zipCode,
        city: offer.serviceLocation?.city,
        serviceLocation: offer.serviceLocation?.name,
        hasAction: true,
        type: offer.offerType,
        contractNo: offer.contractNo,
        cy: offer.type,
      };
    });
  }

  get tableModalContent(): object | undefined {
    return this.record
      ? {
          wasteType: this.record.wastetypeDescription,
          containerType: this.record.containerDescription,
          containerTypeAmount: this.record.containerQuantity,
          interval: this.record.customerIntervalDescription,
          price: 'price',
          customer: this.record.serviceLocation?.name,
          street: this.record.serviceLocation?.street,
          location: this.record.serviceLocation?.city,
          deliveryArea: this.record.publicGround ? 'öffentlich' : 'privat',
          notes: this.record.remark,
          contactPerson: this.record.contactPerson?.name,
          phoneNumber: this.record.contactPerson?.phone,
          mailAdress: this.record.contactPerson?.email,
        }
      : undefined;
  }
}
