import Vue from 'vue';
import { XLanguage } from '~/types/content';

export function toDate(value: string) {
  return new Date(value).toLocaleDateString(XLanguage.DE, { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export function uppercase(value: string) {
  return value.toUpperCase();
}

export function itemDescription(value: string) {
  return value.replace(/ pro tag| per day/i, '');
}

export function unit(value: string, locale: any) {
  value = value.toLowerCase();
  const translatedValue = locale.t(`priceComponents.unitOfMeasure.${value}`);

  return locale.t('priceComponents.per') + ' ' + translatedValue;
}

// input: price in cent, output: formatted price in euros
export function toCurrency(value: number, locale: string = 'de') {
  // TODO: get lang code & unit dynamically from current setting (i18n.locale, as argument?) currency from contentful?
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formatter.format(value / 100);
}

Vue.directive('click-outside', {
  bind: (el: any, binding: any, vnode: any) => {
    el.clickOutsideEvent = function (event: MouseEvent) {
      event.stopPropagation();
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: (el: any) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
