
  import { Component, mixins } from 'nuxt-property-decorator';
  import currentUser from '../mixins/currentUser';
  import { accountStore, serviceLocationStore } from '../store';
  import ServiceLocationForm from './ServiceLocationForm.vue';
  import ContractRequestForm from './ContractRequestForm.vue';
  import OrderFeedbackForm from './OrderFeedbackForm.vue';
  import AddEmployeeForm from './AddEmployeeForm.vue';
  import BookInteractionForm from './BookInteractionForm.vue';
  import CheckDocumentForm from './CheckDocumentForm.vue';

  @Component({
    components: { ServiceLocationForm, ContractRequestForm, AddEmployeeForm, BookInteractionForm, OrderFeedbackForm, CheckDocumentForm },
  })
  export default class CustomerModals extends mixins(currentUser) {
    bookInteractionRowContent = {};
    bookInteractionType = '';
    contractData = null;
    isBookInteractionModalActive = false;
    isCheckDocumentActive = false;
    isContractEditing = false;
    isContractModalActive = false;
    isOrderFeedbackModalActive = false;
    isEmployeeModalActive = false;
    changeDataFromLoggedInUser = false;
    isServiceLocationModalActive = false;
    preselectedServiceLocation?: { id: string; name: string };
    orderFeedbackExternalQualifier? = '';

    mounted() {
      this.$nuxt.$on('new-employee', () => {
        accountStore.resetCurrentEmployeeRecord();
        this.isEmployeeModalActive = true;
        this.changeDataFromLoggedInUser = false;
      });

      this.$nuxt.$on('edit-employee', (params: { id: string; changeDataFromLoggedInUser: boolean }) => {
        accountStore.setCurrentEmployeeById(params.id);
        this.isEmployeeModalActive = true;
        this.changeDataFromLoggedInUser = params.changeDataFromLoggedInUser;
      });

      this.$nuxt.$on('new-service-location', () => {
        serviceLocationStore.resetCurrentRecord();
        this.isServiceLocationModalActive = true;
      });

      this.$nuxt.$on('edit-service-location', (params: { externalQualifier: string }) => {
        serviceLocationStore.setCurrentRecordById(params.externalQualifier);
        this.isServiceLocationModalActive = true;
      });

      this.$nuxt.$on('new-contract', (params: { preselectedServiceLocation?: { id: string; name: string } }) => {
        this.preselectedServiceLocation = params?.preselectedServiceLocation;
        this.isContractModalActive = true;
      });

      this.$nuxt.$on('new-orderfeedback', (params: { preselectedServiceLocation?: { id: string } }) => {
        this.orderFeedbackExternalQualifier = params?.preselectedServiceLocation?.id;
        this.isOrderFeedbackModalActive = true;
      });

      this.$nuxt.$on('contract-editing', (contract: any) => {
        this.preselectedServiceLocation = undefined;
        this.isContractModalActive = true;
        this.contractData = contract;
        this.isContractEditing = true;
      });

      this.$nuxt.$on('book-interaction', (interactionType: string, interactionRowContent: any) => {
        this.isBookInteractionModalActive = true;
        this.bookInteractionType = interactionType;
        this.bookInteractionRowContent = interactionRowContent;
      });

      this.$nuxt.$on('close-modals', () => {
        this.isBookInteractionModalActive = false;
        this.isCheckDocumentActive = false;
        this.isContractEditing = false;
        this.isContractModalActive = false;
        this.isOrderFeedbackModalActive = false;
        this.isEmployeeModalActive = false;
        this.isServiceLocationModalActive = false;
      });
    }

    get currentEmployee() {
      return accountStore.currentEmployee;
    }

    get serviceLocation() {
      return serviceLocationStore.currentServiceLocation;
    }

    get showPermissions() {
      return !this.changeDataFromLoggedInUser;
    }

    destroyed() {
      this.$nuxt.$off('book-interaction');
      this.$nuxt.$off('edit-employee');
      this.$nuxt.$off('edit-service-location');
      this.$nuxt.$off('new-contract');
      this.$nuxt.$off('new-orderfeedback');
      this.$nuxt.$off('new-employee');
      this.$nuxt.$off('new-service-location');
      this.$nuxt.$off('close-modals');
    }
  }
