import { Component, Vue } from 'nuxt-property-decorator';
import dayjs from 'dayjs';

@Component
export default class extends Vue {
  /**
   * @param {dayjs.Dayjs} day The start day.
   * @param {number} setShowDays The max days WITHOUT Weekdays.
   * @param {boolean} isDayInPast Date in the past?
   * @return {dayjs.Dayjs} Returns min/max day.
   */
  setMinMaxPossibleDate = (day: dayjs.Dayjs, setShowDays: number = 2, isDayInPast: boolean = false): dayjs.Dayjs => {
    const tempDay = day.startOf('day');
    const isDaySameAsCurrentDay = tempDay.isSame(dayjs(), 'day');
    const isCurrentDayAfterElevenAM = dayjs().hour() >= 11;
    let dayCounter = 0;
    let counter = 0;

    do {
      dayCounter++;
      if (isDayInPast) {
        if (tempDay.subtract(dayCounter, 'day').get('day') === 0 || tempDay.subtract(dayCounter, 'day').get('day') === 6) {
          continue;
        } else counter++;
      } else if (tempDay.add(dayCounter, 'day').get('day') === 0 || tempDay.add(dayCounter, 'day').get('day') === 6) {
        continue;
      } else counter++;
    } while (counter < setShowDays);

    if (isDayInPast) {
      return tempDay.subtract(dayCounter, 'day');
    } else {
      // current-day AFTER 11 AM AND day is same current-day
      if (isCurrentDayAfterElevenAM && isDaySameAsCurrentDay) {
        return this.setMinMaxPossibleDate(tempDay.add(dayCounter, 'day'), 1);
      }
      return tempDay.add(dayCounter, 'day');
    }
  };
}
