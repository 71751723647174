import { Module, Action, Mutation } from 'vuex-module-decorators';
import { toLocaleDate } from '../utils/helpers';
import { ApiModule } from './ApiModule';

@Module({
  name: 'CustomerRequests',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class CustomerRequestsStoreModule extends ApiModule {
  records: any[] = [];
  types: string[] = [];
  isLoading: boolean = false;
  companyId: string = '';

  get serviceUrl(): string {
    return `/portal/customer/${this.companyId}/customerrequests/`;
  }

  get tableRecords() {
    return this.records.map((request: any) => {
      return {
        requestDate: toLocaleDate(request.requestDate),
        requestType: request.requestType,
        serviceLocationName: request.serviceLocationName,
        wastetypeDescription: request.wastetypeDescription,
        containerDescription: request.containerDescription,
        requestNo: request.requestNo,
        ownOrderNo: request.ownOrderNo,
        status: request.status,
        timestamp: new Date(request.requestDate).getTime(),
      };
    });
  }
  get contractRequestTypes() {
    return this.types;
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  @Action
  async fetchRequests(payload: any) {
    this.context.commit('setCompanyId', payload.companyId);
    const lang = payload.xlanguage;
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(this.serviceUrl, { params: { xlanguage: lang } });
      this.context.commit('setRequestRecords', response.data);
      this.context.commit('showNextPage');
    } catch (e) {
      console.error(e);
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Mutation
  setRequestRecords(response: { data: any[]; options: Record<string, string[]> }) {
    this.tableLoading = false;
    this.records = response.data;
    this.types = response.options.types;
  }

  @Mutation
  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }
}
