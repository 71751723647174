type PositiveListItem = {
  id: string;
  name: string;
  parent?: string;
};
export type GroupedPositiveList = {
  wasteTypes: PositiveListItem[];
  containerTypes: PositiveListItem[];
};

export const emptyPositiveList = {
  standard: { wasteTypes: [], containerTypes: [] },
  cycle: { wasteTypes: [], containerTypes: [] },
  sales: { wasteTypes: [], containerTypes: [] },
};

export type GroupedbyTypePositivelist = {
  standard: GroupedPositiveList;
  sales: GroupedPositiveList;
  cycle: GroupedPositiveList;
};

export enum KeycloakRealm {
  DENKWERK = 'denkwerk',
  PARTNER = 'disposer',
  CUSTOMER = 'commercial-customer',
}

export type CustomerIntervals = { description: string; externalQualifier: string }[];

type ServiceLocation = {};

export type Account = {
  id: string;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  phoneNumber: string;
  customerNumber: string;
  roles: string[];

  accountIdentifier: {
    type: string;
    identifierId: string;
  };
  positiveList: GroupedPositiveList;
  serviceLocations: ServiceLocation[];
  employees: Account[];
};

export enum AccountType {
  CUSTOMER = 'CUSTOMER',
  DISPOSER = 'DISPOSER',
}

export enum AccountRole {
  // GLOBAL
  ADMIN = 'admin',
  MASTER = 'ROLE_MASTER',
  ADMINISTRATION_READ = 'ROLE_MITARBEITERVERWALTUNG_LESEN',
  ADMINISTRATION_WRITE = 'ROLE_MITARBEITERVERWALTUNG_SCHREIBEN',
  INVOICING_READ = 'ROLE_ABRECHNUNG_LESEN',
  INVOICING_WRITE = 'ROLE_ABRECHNUNG_SCHREIBEN',

  // CUSTOMER
  CUSTOMER = 'ROLE_COMMERCIALCUSTOMER',
  SERVICE_LOCATION_READ = 'ROLE_STANDORTVERWALTUNG_LESEN',
  SERVICE_LOCATION_WRITE = 'ROLE_STANDORTVERWALTUNG_SCHREIBEN',

  // PARTNER
  DISPOSER = 'ROLE_DISPOSER',
  DISTRIBUTION_READ = 'ROLE_VERTRIEB_LESEN',
  DISTRIBUTION_WRITE = 'ROLE_VERTRIEB_SCHREIBEN',
  DISPOSITION_READ = 'ROLE_DISPOSITION_LESEN',
  DISPOSITION_WRITE = 'ROLE_DISPOSITION_SCHREIBEN',
}
