import { Module, Action, Mutation } from 'vuex-module-decorators';
import { PartialDeep } from 'type-fest';
import type { ServiceLocation } from '../types/serviceLocation';
import { stateMapper } from '../utils/stateMapper';
import { ApiModule } from './ApiModule';
import { Account } from './entities/account';
import { Contract } from '~/types/contract';

@Module({
  name: 'ServiceLocation',
  namespaced: true,
  stateFactory: true,
  preserveState: false,
})
export default class ServiceLocationStoreModule extends ApiModule {
  records: ServiceLocation[] = [];
  record?: PartialDeep<ServiceLocation> = undefined;
  customerNumber: string = '';
  showArchive: boolean = false;
  allowedServiceLocations: string[] = [];
  isAdmin: boolean = false;
  contracts: Contract[] = [];
  orders: any[] = [];
  zipCodeLatLngBounds: google.maps.LatLngBounds | null = null;

  get serviceUrl(): string {
    return '/portal/customer/serviceLocations';
  }

  @Action
  async fetchServiceLocations(params?: { force: boolean }) {
    try {
      this.context.commit('loadTable');
      const response = await this.api.get(`/portal/customer/${this.customerNumber}/serviceLocations${params?.force ? '?refresh=true' : ''}`);
      this.context.commit('setRecords', response.data.data);
      this.context.commit('showNextPage');
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action
  async fetchContractsAndOrdersForServiceLocation(data: any) {
    const externalQualifier = data.externalQualifier;
    try {
      this.context.commit('loadContractsTable');
      const response = await this.api.get(`/portal/customer/${this.customerNumber}/serviceLocations/${externalQualifier}/contracts`, {
        params: {
          xlanguage: data.xlanguage,
        },
      });
      this.context.commit('setContracts', response.data.data);
    } catch (e) {
      this.context.commit('recordsError', (e as any).response?.data.msg);
    }
  }

  @Action
  async createServiceLocation(serviceLocation: ServiceLocation) {
    try {
      this.context.commit('actionLoading');
      serviceLocation.state = stateMapper(serviceLocation.state);
      const response = await this.api.post(this.serviceUrl, { ...serviceLocation, companyExternalQualifier: this.customerNumber });
      const added = response.data.data as any;
      this.context.commit('serviceLocationCreated', { ...added, allowedUsers: (serviceLocation as any).employees });
    } catch (e) {
      this.context.commit('serviceLocationCreateFailed', e as string);
    }
  }

  @Action
  async updateServiceLocation(serviceLocation: ServiceLocation) {
    try {
      this.context.commit('actionLoading');
      if (!this.recordExternalQualifier) {
        this.context.commit('recordNotFound', 'record not found');
        return;
      }
      serviceLocation.state = stateMapper(serviceLocation.state);
      const response = await this.api.put(`${this.serviceUrl}/${this.recordExternalQualifier}`, {
        ...serviceLocation,
        companyExternalQualifier: this.customerNumber,
      });
      const updated = response.data.data as ServiceLocation;
      this.context.commit('serviceLocationUpdated', { ...updated, allowedUsers: (serviceLocation as any).employees });
    } catch (e) {
      this.context.commit('serviceLocationUpdateFailed', e as string);
    }
  }

  @Mutation
  loadContractsTable() {
    this.contracts = [];
    this.orders = [];
    this.tableLoading = true;
  }

  @Mutation
  setContracts(contracts: Contract[]) {
    this.tableLoading = false;
    this.orders = [];
    this.contracts = contracts;
    contracts.forEach((contract: Contract) => {
      contract.customerOrders.forEach((order) => {
        this.orders.push({ ...order, wastetypeDescription: contract.wastetypeDescription });
      });
    });
  }

  get allContracts() {
    return this.contracts.map((contract: Contract) => {
      return {
        ...contract,
        id: contract.externalQualifier,
        wasteType: contract.wastetypeDescription,
        containerType: contract.containerDescription,
      };
    });
  }

  get allOrders() {
    return this.orders;
  }

  get openOrders() {
    return this.orders.filter((order) => order.orderStatus === 'open');
  }

  get pendingOrders() {
    return this.orders.filter((order) => order.orderStatus === 'pending');
  }

  get closedOrders() {
    return this.orders.filter((order) => order.orderStatus === 'closed');
  }

  @Mutation
  serviceLocationCreated(serviceLocation: ServiceLocation) {
    this.status = { type: 'success' };
    this.records.push(serviceLocation);
    this.record = undefined;
  }

  @Mutation
  serviceLocationUpdated(updated: ServiceLocation) {
    this.status = { type: 'success' };
    this.record = updated as PartialDeep<ServiceLocation>;
    this.records = this.records.filter((record) => record.externalQualifier !== updated.externalQualifier);
    this.records.push(updated);
  }

  @Mutation
  serviceLocationCreateFailed(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  @Mutation
  serviceLocationUpdateFailed(error: string) {
    this.status = { type: typeof error === 'string' && error.match(/found/) ? 'notfound' : 'error' };
  }

  @Mutation
  initAccount(account: Account) {
    this.isAdmin = account.isAdmin;
    this.allowedServiceLocations = account.allowedServiceLocations;
    this.records = account.serviceLocations;
    this.customerNumber = account.accountIdentifier.companyExternalQualifier;
    this.status = { type: '' };
  }

  @Mutation
  setAdmin() {
    this.isAdmin = true;
  }

  @Mutation
  setCurrentRecordById(externalQualifier: string) {
    const serviceLocation = this.records.find((serviceLocation) => serviceLocation.externalQualifier === externalQualifier);
    this.record = { ...serviceLocation, allowedUsers: serviceLocation?.allowedUsers };
    this.status = { type: '' };
  }

  @Mutation
  showArchivedServiceLocations(archived: boolean = false) {
    this.showArchive = archived;
  }

  @Mutation
  setZipCodeLatLngBounds(zipCodeLatLngBounds: google.maps.LatLngBounds | null) {
    this.zipCodeLatLngBounds = zipCodeLatLngBounds;
  }

  get getZipCodeLatLngBounds(): google.maps.LatLngBounds | null {
    return this.zipCodeLatLngBounds;
  }

  get currentServiceLocation(): PartialDeep<ServiceLocation | undefined> {
    return this.record;
  }

  get tableRecords() {
    return this.records
      .filter((record) => {
        return this.showArchive ? record.active === 'Archived' : record.active !== 'Archived';
      })
      .map((serviceLocation) => {
        return {
          name: serviceLocation.name,
          street: serviceLocation.street + ' ' + serviceLocation.streetNo,
          zipCode: serviceLocation.zipCode,
          city: serviceLocation.city,
          state: serviceLocation.active,
          responsible: serviceLocation.organisation?.responsiblePerson,
          serviceLocationId1: serviceLocation.organisation?.serviceLocationID1,
          externalQualifier: serviceLocation.externalQualifier,
          users: serviceLocation.allowedUsers?.length,
        };
      })
      .sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  get counterActiveServiceLocation(): number {
    return this.records.filter((record) => {
      return record.active !== 'Archived';
    }).length;
  }

  get counterArchivedServiceLocation(): number {
    return this.records.filter((record) => {
      return record.active === 'Archived';
    }).length;
  }

  get serviceLocationList() {
    return this.records
      .filter((serviceLocation) => serviceLocation.active === 'Active')
      .map((serviceLocation) => {
        return {
          id: serviceLocation.externalQualifier,
          name: [
            serviceLocation.name,
            serviceLocation.street + ' ' + serviceLocation.streetNo,
            serviceLocation.zipCode + ' ' + serviceLocation.city,
          ].join(', '),
          title: serviceLocation.name,
          city: serviceLocation.zipCode + ' ' + serviceLocation.city,
          address: serviceLocation.street + ' ' + serviceLocation.streetNo,
          state: serviceLocation.state,
          contactPerson: {
            name: serviceLocation.contactPerson?.name ?? '',
            phone: serviceLocation.contactPerson?.phone ?? '',
          },
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  get allServiceLocationList() {
    return this.records
      .map((serviceLocation) => {
        return {
          id: serviceLocation.externalQualifier,
          name: serviceLocation.name,
        };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  get tableModalContent(): object | undefined {
    return {};
  }

  get findServiceLocation() {
    return (externalQualifier: string) => this.records.find((serviceLocation) => serviceLocation.externalQualifier === externalQualifier);
  }

  public get allowedEmployees() {
    return (employees: Account[]) => {
      return employees
        .filter((employee) => this.record?.allowedUsers?.includes(employee.id))
        .sort((a: Account, b: Account) => (a.lastname < b.lastname ? -1 : 1));
    };
  }
}
